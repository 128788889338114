﻿module.exports = [ 'addressPreferenceDataServices','reservationWizardGenericService', genericReservationSelectInvoiceAddress];

function genericReservationSelectInvoiceAddress(addressPreferenceDataServices,reservationWizard) {
    var directive = {
        bindToController: true,
        controller: genericReservationSelectInvoiceAddressController,
        controllerAs: 'ctrlGenericReservationSelectInvoiceAddress',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-select-invoiceaddress.html'
    };

    return directive;

    function genericReservationSelectInvoiceAddressController() {

        var vm = {
            invoiceAddresses:null,
            init:init,
            wizard:reservationWizard,
            isRequired:isRequired
        };
    
        function init() {
            loadInvoiceAddresses();
        }
        
        function loadInvoiceAddresses(){
            addressPreferenceDataServices.getCustomersOnly().then(function (result) {
                vm.invoiceAddresses = _.filter(result, function (x) { return x.addressType === 1 && !isInvoiceAddressRequested(x.addressValue) && x.displayName !== null; });
            });
        } 

        function isInvoiceAddressRequested(value){
            return value.toLowerCase().indexOf('tempf') !== -1;
        }

        function isRequired(){
            return reservationWizard.isRequired('invoiceAddress');
        }

        return vm;
    }
}