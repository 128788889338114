﻿module.exports = ['$translate', 'common', 'fleetDataService', 'fleetModelService', 'reservationDataService', 'constantsService', reservationCurrentService];

function reservationCurrentService($translate, common, fleetData, fleetModels, reservationData, constantsService) {

    var service = {
        clear: clear,
        currentStep: 1,
        //getFacilitiesDescription: getFacilitiesDescription,
        getVesseltype: getVesseltype,
        reservation: {
            reservationItems: [],
            eta: null,// new Date(2017, 8, 1, 12, 0),
            etd: null,// new Date(2017, 8, 4, 8, 0),
            //newVessel: null,
            selectedVessel: null,
            messages: null
        },
        save: save,
        validation: {
            step1: {
                errors: [],
                validate: validateStep1
            },
            step2: {
                errors: [],
                validate: validateStep2
            }
        }
    };
    return service;

    function clear() {
        service.currentStep = 1;
        service.reservation = {
            reservationItems: [],
            eta: null,
            etd: null,
            selectedVessel: null
        };
    }

    function getVesseltype() {
        if (!service.reservation || (!service.reservation.newvessel && !service.reservation.selectedvessel))
            return 1;

        if (service.reservation.selectedvessel)
            return service.reservation.selectedvessel.shiptypeid ? service.reservation.selectedvessel.shiptypeid : 1;
        else if (service.reservation.newvessel)
            return service.reservation.newvessel.shiptypeid ? service.reservation.newvessel.shiptypeid : 1;

        return 1;
    }

    function save(reservation) {
        var deferred = common.$q.defer();
        //var reservation = service.reservation;
        var newReservation = {};

        newReservation = {
            length: reservation.selectedVessel.length,
            shipId: reservation.selectedVessel.id,
            eta: reservation.eta,
            etd: reservation.etd,
            reservationItems: reservation.reservationItems,
            processFlowId: reservation.processFlowId
        };
        if (reservation.selectedVessel !== null) {
            var req = reservationData.addOrUpdateReservation(newReservation)
                .then(function (data) {
                    clear();
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise;
    }

    function validateStep1(addNewVessel) {
        service.validation.step1.errors = [];
        var validEta, validEtd = false;

        if (service.reservation.eta === null || service.reservation.eta === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivaldate');
        else if (moment().diff(service.reservation.eta, 'days') > 0)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.validarrivaldate');
        else
            validEta = true;

        if (service.reservation.etd === null || service.reservation.etd === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.departuredate');
        else if (moment().diff(service.reservation.etd, 'days') > 0)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.validdeparturedate');
        else
            validEtd = true;

        if (validEta && validEtd && service.reservation.etd < service.reservation.eta)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivalbeforedeparturedate');

        return service.validation.step1.errors.length === 0;
    }

    function validateStep2() {
        service.validation.step2.errors = [];
        return service.validation.step2.errors.length === 0;
    }

};

