﻿module.exports = [ 'reservationDataService','reservationWizardGenericService', genericReservationSelectBerth];

function genericReservationSelectBerth(reservationData,reservationWizard) {
    var directive = {
        bindToController: true,
        controller: genericReservationSelectBerthController,
        controllerAs: 'ctrlGenericReservationSelectBerth',
        restrict: 'E',
        scope: {
            addNewVessel: '=',
            flow: '='
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-select-berth.html'
    };

    return directive;

    function genericReservationSelectBerthController() {

        var vm = {
            berthGroups:null,
            init:init,
            wizard:reservationWizard,
            onBerthGroupChanged:onBerthGroupChanged,
            filteredBerthGroups:filteredBerthGroups,
            isRequired:isRequired,
            selectedBerth:null
        };

        var berthGroups =[];
        var berths =[];
    
        function init() {
            loadBerths();
        }
        
        function loadBerths() {
            reservationData.getBerthGroupsDetailsByProcessFlow(vm.flow.id)
                .then(function (result) {
                    if (result) {
                        // make for each shiptype of this flow an array for the berth and berthgroup
                        var allowShipTypeIds = [];
                        var tempGroupsArray = [];
                        var tempBerthArray = [];
                        for (var idx = 0; idx < vm.flow.shipTypes.length; idx++) {
                            var shipType = vm.flow.shipTypes[idx];
                            allowShipTypeIds.push(shipType.id);
                            tempGroupsArray[shipType.id] = [];
                            tempBerthArray[shipType.id] = [];
                        }

                        if (allowShipTypeIds.length > 0) {
                            for (var i = 0; i < result.length; i++) {
                                var berthGroup = result[i];

                                // loop through the allow shiptype to check of this berthgroup has this shiptype id
                                for (var j = 0; j < allowShipTypeIds.length; j++) {
                                    var shipTypeId = allowShipTypeIds[j];

                                    // this berthgroup has this shiptype id, so store berthgroup and add all berths of this berthgroup
                                    if (berthGroup.shipTypeIds && berthGroup.shipTypeIds.length > 0 && berthGroup.shipTypeIds.indexOf(shipTypeId) > -1) {
                                        tempGroupsArray[shipTypeId].push(berthGroup);

                                        if (berthGroup.berths && berthGroup.berths.length > 0) {
                                            for (var k = 0; k < berthGroup.berths.length; k++) {
                                                tempBerthArray[shipTypeId].push(berthGroup.berths[k]);
                                            }
                                        }
                                    } else if (berthGroup.berths && berthGroup.berths.length > 0) { // check if any berth contains this shipTypeId
                                        var foundBerths = _.filter(berthGroup.berths, (b) => { return b.shipTypeIds && b.shipTypeIds.indexOf(shipTypeId) > -1; });
                                        if (foundBerths && foundBerths.length > 0) {
                                            for (var l = 0; l < foundBerths.length; l++) {
                                                tempBerthArray[shipTypeId].push(foundBerths[l]);
                                            }

                                            berthGroup.berths = foundBerths;
                                            tempGroupsArray[shipTypeId].push(berthGroup);
                                        }
                                    }
                                }
                            }
                        }
                        berths = tempBerthArray;
                        berthGroups = tempGroupsArray;
                    }
                });
        }
        
        function filteredBerthGroups() {

            if(reservationWizard.configuration.berthSelectType === 1)
                vm.berthGroups = berthGroups;
            if(reservationWizard.configuration.berthSelectType === 2)
                vm.berthGroups = berths;

            if (vm.addNewVessel) {
                if (!reservationWizard.reservation.newVessel) {
                    return [];
                } else {
                    return vm.berthGroups[reservationWizard.reservation.newVessel.shipTypeId];
                }
            } else {
                if (!reservationWizard.reservation.selectedVessel) {
                    return [];
                } else {
                    return vm.berthGroups[reservationWizard.reservation.selectedVessel.shipTypeId];
                }
            }

        }

        function onBerthGroupChanged() {

            if (vm.selectedBerth) {
                if (reservationWizard.configuration.berthSelectType === 1){
                    reservationWizard.reservation.berthGroup = vm.selectedBerth;
                    reservationWizard.reservation.primaryPreferredBerthGroupId = vm.selectedBerth;
                }
                if (reservationWizard.configuration.berthSelectType === 2){
                    reservationWizard.reservation.berth = vm.selectedBerth;
                    reservationWizard.reservation.primaryPreferredBerthId = vm.selectedBerth.id;
                }
            }

            // check whether the selected berhtgroup is changed or not of the existing reservation. when the berthgroup is changed, update the berthgroup of the first reservation item
            if (reservationWizard.reservation.id && reservationWizard.reservation.id > 0) {
                if (reservationWizard.reservation.activities && reservationWizard.reservation.activities.length > 0 && reservationWizard.reservation.berthGroup) {
                    reservationWizard.reservation.activities[0].berthGroup = reservationWizard.reservation.berthGroup;
                    reservationWizard.reservation.activities[0].berthGroupId = reservationWizard.reservation.berthGroup.id;
                }
                if (reservationWizard.reservation.activities && reservationWizard.reservation.activities.length > 0 && reservationWizard.reservation.berth) {
                    reservationWizard.reservation.activities[0].berth = reservationWizard.reservation.berth;
                    reservationWizard.reservation.activities[0].berthId = reservationWizard.reservation.berth.id;
                }
            }
        }

        function isRequired(){
            return reservationWizard.isRequired('berthGroup');
        }

        return vm;
    }
}