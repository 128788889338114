﻿module.exports = ['$uibModalInstance', 'modalArguments', reviewWarningsController];

function reviewWarningsController($uibModalInstance, modalArguments) {
    var vm = {
        ok: ok,
        cancel: cancel,
        warnings: modalArguments
    };

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        $uibModalInstance.close(true);
    }

    return vm;

}