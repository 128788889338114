﻿module.exports = [vesselDisplayFilter];

function vesselDisplayFilter() {
    return function (vessel) {
        if (!vessel)
            return null;

        var result = vessel.name;

        var subFields = [];

        if (vessel.imo && vessel.imo !== '')
            subFields.push('IMO: ' + vessel.imo);
        if (vessel.eni && vessel.eni !== '')
            subFields.push('ENI: ' + vessel.eni);

        if (subFields.length > 0)
            result += ' (' + subFields.join(', ') + ')';

        return result;
    };
};
