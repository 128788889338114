﻿module.exports = ['constantsService', isBusinessUnitDirective];

function isBusinessUnitDirective(constants) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var show = true;

            if (attrs && attrs.isBusinessUnit) {
                if (scope.shiptype) {
                    updateDOM();
                }

                scope.$watch('shiptype', valueChanged);
            }
            scope.$watch('isBusinessUnit', valueChanged);

            function valueChanged(newValue, origValue, scope) {

                if (!newValue || origValue === newValue)
                    return;

                updateDOM();
            }

            function updateDOM() {
                var units = scope.isBusinessUnit.split(',');
                if (units.length > 0) {
                    show = _.some(units, function (unit) {
                        return constants.isBusinessUnitShipType(unit, scope.shiptype);
                    });
                }
                
                element.removeClass('ng-hide');
                if (!show)
                    element.addClass('ng-hide');
            }
        },
        scope: {
            isBusinessUnit: '@',
            shiptype: '@'
        }
    };
};