﻿// The common service exports all services that will be used a lot in different other services and controllers
module.exports = ['$location', '$q', '$timeout', 'config', 'formatting', 'identity', 'language', commonService];

function commonService($location, $q, $timeout, config, formatting, identity, language) {
    var service = {
        $location: $location,
        $q: $q,
        $timeout: $timeout,
        config: config,
        errorToText: errorToText,
        formatting: formatting,
        identity: identity,
        language: language
    };

    function errorToText(httpResponse) {
        var result = [];

        if (httpResponse.status)
            switch (httpResponse.status) {
                case 400:
                    if (httpResponse.data && httpResponse.data.errors !== '' && httpResponse.data.errors.length > 0)
                        result.push(httpResponse.data.errors);
                    else
                        result.push("An error has occured")
                    break;
                case 401:
                    result.push("You are not authorized to perform this action");
                    break;
                case 500:
                    if (httpResponse.data && httpResponse.data.exceptionMessage && httpResponse.data.exceptionMessage !== '')
                        result.push(httpResponse.data.exceptionMessage);
                    else if (httpResponse.data && httpResponse.data.message && httpResponse.data.message !== '')
                        result.push(httpResponse.data.message);
                    else if (httpResponse.data && httpResponse.data.Message && httpResponse.data.Message !== '')
                        result.push(httpResponse.data.Message);
                    else if (httpResponse.statusText && httpResponse.statusText !== '')
                        result.push(httpResponse.statusText);
                    else
                        result.push("An error has occured");
                    break;
                default:
                    result.push("An error has occured");
                    break;
            }

        return result;
    };

    return service;
};
