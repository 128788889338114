﻿'use strict';

module.exports = ['$location', '$anchorScroll', '$routeParams', '$translate','addressPreferenceDataServices','addressPreferenceModelsService', 'common', 'constantsService', 'fleetDataService', 'fleetModelService', 'widgetModals', 'toastr', 'userAccountService', 'financialInfoService', fleetController];

function fleetController($location, $anchorScroll, $routeParams, $translate, addressPreferenceDataServices, addressPreferenceModelsService, common, constants, fleetData, fleetModels, widgetModals, toastr, userAccountService, financialInfoService) {
    var vm = {
        activateShip: activateShip,
        addShip: addShip,
        constants: constants,
        deleteShip: deleteShip,
        editingVessel: null,
        invoiceAddress: null,
        filteredVessels: filteredVessels,
        filteredShipIssueList: filteredShipIssueList,
        loadVessels: loadVessels,
        loadShipIssueList: loadShipIssueList,
        newVessel: new fleetModels.vesselModel(),
        saveShip: saveShip,
        getDescription: getDescription,
        status: {
            completed: false,
            errors: [],
            loading: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false,
            showAll: false
        },
        vessels: [],
        shipIssueList: [],
        invoiceAddresses: [],
        saveInvoiceAddress:saveInvoiceAddress,
        saveNewAddressPreferences:saveNewAddressPreferences,
        selectedLink: selectedLink,
        currentlySelected: false,
        goBack: goBack,
        userAccount: null,
        requestSuccess: requestSuccess,
        requestFailed: requestFailed,
        canEditDebtor:false
    };

    load();

    function activateShip() {
        vm.editingVessel.isActive = true;
        vm.saveShip();
    }

    function checkPermission() {
        if (common.identity.hasPermission("user_admin")) return true;

        return false;
    }
    function load() {
        $translate([
            'views.fleet.fleet.conditions.areyousure',
            'views.fleet.fleet.errors.delete'
        ]).then(function (translations) { vm.translations = translations; });

        vm.canEditDebtor = constants.hasBusinessUnit(3) && checkPermission();
        loadInvoiceAddresses();
    }

    function filteredVessels() {
        if (vm.status.showAll) {
            return vm.vessels;
        } else {
            return _.filter(vm.vessels, function (vessel) { return vessel.isActive; });
        }
    }

    function filteredShipIssueList(vesselId) {
        return _.compact(_.map(vm.shipIssueList, function (shipIssue) {
            if(shipIssue.id == vesselId && shipIssue.issueType) {
                return shipIssue.issueType.replace(/_/g, ' ');
            }
        }));
    }

    function getDescription(vessel) {
        var result = '';
        if (vessel === null || vessel === 'undefined') return null;

        if (vessel.paxCapacity !== null && typeof (vessel.paxCapacity) === 'number')
            result = '# PAX ' + vessel.paxCapacity;
        else
            result = '';

        if (vessel.remarks !== null && vessel.remarks !== 'undefined' && vessel.remarks.trim() !== '') {
            if (result === '')
                result = vessel.remarks.trim();
            else
                result = result + ', ' + vessel.remarks.trim();
        }

        return result;
    }
    
    function addShip() {
        vm.status.errors = [];

        var validation = fleetModels.validateVessel(vm.newVessel);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        _.each(vm.newVessel.shipProperties, function (property) {
            property.startsOn = moment(property.startsOn).format("YYYY-MM-DDTHH:mm:ss");

            if (property.endsOn !== undefined && property.endsOn !== null)
                property.endsOn = moment(property.endsOn).format("YYYY-MM-DDTHH:mm:ss");
        });

        fleetData.addShip(vm.newVessel)
            .then(function (result) {
                vm.newVessel = new fleetModels.vesselModel();
                vm.status.showAddNew = false;
                vm.vessels.push(result);
                toastr.success('Ship added');
            }, function (err) {

            });
    }

    function deleteShip(ship) {
        widgetModals.cancelReservationOfShip({ message: vm.translations['views.fleet.fleet.conditions.areyousure'], shipId:vm.editingVessel.id}).result
            .then(function () {
                vm.editingVessel.isActive = false;
                fleetData.saveShip(vm.editingVessel)
                    .then(
                        function (response) {
                            //var iVessel = _.findIndex(vm.vessels, function (item) { return item.id === vm.editingVessel.id; });
                            //vm.vessels.splice(iVessel, 1);
                            vm.editingVessel = null;
                        },
                        function (response, status, headers, config) {
                            toastr.clear();
                            toastr.error(vm.translations['views.fleet.fleet.errors.delete']);
                        }
                    );
            });
    }

    function loadVessels() {
        vm.status.loading = true;

        fleetData.getFleet()
            .then(function (result) {
                vm.vessels = result;
                vm.status.loading = false;

                loadShipIssueList();
            }, function () {
                vm.status.loading = false;
            });

        var shipId = $routeParams.shipId;
        if (shipId !== undefined && shipId !== null && shipId !== '' && shipId > 0) {
            fleetData.getFleetById(shipId)
                .then(function (result) {
                    if (result !== undefined && result !== null) {
                        vm.status.setEdit(result);
                    }
                });
        }
    }

    function loadShipIssueList() {
        const shipIssues = [];

        _.forEach(vm.vessels, function (vessel) {
            const shipIssueList = _.where(constants.shipIssueList, { id: vessel.id }, 'id');

            if(shipIssueList.length) {
                _.forEach(shipIssueList, function (shipIssue) {
                    shipIssues.push(shipIssue);
                });
            }
        });

        vm.shipIssueList = shipIssues;
    }

    function saveShip() {
        vm.status.errors = [];

        var validation = fleetModels.validateVessel(vm.editingVessel);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        _.each(vm.editingVessel.shipProperties, function (property) {
            property.startsOn = moment(property.startsOn).format("YYYY-MM-DDTHH:mm:ss");

            if (property.endsOn !== undefined && property.endsOn !== null)
                property.endsOn = moment(property.endsOn).format("YYYY-MM-DDTHH:mm:ss");
        });

        vm.status.saving = true;
        fleetData.saveShip(vm.editingVessel)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.status.completed = true;
            vm.status.saving = false;

            constants.updateShipIssueList()
                .then(function () {
                    loadShipIssueList();
                });
        }
    }

    function setAddNew() {
        vm.status.completed = false;
        vm.status.showAddNew = true;
        vm.editingVessel = null;
    }

    function setEdit(ship) {
        vm.status.completed = false;
        vm.status.showAddNew = false;
        vm.status.errors = [];
        vm.editingVessel = ship;
        addressPreferenceDataServices.getBasedOnShip(ship.id).then(function (result) {
            if (result !== null) {
                vm.invoiceAddress = _.filter(vm.invoiceAddresses, function (x) { return x.addressValue === result.addressValue; })[0];
            } else {
                vm.invoiceAddress = null;
            }
        });
        $anchorScroll();
    }
    function selectedLink(id) {
        if (vm.editingVessel !== null) {
            if (parseInt(id) === parseInt(vm.editingVessel.id)) {
                vm.currentlySelected = true;
                return vm.currentlySelected;
            }
        }
    }
    function goBack() {
        $location.path('/fleet/', false);
    }

    function loadInvoiceAddresses(){
        addressPreferenceDataServices.getAddressPreferences().then(function (result) {
            vm.invoiceAddresses = _.filter(result, function (x) { return x.addressType === addressPreferenceDataServices.addressType.debtorNumber.id; });
        });
    }

    function saveNewAddressPreferences(result){
        if(!vm.invoiceAddress){
            vm.invoiceAddress = addressPreferenceModelsService.addressPreferenceModel();
            vm.invoiceAddress.addressType = addressPreferenceDataServices.addressType.debtorNumber.id;
        }

        if (vm.invoiceAddress.addressType === addressPreferenceDataServices.addressType.debtorNumber.id) {
            vm.invoiceAddress.addressValue = 'TEMPF:' + result.id;
        }

        if (!result || !result.companyName) {
            if(vm.editingVessel.name){
                vm.invoiceAddress.displayName = vm.editingVessel.name;
            }
            else{
                vm.invoiceAddress.displayName = "(None)";
            }
        } else {
            vm.invoiceAddress.displayName = result.companyName;
        }

        saveInvoiceAddress();
    }
    

    function saveInvoiceAddress() {

        addressPreferenceDataServices.getBasedOnShip(vm.editingVessel.id).then(function (result) {
            if (vm.invoiceAddress === null) {
                return addressPreferenceDataServices.deleteAddressPreference(result);
            }
            else {
                var newInvoice = addressPreferenceModelsService.addressPreferenceModel(vm.invoiceAddress);
                if (result !== null){
                    newInvoice.id = result.id;
                    newInvoice.customerId = result.customerId;
                }

                newInvoice.objectType = addressPreferenceDataServices.objectType.ship.id;
                newInvoice.objectId = vm.editingVessel.id;

                if (result === null) {
                    addressPreferenceDataServices.addAddressPreference(newInvoice)
                        .then(requestSuccess, requestFailed);
                } else {
                    addressPreferenceDataServices.updateAddressPreference(newInvoice)
                        .then(requestSuccess, requestFailed);
                }
            }
        },
            requestFailed);
    }

    function requestSuccess() {
        toastr.success('You changes has been successfully saved!');
    }

    function requestFailed(error) {
        toastr.clear();
        toastr.error(error.data);
    }

    return vm;

}