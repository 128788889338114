﻿module.exports = ['$uibModal', fleetModalsService];

function fleetModalsService($uibModal) {

    var service = {
        shipProperties: shipProperties
    };

    return service;

    function shipProperties(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/fleet/modals/ship-properties-modal.html',
            controllerAs: 'vm',
            controller: 'modalShipPropertiesController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            },
            size: 'lg'
        });
    }
}
