﻿'use strict';

module.exports = ['common', 'dataContext', '$rootScope', userAccountService];

function userAccountService(common, context, $rootScope) {
    var service = {
        getUserInfo: getUserInfo,
        loginUser: loginUser,
        registerUser: registerUser,
        saveCustomerInfo: saveCustomerInfo,
        getCustomerInfo: getCustomerInfo,
        getCustomerDebtor: getCustomerDebtor,
        saveUserInfo: saveUserInfo,
        changePassword: changePassword,
        requestPasswordChange: requestPasswordChange,
        resetPassword: resetPassword,
        getBusinessUnits: getBusinessUnits,
        businessUnits: [],
        readPrivacy: readPrivacy,
        getSystemInfo: getSystemInfo
    };

    return service;
    function readPrivacy() {
        var deferred = common.$q.defer();
        var req = context.get('/account/read-privacy')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBusinessUnits() {
        var deferred = common.$q.defer();

        if (service.businessUnits.length > 0)
            deferred.resolve(service.businessUnits);
        else
            context.get('/account/businessunits')
                .then(function (array) {
                    service.businessUnits = array;
                    deferred.resolve(array);
                }, function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function resetPassword(resetpasmodel) {
        var deferred = common.$q.defer();
        var req = context.post('/account/reset-password', resetpasmodel)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function requestPasswordChange(useremail) {
        var deferred = common.$q.defer();
        var req = context.post('/account/request-password', { userEmail: useremail })
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loginUser(userData, rememberMe) {
        var tokenUrl = '/account/login';
        if (!userData.grant_type) {
            userData.grant_type = 'password';
        }
        userData.applicationKey = common.config.applicationKey;
        userData.applicationSecret = common.config.applicationSecret;

        var deferred = common.$q.defer();
        context.post(tokenUrl, userData)
            .then(function (data) {
                common.identity.set(userData.username, data, rememberMe);
                common.identity.reloadUserInfo().then(function (userInfo) {
                    $rootScope.$emit('identity:set');
                }, function () {
                    $rootScope.$emit('identity:set');
                });
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }

    function getUserInfo() {
        var deferred = common.$q.defer();
        var req = context.get('/account/userinfo')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function registerUser(userData) {
        var accountUrl = '/account/register';
        return context.post(accountUrl, userData);
    }

    function saveCustomerInfo(customerInfo) {
        var deferred = common.$q.defer();
        var req = context.put('/account/customerinfo', customerInfo)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function getCustomerInfo() {
        var deferred = common.$q.defer();
        var req = context.get('/account/customerinfo')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomerDebtor(shipTypes) {
        var deferred = common.$q.defer();
        var url = '/account/customerdebtor';
        if (shipTypes && shipTypes.length > 0)
            url += '?shiptypes=' + shipTypes.join(',');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveUserInfo(userInfo) {
        var deferred = common.$q.defer();
        var req = context.put('/account/userinfo', userInfo)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function changePassword(changePasswordData) {
        var deferred = common.$q.defer();
        var req = context.put('/account/changepassword', changePasswordData)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getSystemInfo() {
        var deferred = common.$q.defer();
        var req = context.get('/account/systeminfo')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}