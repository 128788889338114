﻿angular.module('poa.rprp.planning', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('planningController', require('./planning'))
// Directives
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/planning', {
            templateUrl: 'templates/planning/planning.html'
        });
};
