﻿module.exports = ['common', 'reservationDataService', 'messageDataService', sendMessageDirective];

function sendMessageDirective(common, reservationData, messageData) {

    var directive = {
        bindToController: true,
        controller: sendMessageController,
        controllerAs: 'ctrlSendMessage',
        restrict: 'E',
        scope: {
            messageSentCallback: '=',
            reservationId: '=',
            isCustomerMessage: '='
        },
        templateUrl: 'templates/messages/directives/send-message.html'
    };

    return directive;

    function sendMessageController() {
        var ctrlSendMessage = this;
        ctrlSendMessage.newMessage = {
            isIntern: false,
            text: null
        };

        ctrlSendMessage.saveMessage = function () {
            if (!ctrlSendMessage.newMessage.text || ctrlSendMessage.newMessage.text == '')
                return;

            if (ctrlSendMessage.isCustomerMessage) {
                messageData.addCustomerMessage(ctrlSendMessage.newMessage.text, ctrlSendMessage.newMessage.isIntern)
                    .then(function (result) {
                        ctrlSendMessage.newMessage.isIntern = false;
                        ctrlSendMessage.newMessage.text = null;

                        if (ctrlSendMessage.messageSentCallback)
                            ctrlSendMessage.messageSentCallback();
                    });
            } else {
                reservationData.addMessage(ctrlSendMessage.reservationId, ctrlSendMessage.newMessage.text, ctrlSendMessage.newMessage.isIntern)
                    .then(function (result) {
                        ctrlSendMessage.newMessage.isIntern = false;
                        ctrlSendMessage.newMessage.text = null;

                        if (ctrlSendMessage.messageSentCallback)
                            ctrlSendMessage.messageSentCallback();
                    });
            }

        }

    };


};