angular.module('poa.rprp.financial', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('financialController', require('./controllers/financial'))
    // Directives
    .directive('addressPreferencesOverview', require('./directives/address-preferences-overview'))
    .directive('addressPreferencesForm', require('./directives/address-preferences-form'))
    // Services
    .factory('addressPreferenceDataServices', require('./services/address-preference-data'))
    .factory('addressPreferenceModelsService', require('./services/address-preference-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
    .when('/financial', {
        templateUrl: 'templates/financial/controllers/financial.html'
    })
};