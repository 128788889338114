﻿angular
    .module('poa.rprp.account', [])
    .config(['$routeProvider', routeConfigurator])
    .controller('loginController', require('./controllers/login'))
    .controller('logoutController', require('./controllers/logout'))
    .controller('profileController', require('./controllers/profile'))
    .controller('resetPasswordControl', require('./controllers/reset-password'))
    .directive('financialInformation', require('./directives/financial-information'))
    .factory('userAccountService', require('./services/user-account'))
    .factory('financialInfoService', require('./services/financial-info'));

function routeConfigurator($routeProvider) {
    $routeProvider
        .when('/account', {
            templateUrl: 'templates/account/controllers/profile.html'
        })
        .when('/account/login', {
            templateUrl: 'templates/account/controllers/login.html'
        })
        .when('/account/logout', {
            templateUrl: 'templates/account/controllers/logout.html'
        })
       .when('/account/reset-password', {
           templateUrl: 'templates/account/controllers/reset-password.html'
       });
};
