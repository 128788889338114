﻿module.exports = ['common', calendarBlockDirective];

function calendarBlockDirective(common) {

    var directive = {
        bindToController: true,
        controller: calendarBlockController,
        controllerAs: 'ctrlCalendarBlock',
        link: function (scope) {
            if (!scope || !scope.ctrlCalendarBlock)
                return;

            if (!scope.ctrlCalendarBlock.titleText || scope.ctrlCalendarBlock.titleText == '') {
                scope.ctrlCalendarBlock.titleText =
                    scope.ctrlCalendarBlock.calendarItem.ship.name;

                if (scope.ctrlCalendarBlock.calendarItem.customer && scope.ctrlCalendarBlock.calendarItem.customer.name)
                    scope.ctrlCalendarBlock.titleText += ' (' + scope.ctrlCalendarBlock.calendarItem.customer.name + ')';

                scope.ctrlCalendarBlock.titleText += ' - ' +
                    moment(scope.ctrlCalendarBlock.calendarItem.eta).format('DD-MM-YYYY HH:mm') + ' to ' + moment(scope.ctrlCalendarBlock.calendarItem.etd).format('DD-MM-YYYY HH:mm');
            }

            if (!scope.ctrlCalendarBlock.displayText || scope.ctrlCalendarBlock.displayText == '') {
                scope.ctrlCalendarBlock.displayText = scope.ctrlCalendarBlock.calendarItem.ship.name;
                if (scope.ctrlCalendarBlock.calendarItem.customer && scope.ctrlCalendarBlock.calendarItem.customer.name)
                    scope.ctrlCalendarBlock.displayText += ' (' + scope.ctrlCalendarBlock.calendarItem.customer.name + ')';
            }
        },
        restrict: 'E',
        scope: {
            calendarItem: '=',
            displayText: '@',
            itemClasses: '@',
            titleText: '@'
        },
        templateUrl: 'templates/reservations/directives/calendar-block.html'
    };

    return directive;

    function calendarBlockController() {
        var ctrlCalendarBlock = this;
    };

};