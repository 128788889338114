﻿module.exports = ['common', reservationCommunicatedStatusDirective];

function reservationCommunicatedStatusDirective(common) {

    var directive = {
        bindToController: true,
        controller: reservationCommunicatedStatusController,
        controllerAs: 'ctrlReservationCommunicatedStatus',
        restrict: 'E',
        scope: {
            communicatedStatus: '=',
            shiptypeId: '='
        },
        templateUrl: 'templates/reservations/directives/reservation-communicated-status.html'
    };

    return directive;

    function reservationCommunicatedStatusController() {
        var ctrlReservationCommunicatedStatus = this;

        ctrlReservationCommunicatedStatus.hasStatus = function (status, lastItemOnly) {
            if (!ctrlReservationCommunicatedStatus.communicatedStatus || ctrlReservationCommunicatedStatus.communicatedStatus.length == 0)
                return;

            if (lastItemOnly && lastItemOnly == true)
                return _.last(ctrlReservationCommunicatedStatus.communicatedStatus).status == status;

            return _.some(ctrlReservationCommunicatedStatus.communicatedStatus, function (item) {
                return item.status >= status;
            });
        }
    }

};