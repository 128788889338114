﻿module.exports = {
    'vesselName': 'Naam:',
    'vesselType': 'Type:',
    'paxCapacity': 'PAX capaciteit',
    'imoNumber': 'IMO nummer:',
    'eniNumber': 'ENI nummer:',
    'height': 'Hoogte:',
    'legnth': 'Lengte:',
    'width': 'Breedte:',
    'maxDraught': 'Maximale diepgang:',
    'phoneNumber': 'Telefoonnummer:',
    'remarks': 'Opmerkingen:',
    'loa': 'LOA:',
    'selectType': 'Selecteer Type',
    'selectActivity': 'Selecteer Activiteit',
    'selectCargo': 'Selecteer Cargo',
    'tanker': 'Tanker',
    'inert': 'Inert',
    'dangerousGoods': 'Gevaarlijke goederen aan boord',
    'yes': 'Ja',
    'no': 'Nee',
    'unnumber': 'UN Nummer/ Product naam'
};