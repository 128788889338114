﻿module.exports = ['common', reservationPlanningService];

function reservationPlanningService() {

    var service = {
        calculateBerths: calculateBerths,
        calculateDays: calculateDays,
        calculateItem: calculateItem,
        calculateItems: calculateItems,
        data: {
            berthObstructions: [],
            planned: [],
            unplanned: []
        },
        days: [],
        display: {
            dayPercentage: 0,
            maximumDate: null,
            minimumDate: null
        },
        calculateCalendarBlock:calculateCalendarBlock
    };
        
    return service;

    function calculateBerths(berthDetails, obstructions) {
        if (berthDetails == null)
            return;

        service.data.berthObstructions = [];

        if (obstructions && obstructions.length > 0) {
            // map the obstructions to fit the same structure as berth details, for easy processing
            var mappedObstructions = _.map(obstructions, function (obstruction) {
                var availability = '';
                if (obstruction.startsOn && obstruction.endsOn)
                    availability = 'Niet beschikbaar van ' + moment(obstruction.startsOn).format('DD-MM-YYYY HH:mm') + ' tot ' + moment(obstruction.endsOn).format('DD-MM-YYYY HH:mm');
                else if (obstruction.startsOn)
                    availability = 'Niet beschikbaar vanaf ' + moment(obstruction.startsOn).format('DD-MM-YYYY HH:mm');
                else if (obstruction.endsOn)
                    availability = 'Niet beschikbaar tot ' + moment(obstruction.endsOn).format('DD-MM-YYYY HH:mm');

                if (availability === '') {
                    availability = obstruction.remarks;
                } else {
                    if (obstruction.berthName !== undefined && obstruction.berthName !== null) {
                        availability += " (" + obstruction.berthName + ")";
                    }

                    availability += "\n" + obstruction.remarks;
                }

                return {
                    availability: availability,
                    availableFrom: obstruction.startsOn,
                    availableUntil: obstruction.endsOn,
                    id: obstruction.berthId,
                    remarks: obstruction.remarks,
                    obstructionId: obstruction.id
                };
            });
            berthDetails = berthDetails.concat(mappedObstructions);
        }
        var startdate = service.display.minimumDate? service.display.minimumDate: moment().subtract(1,'days');
        var enddate = service.display.maximumDate? service.display.maximumDate: moment().add(2,'years');

        var obstructedBerths = _.filter(berthDetails, function (berth) {
            var availableFrom, availableUntil = null;

            if (berth.availableFrom)
                availableFrom = moment(berth.availableFrom);
            if (berth.availableUntil)
                availableUntil = moment(berth.availableUntil);

            if (!availableFrom && !availableUntil)
                return false;

            if (availableUntil < startdate || availableFrom > enddate)
                return false;

            return true;
        });

        service.data.berthObstructions = [];

        _.each(obstructedBerths, function (berth) {
            var availability = '';
            if (berth.availableFrom && berth.availableUntil)
                availability = 'Beschikbaar van ' + moment(berth.availableFrom).format('DD-MM-YYYY') + ' tot ' + moment(berth.availableUntil).format('DD-MM-YYYY');
            else if (berth.availableFrom)
                availability = 'Beschikbaar vanaf ' + moment(berth.availableFrom).format('DD-MM-YYYY');
            else if (berth.availableUntil)
                availability = 'Beschikbaar tot ' + moment(berth.availableUntil).format('DD-MM-YYYY');

            var description = berth.remarks ? berth.remarks : 'niet beschikbaar';
            if (availability === '') {
                availability = description;
            } else {
                if (berth.name !== undefined && berth.name !== null) {
                    availability += " (" + berth.name + ")";
                }

                availability += "\n" + description;
            }

            var result = {
                berthId: berth.id,
                availableFrom: berth.availableFrom,
                availableUntil: berth.availableUntil,
                availability: berth.availability ? berth.availability : availability,
                remarks: description
            };
            
            if (berth.obstructionId) {
                result.id = berth.obstructionId;

                // check the remark is startwith reservation id or not. which means this obstruction is link to a reservation
                if (berth.remarks) {
                    var remarksParts = berth.remarks.split(':');
                    if (remarksParts.length > 1) {
                        var resId = parseInt(remarksParts[0]);
                        if (isNaN(resId) === false) {
                            result.reservationId = resId;
                            result.remarks = berth.remarks.replace(remarksParts[0] + ':', '');
                            result.etaDisplay = moment(berth.availableFrom).format('DD-MM-YYYY HH:mm');
                            result.etdDisplay = moment(berth.availableUntil).format('DD-MM-YYYY HH:mm');
                        }
                    }
                }
                service.data.berthObstructions.push(calculateCalendarBlock(result, 'availableFrom', 'availableUntil'));
            }
            else {
                if (berth.availableFrom && result.availableFrom && moment(result.availableFrom).isSameOrAfter(startdate)) {
                    result.blockStart = startdate;
                    service.data.berthObstructions.push(calculateCalendarBlock(_.clone(result), 'blockStart', 'availableFrom'));
                }
                if (berth.availableUntil && result.availableUntil ) {
                    result.blockEnd = enddate.endOf('day');
                    service.data.berthObstructions.push(calculateCalendarBlock(_.clone(result), 'availableUntil', 'blockEnd'));
                }
            }
        });
    }

    function calculateDays(source, nrOfAdditionalColumns) {
        if (!source)
            return;

        if (source.eta && source.etd) {
            service.display.minimumDate = (moment.isMoment(source.eta) ? source.eta : moment(source.eta));
            service.display.maximumDate = (moment.isMoment(source.etd) ? source.etd : moment(source.etd));
        }
        else if (source.planningItems) {
            var minimumDate, maximumDate = null;
            for (var i = 0; i < source.planningItems.length; i++) {
                if (!minimumDate || source.planningItems[i].eta < minimumDate)
                    minimumDate = source.planningItems[i].eta;
                if (!maximumDate || source.planningItems[i].etd > maximumDate)
                    maximumDate = source.planningItems[i].etd;
            }

            service.display.minimumDate = moment(minimumDate);
            service.display.maximumDate = moment(maximumDate);
        }
        else
            return;

        service.display.maximumDate = moment(service.display.maximumDate.format('YYYY-MM-DD'));
        service.display.minimumDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var totalDays = Math.ceil(service.display.maximumDate.diff(service.display.minimumDate, 'days'));
        var minDate = moment(service.display.minimumDate.format('YYYY-MM-DD'));

        service.days = [];
        for (var i = 0; i < totalDays + 1; i++) {
            service.days.push(minDate.format('ddd DD'));
            minDate.add(1, 'days');
        }

        var nrOfColumns = service.days.length;
        if (nrOfAdditionalColumns)
            nrOfColumns = nrOfColumns + nrOfAdditionalColumns;

        service.display.dayPercentage = (100 / nrOfColumns);
    }

    function calculateItem(source) {
        if (!source.planningItems)
            return [];

        var items = [];

        for (var i = 0; i < source.planningItems.length; i++) {
            var item = source.planningItems[i];
            if (!item.id && source.id)
                item.id = source.id;
            item.display = {
                first: i == 0,
                last: source.planningItems.length == (i + 1),
                row: -1
            };
            item.customer = source.customer;
            item.ship = source.ship;
            item = calculateCalendarBlock(item);

            if (item != null)
                items.push(item);
        }

        return items;
    }

    function calculateItems(source, target, append) {
        if (!service.data[target])
            return;

        if (!append || append === false)
            service.data[target] = [];

        if (!source || source.length == 0)
            return;

        for (var a = 0; a < source.length; a++) {
            // Exclude already planned, but changed items as suggestion blocks
            if (target === 'unplanned' && source[a].reservation && source[a].reservation.reservationStatus && source[a].reservation.reservationStatus.isChangedButNotConfirmed && source[a].reservation.reservationStatus.isChangedButNotConfirmed === true)
                continue;

            var items = calculateItem(source[a]);
            for (var b = 0; b < items.length; b++) {
                service.data[target].push(items[b]);
            }
        }

        calculateRows(service.data[target]);
    }

    function calculateCalendarBlock(item, startTimeField, endTimeField) {
        if (!startTimeField)
            startTimeField = 'eta';
        if (!endTimeField)
            endTimeField = 'etd';

        var eta = moment.isMoment(item[startTimeField]) ? item[startTimeField] : moment(item[startTimeField]);
        var etd = moment.isMoment(item[endTimeField]) ? item[endTimeField] : moment(item[endTimeField]);

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;

        if (item.display == undefined)
            item.display = {};

        item.display.startPosition = (service.display.dayPercentage * (eta - service.display.minimumDate) / _MS_PER_DAY);

        item.display.width = (service.display.dayPercentage * (etd - service.display.minimumDate) / _MS_PER_DAY) - item.display.startPosition;
        if (item.display.startPosition + item.display.width > 100) {
            item.display.width = 100 - item.display.startPosition;
        }

        if (item.display.startPosition + item.display.width < 0)
            item.display.width = 0;
        else if (item.display.startPosition < 0) {
            item.display.width += item.display.startPosition;
            item.display.startPosition = 0;
        }
        else if (item.display.startPosition >= 98)
            item.display.width = 0;

        return item;
    }

    function calculateRows(items) {
        var rowStatus = {};

        //var allItems = vm.data.planned.concat(vm.data.unplanned);
        var items = service.data.planned.concat(service.data.unplanned);
        items = _.sortBy(items, function (item) { return item.display.startPosition + item.display.width; });

        for (var itemIndex = 0; itemIndex < items.length; itemIndex++) {
            var item = items[itemIndex];

            var berthId = item.berthId.toString();

            if (!rowStatus[berthId]) {
                item.display.row = 0;
                rowStatus[berthId] = { 0: item.display.startPosition + item.display.width };
                continue;
            }

            for (var status in rowStatus[berthId]) {
                if (rowStatus[berthId][status] <= item.display.startPosition) {
                    item.display.row = parseInt(status);
                    rowStatus[berthId][status] = item.display.startPosition + item.display.width;
                    break;
                }
            }

            if (item.display.row == -1) {
                var newIndex = Object.keys(rowStatus[berthId]).length;
                item.display.row = newIndex;
                rowStatus[berthId][newIndex] = item.display.startPosition + item.display.width;
            }
        }
    }

};
