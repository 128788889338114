﻿'use strict';

module.exports = ['common', 'dataContext', reservationDataService];

function reservationDataService(common, context) {

    var service = {
        addOrUpdateReservation: addOrUpdateReservation,
        cancelReservation: cancelReservation,
        getBerthGroups: getBerthGroups,
        getBerthGroupsByBusinessUnit: getBerthGroupsByBusinessUnit,
        getBerthGroupsByShipType: getBerthGroupsByShipType,
        getBerthGroupsByProcessFlow: getBerthGroupsByProcessFlow,
        getBerthGroupsDetailsByProcessFlow:getBerthGroupsDetailsByProcessFlow,
        getCalculatedPrice: getCalculatedPrice,
        getCommercialGoals: getCommercialGoals,
        getFacilities: getFacilities,
        addMessage: addMessage,
        getMessages: getMessages,
        getNauticalGoals: getNauticalGoals,
        getOverlappingReservations: getOverlappingReservations,
        getReservation: getReservation,
        getReservationPlanning: getReservationPlanning,
        getReservations: getReservations,
        getCalendar: getCalendar,
        lastSaved: null,
        getFilteredBerthGroups: getFilteredBerthGroups,
        compareHours: compareHours,
        getAvailability: getAvailability,
        checkAvailabilityByBerth: checkAvailabilityByBerth,
        getReservationsByShipId:getReservationsByShipId
    };

    return service;

    function addMessage(reservationId, text, isIntern) {
        var deferred = common.$q.defer();

        var args = {
            isIntern: isIntern,
            text: text
        };

        var req = context.post('/reservation/' + reservationId + '/message', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addOrUpdateReservation(reservation) {
        service.lastSaved = reservation;
        var url = '/reservation';
        var id = reservation.id;
        if (typeof id  === "number" && id > 0)
            url = url + "/" + id;

        var deferred = common.$q.defer();
        var req = context.post(url, reservation)
            .then(function (data) {
                deferred.resolve(data);
                if (data.id) {
                    service.lastSaved.id = data.id;
                }
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function cancelReservation(id, reason) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + id + '/cancel/' + reason)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthGroups() {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthGroupsByShipType(shipType) {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/shiptype/' + shipType)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthGroupsByProcessFlow(flowId) {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/processflow/' + flowId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthGroupsDetailsByProcessFlow(flowId) {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/processflowDetails/' + flowId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthGroupsByBusinessUnit(businessUnit) {
        var deferred = common.$q.defer();
        var req = context.get('/berthgroup/businessunit/' + businessUnit + '/details')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCalculatedPrice(reservation) {
        var deferred = common.$q.defer();
        var req = context.post('/invoice/preview', reservation)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFilteredBerthGroups(businessUnit, flowIds, loa, berthGroupId, nauticalGoalId, commercialGoalId, startDate, endDate) {
        var deferred = common.$q.defer();
        // Convert the dates to a Moment object for easy manipulation
        startDate = (moment.isMoment(startDate) ? startDate : moment(startDate));
        endDate = (moment.isMoment(endDate) ? endDate : moment(endDate));
        // Convert the Moment dates to a string
        startDate = startDate.format('YYYY-MM-DD HH:mm');
        endDate = endDate.format('YYYY-MM-DD HH:mm');

        var url = '/berthgroup/length/';
        var urlArgs = [];
        urlArgs.push('businessUnit=' + businessUnit);
        urlArgs.push('loa=' + loa);
        urlArgs.push('berthGroupId=' + berthGroupId);
        urlArgs.push('nauticalGoalId=' + nauticalGoalId);
        urlArgs.push('commercialGoalId=' + commercialGoalId);

        if (flowIds !== null)
            urlArgs.push('flowIds=' + flowIds.join(','));

        if (typeof startDate !== 'undefined' && startDate !== null) {
            urlArgs.push('startDate=' + startDate);
        }
        if (typeof endDate !== 'undefined' && endDate !== null) {
            urlArgs.push('endDate=' + endDate);
        }

        url += '?' + urlArgs.join('&');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    function getCommercialGoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/commercial')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFacilities() {
        var deferred = common.$q.defer();
        var req = context.get('/facility')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessages(reservationId) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + reservationId + '/message')
            .then(function (data) {
                messagesToLocalTime(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getNauticalGoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/nautical')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getOverlappingReservations(reservation) {
        var deferred = common.$q.defer();
        var req = context.post('/reservation/overlap', reservation)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservations(searchquery) {
        var deferred = common.$q.defer();
        var url = '/reservation';
        if (typeof searchquery !== 'undefined' && searchquery !== null && searchquery.trim() !== '')
            url = url + '?q=' + searchquery.trim();

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationsByShipId(shipId) {
        var deferred = common.$q.defer();
        var url = '/reservation/ship/'+shipId;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservation(id) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCalendar(eta, etd) {
        var deferred = common.$q.defer();
        var url = '/calendar';

        url = url + '?dateFrom='+eta+'&dateUntil='+etd+'&days=365';

        //if (typeof (searchquery) !== 'undefined' && searchquery !== null && searchquery.trim() !== '')
        //    url = url + '&query=' + searchquery.trim();

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getReservationPlanning(id) {
        var deferred = common.$q.defer();
        var req = context.get('/reservation/' + id + '/planning')
            .then(function (data) {

                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getAvailability(startDate, dateUntil, businessUnit, berthGroupId, nauticalGoalId) {
        // Convert the dates to a Moment object for easy manipulation
        startDate = (moment.isMoment(startDate) ? startDate : moment(startDate));
        dateUntil = (moment.isMoment(dateUntil) ? dateUntil : moment(dateUntil));
        // Convert the Moment dates to a string
        startDate = startDate.format('YYYY-MM-DD');
        var endDate = moment(dateUntil, 'YYYY-MM-DD'); // first clone the endDate before add 1 day, otherwise the source data will be changed
        endDate = endDate.add(1, 'days').format('YYYY-MM-DD');

        var deferred = common.$q.defer();
        var url = '/reservation/availability';
        var urlArgs = [];
        urlArgs.push('startDate=' + startDate);
        urlArgs.push('endDate=' + endDate);

        if (businessUnit)
            urlArgs.push('businessUnit=' + businessUnit);
        else
            urlArgs.push('businessUnit=-1');

        if (berthGroupId)
            urlArgs.push('berthGroupId=' + berthGroupId);

        if (nauticalGoalId)
            urlArgs.push('nauticalGoalId=' + nauticalGoalId);

        url += '?' + urlArgs.join('&');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function checkAvailabilityByBerth(berthId, loa, nauticalGoalId, commercialGoalId, startDate, endDate) {

        var deferred = common.$q.defer();

        // Convert the dates to a Moment object for easy manipulation
        startDate = (moment.isMoment(startDate) ? startDate : moment(startDate));
        endDate = (moment.isMoment(endDate) ? endDate : moment(endDate));
        // Convert the Moment dates to a string
        startDate = startDate.format('YYYY-MM-DD HH:mm');
        endDate = endDate.format('YYYY-MM-DD HH:mm');

        var url = '/berthgroup/berth/' + berthId + '/check';

        var urlArgs = [];
        if (loa) {
            urlArgs.push('loa=' + loa);
        }

        if (nauticalGoalId) {
            urlArgs.push('nauticalGoalId=' + nauticalGoalId);
        }

        if (commercialGoalId) {
            urlArgs.push('commercialGoalId=' + commercialGoalId);
        }
        
        if (typeof startDate !== 'undefined' && startDate !== null) {
            urlArgs.push('startDate=' + startDate);
        }
        if (typeof endDate !== 'undefined' && endDate !== null) {
            urlArgs.push('endDate=' + endDate);
        }

        url += '?' + urlArgs.join('&');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function messagesToLocalTime(data) {
        return _.map(data, function (item) {
            item.createdOn = moment(item.createdOn).add( moment().utcOffset(), 'minutes').format('DD MMMM YYYY HH:mm');
            return item;
        });
    }
    function compareHours(date1, date2) {
        let difference = moment(date1).diff(moment(date2), 'hours');
        if (difference !== 0) {
            return false;
        } else {
            return true;
        }
    }
}
