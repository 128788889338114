﻿module.exports = ['common', 'fleetDataService', 'reservationWizardService', 'constantsService', reservationWizardStep1Directive];

function reservationWizardStep1Directive(common, fleetData, reservationWizard, constantsService) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep1Controller,
        controllerAs: 'ctrlReservationWizard1',
        restrict: 'E',
        templateUrl: 'templates/reservations/directives/reservation-wizard-step1.html'
    };

    return directive;

    function reservationWizardStep1Controller() {
        var vm = this;
        vm.addNewVessel = false;
        vm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        };

        vm.etaChanged = etaChanged;
        vm.loadVessels = loadVessels;
        vm.next = next;
        vm.vessels = [];
        vm.wizard = reservationWizard;

        //load vessels on initial
        loadVessels();

        function etaChanged(newEta) {
            if (newEta === undefined)
                return;

            var shipType = reservationWizard.getVesselType();
            var config = constantsService.shipTypeConfigs[shipType];
            
            if (config && config.maxAtdMin) {
                if (config.maxAtdMin > 0) {
                    reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(config.maxAtdMin, 'minutes').toDate();
                }
            } else if (reservationWizard.reservation.eta >= reservationWizard.reservation.etd) {
                reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(8, 'hours').toDate();
            }
        }

        function loadVessels() {
            fleetData.getFleet()
                .then(
                    function (result) {
                        vm.vessels = null;
                        vm.vessels = _.filter(result, function (vessel) {
                            return vessel.isActive;
                        });
                        if (!reservationWizard.reservation.selectedVessel && vm.vessels.length === 1)
                            reservationWizard.reservation.selectedVessel = vm.vessels[0];

                    }, function (response, status, headers, config) {
                        //console.log(response)
                    });
        }

        function next() {
            if (reservationWizard.validation.step1.validate(vm.addNewVessel)) {
                if (vm.addNewVessel)
                    reservationWizard.reservation.selectedVessel = null;
                else
                    reservationWizard.reservation.newVessel = null;

                reservationWizard.currentStep = 2;
            }
        };
    }

};