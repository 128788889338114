﻿'use strict';

module.exports = ['$location', '$routeParams','$translate', 'common', 'userDataService', 'userModelsService', 'widgetModals', userOverviewController];

function userOverviewController($location, $routeParams, $translate,  common, userData, userModels, widgetModals) {

    var vm = {
        addUser: addUser,
        attached: attached,
        common: common,
        deleteUser: deleteUser,
        editingUser: null,
        getDescription: getDescription,
        newUser: new userModels.userRegistrationModel(),
        saveUser: saveUser,
        status: {
            completed: false,
            errors: [],
            loading: false,
            saving: false,
            setAddNew: setAddNew,
            setEdit: setEdit,
            showAddNew: false
        },
        users: [],
        selectedLink: selectedLink,
        currentlySelected: false,
        goBack: goBack
    };

    function load() {
        $translate([
            'views.users.useroverview.deleteuserquestion',
            'views.users.usermodels.errors.displayname',
            'views.users.usermodels.errors.missingemail'
        ]).then(function (translations) { vm.translations = translations; });
    }

    function getDescription(user) {
        var result = '';
        if (user === undefined || user === null) return result;

        if (user.emailAddress !== null && user.emailAddress !== undefined && user.emailAddress.trim() !== '') 
            result = user.emailAddress.trim();
        

        if (user.phoneNumber !== null && user.phoneNumber !== undefined && user.phoneNumber.trim() !== '') {
            if (result === '')
                result = user.phoneNumber.trim();
            else
                result = result + ', ' + user.phoneNumber.trim();
        }

        return result;
    }

    function addUser() {
        var validation = userModels.validateUserRegistration(vm.newUser);
        if (validation !== true) {
            vm.status.errors = validation;
            return;
        }

        vm.status.saving = true;

        userData.addUser(vm.newUser)
          .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response)[0];
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.users.push(result);
            vm.newUser = new userModels.userRegistrationModel();
            vm.status.saving = false;
            vm.status.showAddNew = false;
        }
    }

    function attached() {
        loadUsers();

        var userId = $routeParams.userId;
        if (userId !== undefined && userId !== null && userId !== '' && userId > 0) {
            userData.getUser(userId)
            .then(function (result) {
                if (result !== undefined && result !== null) {
                    vm.status.setEdit(result);
                }
            });
        }
    }

    function deleteUserErrorResult(response) {
        vm.status.errors = common.errorToText(response);
        vm.status.saving = false;
    }

    function deleteUserSuccessResult(response) {
        var iUser = _.findIndex(vm.users, function (item) { return item.id === vm.editingUser.id; });
        vm.users.splice(iUser, 1);
        vm.editingUser = null;
        vm.status.saving = false;
    }

    function deleteUser(user) {
        vm.status.errors = [];

        widgetModals.areYouSure(vm.translations['views.users.useroverview.deleteuserquestion']).result
            .then(function (result) {
                if (result === true) {
                    vm.status.saving = true;
                    userData.deleteUser(vm.editingUser)
                    .then(deleteUserSuccessResult, deleteUserErrorResult);
                }
            });
    }

    function loadUsers() {
        vm.status.loading = true;

        userData.getUsers()
            .then(function (result) {
                vm.users = result;
                vm.status.loading = false;
            }, function () {
                vm.status.loading = false;
            });
    }

    function saveUser() {
        vm.status.errors = [];

        var validation = userModels.validateUser(vm.editingUser);
        if (validation !== true) {
            _.map(validation, function (item) {
                vm.status.errors.push(vm.translations[item]);
            });
            return;
        }

        vm.status.saving = true;
        userData.saveUser(vm.editingUser)
            .then(successResult, errorResult);

        function errorResult(response) {
            vm.status.errors = common.errorToText(response);
            vm.status.saving = false;
        }

        function successResult(result) {
            vm.editingUser = null;
            vm.status.completed = true;
            vm.status.saving = false;
        }
    }

    function setAddNew() {

        vm.status.errors = [];
        vm.editingUser = null;
        vm.status.completed = false;
        vm.status.showAddNew = true;
    }

    function setEdit(user) {
        vm.editingUser = null;
        vm.status.completed = false;
        vm.status.errors = [];
        vm.status.showAddNew = false;

        vm.editingUser = user;
    }
    function selectedLink(id) {
        if (vm.editingUser !== null) {
            if (parseInt(id) === parseInt(vm.editingUser.id)) {
                vm.currentlySelected = true;
                return vm.currentlySelected;
            }
        }
    }
    function goBack() {
        $location.path('/users/', false);
    }
    load();
    return vm;
}
