﻿module.exports = ['$window', '$filter', 'common', 'reservationDataService', 'reservationPlanningService', newReservationDolphinStep2];

function newReservationDolphinStep2($window, $filter, common, reservationData, reservationPlanning) {
    var directive = {
        bindToController: true,
        controller: newReservationDolphinStep2Controller,
        controllerAs: 'ctrlStep2',
        restrict: 'E',
        scope: {
            filteredBerths: '=',
            previewsFilter: "=",
            reservationData: '=',
            onSelectedBerth: '&'
        },
        templateUrl: 'templates/reservations-dolphin/directives/new-reservation-step2.html'
    };

    function newReservationDolphinStep2Controller() {

        var vm = {
            attached: attached,
            getRowsForBerth: getRowsForBerth,
            planning: {
                berthDetails: [],
                berths: [],
                calendar: reservationPlanning
            },
            setSelectionBerth: setSelectionBerth,
            displayBerthsFilter: displayBerthsFilter,
            testArr: [],
            checkDisabled: checkDisabled,
            openBerthDetailsPage: openBerthDetailsPage
        };

        function attached() {
            reservationData.getBerthGroupsByBusinessUnit(1)
                .then(function (result) {
                    result = _.map(result, function (berthGroup) {
                        berthGroup.berths = _.map(_.filter(berthGroup.berths, function (activeBerth) { return activeBerth.isActive; }), function (berth) {
                            // If the berth name starts with the group name, remove it for display purposes
                            if (berth.name.indexOf(berthGroup.name) === 0 && berth.name.length > berthGroup.name.length) {
                                berth.name = berth.name.substring(berthGroup.name.length).trim();
                            }
                            return berth;
                        });
                        return berthGroup;
                    });

                    vm.planning.berths = _.filter(result, function (berthGroup) {
                        return berthGroup.berths && berthGroup.berths.length > 0;
                    });
                    calculateBerths();
                });
        }
        function displayBerthsFilter(input) {
            let berth = null;
            if (input === undefined || input === null || input === '') {
                return '';
            }
            if (vm.filteredBerths && vm.filteredBerths.length > 0) {
                berth = _.find(vm.filteredBerths, function (item) {
                    return item.id === input.id;
                });
            } else {
                return;
            }
            if (berth !== undefined && berth !== null) {
                if (berth.availableUntil !== undefined && berth.availableUntil !== null) {
                    if (vm.filter !== undefined && vm.filter !== null && vm.filter.startDate.toISOString() > berth.availableUntil)
                        return;
                }
    
                if (berth.availableFrom !== undefined && berth.availableFrom !== null) {
                    if (vm.filter !== undefined && vm.filter !== null && vm.filter.endDate.toISOString() < berth.availableFrom)
                        return;
                }
    
                return berth;
            }
        }
        
        function checkDisabled(berth) {
            /*
            if (vm.previewsFilter.length > 0) {
                let found = _.find(vm.previewsFilter, function (pf) {
                    return pf.id === berthId;
                });
                if (!found) {
                    console.log(berthId + 'not found in preview');
                    return true;
                }
            }*/

            if ((!vm.planning.calendar.data.planned || vm.planning.calendar.data.planned.length === 0) &&
             (!vm.planning.calendar.data.unplanned || vm.planning.calendar.data.unplanned.length === 0) &&
              (!vm.planning.calendar.data.berthObstructions || vm.planning.calendar.data.berthObstructions.length === 0)) {
                return false;
            }
            let possibleData = [];

            if (vm.planning.calendar.data.planned) {
                var originalCountPlanned = possibleData.length;
                possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.planned, function (item) {
                    var disabled = item.berthId === berth.id && item.eta && moment(vm.reservationData.eta).isSameOrBefore(moment(item.etd)) && moment(vm.reservationData.etd).isSameOrAfter(moment(item.eta));
                    return disabled;
                }));
                if (originalCountPlanned < possibleData.length) {
                    //console.log(berthId + ': found ' + (possibleData.length - originalCountPlanned) + ' planned items');
                }
            }
            if (vm.planning.calendar.data.unplanned) {
                var originalCountUnplanned = possibleData.length;
                possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.unplanned, function (item) {
                    var disabled = item.berthId === berth.id && item.eta && moment(vm.reservationData.eta).isSameOrBefore(moment(item.etd)) && moment(vm.reservationData.etd).isSameOrAfter(moment(item.eta));
                    return disabled;
                }));
                if (originalCountUnplanned < possibleData.length) {
                    //console.log(berthId + ': found ' + (possibleData.length - originalCountUnplanned) + ' unplanned items');
                }
            }
            if (vm.planning.calendar.data.berthObstructions) {
                var originalCountObstr = possibleData.length;
                possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.berthObstructions, function (item) { 

                    var isEnabled = (item.availableUntil !== null && moment(item.availableUntil) <= moment(vm.reservationData.eta)) || (item.availableFrom !== null && moment(item.availableFrom) >= moment(vm.reservationData.etd));

                    return item.berthId === berth.id && !isEnabled;
                }));
                
                if (originalCountObstr < possibleData.length) {
                   // console.log(berthId + ': found ' + (possibleData.length - originalCountObstr) + ' obstruction items');
                }
            }

            var result = _.max(possibleData, function (item) { return item.display.row; });
            var row = result && result.display ? result.display.row + 1 : 0;

            return berth.capacity && berth.capacity > row ? false : true; // true = disabled
        }
        function calculateBerths() {
            var berths = [];
            for (var a = 0; a < vm.planning.berths.length; a++) {
                for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                    berths.push(vm.planning.berths[a].berths[b]);
                }
            }
            vm.planning.berthDetails = berths;
            calculateObstructions();
        }

        function calculateObstructions(obstructions) {
            reservationPlanning.calculateBerths(vm.planning.berthDetails, obstructions);
        }

        function getRowsForBerth(berth) {
            var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
            items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

            if (!items || items.length === 0)
                return 1;

            var result = _.max(items, function (item) { return item.display.row; });
            var row = result ? result.display.row + 1 : 1;

            return berth.capacity && berth.capacity > row ? row + 1 : row;
        }

        function setSelectionBerth(selectionBerth) {
            if (checkDisabled(selectionBerth)) {
                return;
            }
            if (vm.onSelectedBerth)
                vm.onSelectedBerth({ filter: { berth: selectionBerth } });
        }

        function openBerthDetailsPage() {
            $window.open(common.config.publicBerthInfoUrl, '_blank');
        }

        return vm;
    }
    return directive;
}