﻿module.exports = ['constantsService', 'reservationDataService', 'reservationDolphinModelsService', '$routeParams', newReservationDolphinStep1];

function newReservationDolphinStep1(constants, reservationDataService, reservationDolphinModels, $routeParams) {
    var directive = {
        bindToController: true,
        controller: newReservationDolphinStep1Controller,
        controllerAs: 'ctrlStep1',
        restrict: 'E',
        scope: {
            onSelectionChanged: '&',
            isBlurred: "=",
            showAll: "=",
            previewsFilter: "="
        },
        templateUrl: 'templates/reservations-dolphin/directives/new-reservation-step1.html'
    };

    function newReservationDolphinStep1Controller() {
        var vm = {
            berthGroups: [],
            changeSelection: changeSelection,
            constants: constants,
            commercialGoals: [],
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yyyy H:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            },
            errors: [],
            filter: {
                berthGroupId: null,
                commercialGoalId: null,
                eta: null, //moment().toDate(),
                etd: null, //moment().add(7, 'days').toDate(),
                loa: null,
                nauticalGoalId: null
            },
            init: init,
            nauticalGoals: [],
            // setEtd: setEtd,
            clearView: clearView
        };

        function changeSelection() {
            var nauticalGoal = _.find(vm.nauticalGoals, function (goal) { return goal.id === vm.filter.nauticalGoalId; });
            var nauticalGoalPriorityLevel = !nauticalGoal ? "" : nauticalGoal.priorityLevel;

            vm.errors = reservationDolphinModels.validateReservation(vm.filter, nauticalGoalPriorityLevel);

            if (vm.errors.length === 0 && vm.onSelectionChanged) {
                vm.onSelectionChanged({ filter: vm.filter });
                vm.showAll = true;
            }
        }

        function getBerthGroups() {
            reservationDataService.getBerthGroupsByBusinessUnit(1)
                .then(function (result) {
                    vm.berthGroups = _.map(result, function (berthGroup) {
                        berthGroup.berths = _.map(berthGroup.berths, function (berth) {
                            // If the berth name starts with the group name, remove it for display purposes
                            if (berth.name.indexOf(berthGroup.name) === 0 && berth.name.length > berthGroup.name.length) {
                                berth.name = berth.name.substring(berthGroup.name.length).trim();
                            }
                            return berth;
                        });
                        return berthGroup;
                    });

                    if ($routeParams.eta && $routeParams.etd) {
                        vm.filter.eta = moment($routeParams.eta).toDate();
                        vm.filter.etd = moment($routeParams.etd).toDate();

                        if ($routeParams.commercialGoalId) {
                            vm.filter.commercialGoalId = parseInt($routeParams.commercialGoalId);
                        }

                        if ($routeParams.berthGroupId) {
                            vm.filter.berthGroupId = parseInt($routeParams.berthGroupId);
                        } else if ($routeParams.berthId) {
                            var bId = parseInt($routeParams.berthId);
                            var bg = _.find(vm.berthGroups,
                                function (group) {
                                    return _.find(group.berths, function (b) { return b.id === bId; }) !== undefined;
                                });

                            if (bg) {
                                vm.filter.berthGroupId = bg.id;
                            }
                        }

                        if ($routeParams.loa) {
                            vm.filter.loa = parseInt($routeParams.loa);
                        }

                        if ($routeParams.nauticalGoalId) {
                            vm.filter.nauticalGoalId = parseInt($routeParams.nauticalGoalId);
                            changeSelection(); // when the nauticalGoal is filled, validate the parameters immediately
                        }
                    }
                });
        }

        function init() {
            vm.errors = [];
            constants.preloaded
                .then(function () {
                    vm.commercialGoals = _.filter(constants.commercialGoals, function (item) { return item.businessUnit === 1; });
                    vm.nauticalGoals = _.filter(constants.nauticalGoals, function (item) { return item.businessUnit === 1; });
                }, function () { });

            getBerthGroups();
            //changeSelection('all');
        }

        //function setEtd() {
        //    vm.filter.etd = moment(vm.filter.eta).add(7, 'days').toDate();
        //}

        function clearView() {
            vm.showAll = false;
            vm.isBlurred = false;
            vm.previewsFilter = [];
        }
        return vm;
    }
    return directive;
}