﻿module.exports = [calendarItemsFilter];

function calendarItemsFilter() {

    return function (items, berth, reservationId) {
        if (!items || items.length == 0)
            return [];
        return _.filter(items, function (item) {
            var isMatch = false;
            var fieldToMatch = item.assignedToBerthId;
            if (fieldToMatch == null || fieldToMatch == undefined)
                fieldToMatch = item.berthId;
            if (fieldToMatch == null || fieldToMatch == undefined)
                fieldToMatch = item.berth.id;
            if (fieldToMatch == null || fieldToMatch == undefined)
                return;

            if (berth && berth.id)
                isMatch = fieldToMatch == berth.id;
            else
                isMatch = fieldToMatch == null || fieldToMatch == -1;
            if (isMatch && reservationId)
                isMatch = item.reservationId == reservationId

            return isMatch;
        });
    };

};