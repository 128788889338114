﻿module.exports = [ 'fleetDataService','reservationWizardGenericService', genericReservationSelectVessel];

function genericReservationSelectVessel(fleetData,reservationWizard) {
    var directive = {
        bindToController: true,
        controller: genericReservationSelectVesselController,
        controllerAs: 'ctrlGenericReservationSelectVessel',
        restrict: 'E',
        scope: {
            shipTypeIds: '=',
            onAddNewVessel: '&'
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-select-vessel.html'
    };

    return directive;

    function genericReservationSelectVesselController() {

        var vm = {
            vessels:null,
            init:init,
            wizard:reservationWizard,
            addNewVessel:false,
            setAddNewVessel:setAddNewVessel,
            isRequired:isRequired
        };
    
        function init() {
            loadVessels();
        }

        function loadVessels() {
            fleetData.getFleet(vm.shipTypeIds)
                .then(
                    function (result) {
                        vm.vessels = null;
                        vm.vessels = _.filter(result, function (vessel) {
                            return vessel.isActive;
                        });
                        if (!reservationWizard.reservation.selectedVessel && vm.vessels.length === 1)
                            reservationWizard.reservation.selectedVessel = vm.vessels[0];

                    }, function (response, status, headers, config) {
                        //console.log(response)
                    });
        }

        function setAddNewVessel(value){
            vm.onAddNewVessel({value:value});
            vm.addNewVessel = value;
        }

        function isRequired(){
            return reservationWizard.isRequired('selectedVessel');
        }

        return vm;
    }
}