﻿module.exports = ['$location', 'reservationWizardSeacruiseService', newReservationSeacruiseController];

function newReservationSeacruiseController($location, reservationWizardSeacruise) {

    var vm = {
        wizard: reservationWizardSeacruise
    };

    init();
    function init() {
        vm.wizard.clear();
    }

    return vm;
};