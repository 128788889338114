﻿module.exports = {
    'userOverview': 'User Overview',
    'addUser': 'Add User',
    'editUser': 'Edit User',
    'changeSucessful': 'Your changes have been saved successfully:',
    'errorSaving': 'There was an error saving your changes:',
    'saveUser': 'Save User',
    'deleteUser': 'Delete User',
    'addNewUser': 'Add new User',
    'errorAddingUser': 'There was an error adding the user:',
    'deleteuserquestion': 'You want to delete this user from your account?',
    'loadingUsers': 'The users are being loaded...',
    'noUsers': 'No users have been added yet...'
};