﻿module.exports = {
    'addNew': 'Voeg een nieuwe reservering',
    'vesselName': 'Schip naam',
    'selectVessel': 'Selecteer een schip',
    'eni': 'ENI',
    'addNewVessel': 'Voeg een nieuw schip',
    'resProblem': 'Er is een probleem met uw reservering:',
    'greenAwardWarning': 'Het geselecteerde schip mist de Green Award benodigd voor deze ligplaats. Houd er rekening mee dat de reservering niet kan worden gegarandeerd, aangezien schepen met de benodigde Green Award voorrang hebben op de geselecteerde ligplaats.',
    'greenAwardDenied': 'Het geselecteerde schip mist de Green Award benodigd voor deze ligplaats.',
    'vesselHasNoGreenAward': 'Geselecteerde schip heeft geen Green Award',
    'vesselHasBronzeGreenAward': 'Geselecteerde schip heeft een bronze Green Award',
    'vesselHasSilverGreenAward': 'Geselecteerde schip heeft een zilveren Green Award',
    'vesselHasGoldGreenAward': 'Geselecteerde schip heeft een goude Green Award',
    'berthHasPlatinumGreenAward': 'Geselecteerde schip heeft een platinum Green Award',
    'berthHasNoGreenAward': 'Geselecteerde ligplaats heeft geen Green Award',
    'berthHasBronzeGreenAward': 'Geselecteerde ligplaats heeft een bronze Green Award',
    'berthHasSilverGreenAward': 'Geselecteerde ligplaats heeft een zilvere Green Award',
    'berthHasGoldGreenAward': 'Geselecteerde ligplaats heeft een gouden Green Award',
    'berthHasPlatinumGreenAward': 'Geselecteerde ligplaats heeft een platinum Green Award',    
    'berthGroupHasNoGreenAward': 'Geselecteerde ligplaatsgroep heeft geen Green Award',
    'berthGroupHasBronzeGreenAward': 'Geselecteerde ligplaatsgroep heeft een bronze Green Award',
    'berthGroupHasSilverGreenAward': 'Geselecteerde ligplaatsgroep heeft een zilvere Green Award',
    'berthGroupHasGoldGreenAward': 'Geselecteerde ligplaatsgroep heeft een gouden Green Award',
    'berthGroupHasPlatinumGreenAward': 'Geselecteerde ligplaatsgroep heeft een platinum Green Award',  
    'nextstep': 'Volgende stap',
    'yourRes': 'Uw reservering',
    'berthtbd': 'Ligplaats Tbd.',
    'savedReservationProblem': 'Uw reservering kan niet worden opgeslagen op dit moment',
    'berthselect': 'Selecteer uw ligplaats',
    'sendReservation': 'Verzend reservering',
    'specifyActivities': 'Geef uw activiteiten',
    'specifyActivitiesdetail': 'Gelieve uw activiteiten zo gedetailleerd mogelijk zodat we de optimale ligplaats kan reserveren',
    'specifyAdditionalFacilities': 'Geef aan extra faciliteiten',
    'specifyBerth': 'Vraag een specifiek ligplaats',
    'activityPlace': 'Deze activiteit vindt plaats op',
    'until': 'Tot',
    'remarks': 'Opmerkingen',
    'addActivity': 'Activiteit toevoegen aan mijn reservering',
    'pleasevesselselect': 'Selecteer een schip',
    'updateReservation': 'Update uw reservering',
    'overlappingReservations': 'Aansluitende reservering(en) gevonden',
    'errors': {
        'activitytype': 'Vul a.u.b. een soort activiteit',
        'cargotype': 'Vul a.u.b. een cargo type',
        'validdate': 'Vul a.u.b. een geldige startdatum',
        'validenddate': 'Vul a.u.b. een geldige einddatum',
        'vesselselect': 'Vul a.u.b. een schip',
        'arrivaldate': 'Vul a.u.b. een aankomstdatum',
        'departuredate': 'Vul  a.u.b. een vertrekdatum',
        'arrivalbeforedeparturedate': 'De dag van aankomst kan niet na de vertrekdatum',
        'validarrivaldate': 'Vul aub een geldige aankomstdatum',
        'validdeparturedate': 'Vul aub een geldige vertrekdatum',
        'validUcrn': 'Vul a.u.b. een geldige UCRN',
        'moreThanSevenDays': 'Er kunnen geen reservering gemaakt worden dat langer dan 7x24 uren duurt',
        'moreThanThreeDays': 'Er kunnen geen reservering gemaakt worden dat langer dan 72 uren duurt',
        'noReservation': 'Er is geen reservering gegeven',
        'arrivalDateIsInPast': 'De dag van aankomst kan niet in het verleden',
        'arrivalDateMoreThanThreeDaysInFuture': 'De dag van aankomst kan niet langer dan 72 uren later.',
        'newArrivalBeforeOriginalArrivalDate': 'De dag van aankomst kan niet eerder de oorspronkelijk aankomstdatum',
        'newDepartureAfterOrigianlDepartureDate': 'De dag van vertrek kan niet na de oorspronkelijk vertrekdatum',
        'nocustomer': 'Vul a.u.b. een klant',
        'noInvoiceAddress': 'Vul a.u.b. een factuur adres',
        'noInvoiceReference': 'Vul a.u.b. een factuur reference',
        'minReservationItems':'De reservering bevat niet genoeg activiteiten, voeg meer activiteiten toe.',        
        'maxReservationItems':'De reservering bevat te veel activiteiten dan toegestaan is, verwijder activiteiten.',
        'notAllowedReservationItems':'De reservering is niet toegestaan om activiteiten te bevatten, verwijder alstublieft de activiteiten.',        
        'periodMin':'De periode tussen ETA en ETD is te kort',
        'periodMax':'De periode tussen ETA en ETD is meer dan toegestaan is',
        'etaInPast':'De ETA mag niet in het verleden staan',
        'etdInPast':'De ETD mag niet in het verleden staan',
        'ucrn': 'Specificeer een UCRN'
    }
};