﻿module.exports = ['common', '$routeParams', 'fleetDataService', 'fleetModelService', 'reservationDataService', 'reservationWizardService', 'constantsService', reservationWizardStep1Directive];

function reservationWizardStep1Directive(common, $routeParams, fleetData, fleetModel, reservationData, reservationWizard, constantsService) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep1Controller,
        controllerAs: 'ctrlReservationWizard1',
        restrict: 'E',
        link: function (scope) {
            scope.$watchCollection(function (scope) {
                return {
                    selectedVessel: scope.ctrlReservationWizard1.wizard.reservation.selectedVessel,
                    newVessel: scope.ctrlReservationWizard1.wizard.reservation.newVessel
                };
            },
                function (newValue, oldValue) {
                    //console.log(newValue);
                    let back2Hours = moment().subtract(2, 'hours').toDate();
                    if (newValue.selectedVessel && newValue.selectedVessel.shipTypeId === 2) {
                        scope.ctrlReservationWizard1.datePickerOptions.minDate = back2Hours;
                    }
                });

        },
        templateUrl: 'templates/reservations/directives/reservation-wizard-step1-alternative.html'
    };
    reservationWizardStep1Controller.$inject = ['$scope'];
    return directive;



    function reservationWizardStep1Controller($scope) {
        var vm = this;
        vm.addNewVessel = false;
        vm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ],
            minDate: null
        };
        vm.berthGroups = [];
        vm.etaChanged = etaChanged;
        vm.loadVessels = loadVessels;
        vm.next = next;
        vm.vessels = [];
        vm.wizard = reservationWizard;
        vm.filteredBerthGroups = filteredBerthGroups;
        vm.warnings = [];
        vm.onBerthGroupChanged = onBerthGroupChanged;
        vm.flow = null;
        vm.shipTypeIds = [];

        init();

        function init() {
            if (reservationWizard.reservation.processFlowId === undefined || reservationWizard.reservation.processFlowId === null) {
                if ($routeParams.reservationId !== undefined && $routeParams.reservationId !== null) {
                    reservationData.getReservation(parseInt($routeParams.reservationId))
                        .then(function (reservationResult) {

                            if (reservationResult) {
                                reservationWizard.reservation = {
                                    activities: reservationResult.reservationItems,
                                    berthGroup: reservationResult.reservationItems ? reservationResult.reservationItems[0].berthGroup : null,
                                    eta: new Date(reservationResult.eta),
                                    etd: new Date(reservationResult.etd),
                                    newVessel: new fleetModel.vesselModel(),
                                    selectedVessel: reservationResult.ship,
                                    id: reservationResult.id,
                                    processFlowId: reservationResult.processFlowId
                                };

                                loadData();

                            } else {
                                common.$location.path("/reservations");
                            }
                        });

                } else if ($routeParams.flowId !== undefined && $routeParams.flowId !== null) {
                    reservationWizard.reservation.processFlowId = parseInt($routeParams.flowId); // during create new reservation, the flow id will be set in the url
                    loadData();

                } else {
                    common.$location.path("/reservations"); // no reservation id and flow id are found in the url, redirect to the reservation overview page
                }
            } else {
                loadData();
            }
        }

        function loadData() {
            if (!isNaN(reservationWizard.reservation.processFlowId)) {
                vm.flow = _.find(constantsService.processFlows, function (flow) { return flow.id === reservationWizard.reservation.processFlowId; });
                vm.shipTypeIds = _.pluck(vm.flow.shipTypes, 'id');
            }

            //load vessels on initial
            loadBerths();
            loadVessels();
        }

        function etaChanged(newEta) {
            vm.warnings = [];

            if (newEta === undefined)
                return;

            var shipType = reservationWizard.getVesselType();
            var config = constantsService.shipTypeConfigs[shipType];

            if (shipType && config && config.disableBeforeAtd === true) {
                var atdMin = config && config.maxAtdMin && config.maxAtdMin > 0 ? config.maxAtdMin : 480;
                let backAtdMins = moment().subtract(atdMin, 'minutes').toDate();

                if (moment(reservationWizard.reservation.eta).isBefore(backAtdMins)) {
                    //vm.wizard.reservation.eta = moment().toDate();
                    reservationWizard.validation.step1.errors.push("The arrival date for can't be more then " + atdMin + " mins in past, please check the arrival and departure dates");
                    //vm.warnings.push("The arrival date for party boats can't be more then 2 hours in past, please check the arrival and departure dates");
                    //vm.wizard.reservation.eta = back2Hours;
                }
            }

            if (config && config.maxAtdMin) {
                if (config.maxAtdMin > 0) {
                    reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(config.maxAtdMin, 'minutes').toDate();
                }
            } else if (reservationWizard.reservation.eta >= reservationWizard.reservation.etd) {
                reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(8, 'hours').toDate();
            }
        }

        function loadBerths() {
            if(!vm.flow){
                vm.berthGroups = [];
                return;
            }
            reservationData.getBerthGroupsDetailsByProcessFlow(vm.flow.id)
                .then(function (result) {
                    if (result) {
                        var tempArray = [];
                        for (var i = 0; i < result.length; i++) {
                            var berthGroup = result[i];
                            if (berthGroup.shipTypeIds) {
                                for (var j = 0; j < berthGroup.shipTypeIds.length; j++) {
                                    if (tempArray[berthGroup.shipTypeIds[j]] === undefined) {
                                        tempArray[berthGroup.shipTypeIds[j]] = [];
                                    }
                                    tempArray[berthGroup.shipTypeIds[j]].push(berthGroup);
                                }
                            }
                        }
                        vm.berthGroups = tempArray;
                    }
                });
        }

        function filteredBerthGroups() {
            if (vm.addNewVessel) {
                if (reservationWizard.reservation.newVessel === undefined) {
                    return [];
                } else {
                    return vm.berthGroups[reservationWizard.reservation.newVessel.shipTypeId];
                }
            } else {
                return vm.berthGroups[reservationWizard.reservation.selectedVessel.shipTypeId];
            }

        }
        function loadVessels() {
            fleetData.getFleet(vm.shipTypeIds)
                .then(
                    function (result) {
                        vm.vessels = null;
                        vm.vessels = _.filter(result, function (vessel) {
                            return vessel.isActive;
                        });
                        if (!reservationWizard.reservation.selectedVessel && vm.vessels.length === 1)
                            reservationWizard.reservation.selectedVessel = vm.vessels[0];

                    }, function (response, status, headers, config) {
                        //console.log(response)
                    });
        }

        function next() {
            if (vm.warnings.length > 0)
                return;
            if (reservationWizard.validation.step1.validate(vm.addNewVessel)) {
                var shipTypeId;
                if (vm.addNewVessel) {
                    shipTypeId = reservationWizard.reservation.newVessel.shipTypeId;
                    reservationWizard.reservation.selectedVessel = null;
                }
                else {
                    shipTypeId = reservationWizard.reservation.selectedVessel.shipTypeId;
                    reservationWizard.reservation.newVessel = null;
                }

                // also update the activity time for party boat
                if (reservationWizard.reservation.id > 0 && shipTypeId === 2 && reservationWizard.reservation.activities && reservationWizard.reservation.activities.length === 1) {
                    reservationWizard.reservation.activities[0].startsOn = reservationWizard.reservation.eta;
                    reservationWizard.reservation.activities[0].endsOn = reservationWizard.reservation.etd;
                }

                reservationWizard.currentStep = 2;
            }
        }

        function onBerthGroupChanged() {
            // check whether the selected berhtgroup is changed or not of the existing reservation. when the berthgroup is changed, update the berthgroup of the first reservation item
            if (reservationWizard.reservation.id > 0) {
                if (reservationWizard.reservation.activities && reservationWizard.reservation.activities.length > 0 && reservationWizard.reservation.berthGroup) {
                    reservationWizard.reservation.activities[0].berthGroup = reservationWizard.reservation.berthGroup;
                    reservationWizard.reservation.activities[0].berthGroupId = reservationWizard.reservation.berthGroup.id;
                }
            }
        }
    }
}