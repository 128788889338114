﻿module.exports = ['constantsService', 'fleetDataService', 'fleetModelService', newReservationDolphinShipDetails];

function newReservationDolphinShipDetails(constants, fleetData, fleetModel) {
    var directive = {
        bindToController: true,
        controller: shipDetailsController,
        controllerAs: 'ctrlShipDetails',
        restrict: 'E',
        scope: {
            ship: '=',
            flowId: '='
        },
        templateUrl: 'templates/reservations-dolphin/directives/new-reservation-ship-details.html'
    };

    return directive;

    function shipDetailsController() {

        var vm = {
            constants: constants,
            fleets: [],
            init: init,
            onSelect: onSelect,
            search: search,
            shipTypes: []
        };

        function init() {
            constants.preloaded
                .then(function () {
                    vm.shipTypes = vm.flowId
                        ? constants.processFlows.find(x => x.id === vm.flowId).shipTypes
                        : _.filter(constants.shipTypes, function (item) { return [4, 5].indexOf(item.id) > -1; });
                    if (vm.shipTypes.length === 1) {
                        vm.ship.shipTypeId = vm.shipTypes[0].id;
                    }
                }, function () { });
        }

        function onSelect(item) {
            _.extend(vm.ship, item);
        }

        function search(searchQuery, fieldName, filterStartsWith) {
            vm.fleets = [];

            return fleetData.getExternalFleetsBySearch(fieldName, searchQuery, vm.ship.shipTypeId)
                .then(function (vessels) {
                    if (vessels && vessels.length) {
                        vm.fleets = _.map(vessels,
                            function (vessel) {
                                var fleet = new fleetModel.vesselModel();
                                fleet.name = vessel.name;
                                fleet.shipTypeId = vessel.shipTypeId;
                                fleet.imo = vessel.imo;
                                fleet.eni = vessel.eni;
                                fleet.length = vessel.length;
                                fleet.width = vessel.width;
                                fleet.maxDraught = vessel.maxDraught;
                                fleet.displayNameImo = vessel.imo === undefined || vessel.imo === null ? vessel.name : vessel.name + " - " + vessel.imo;
                                fleet.displayNameEni = vessel.eni === undefined || vessel.eni === null ? vessel.name : vessel.name + " - " + vessel.eni;
                                fleet.displayImo = vessel.imo + " - " + vessel.name;
                                return fleet;
                            });

                        vm.fleets = _.sortBy(vm.fleets, fieldName);
                        if (filterStartsWith === true) {
                            vm.fleets = _.sortBy(vm.fleets, function (fleet) {
                                return fleet[fieldName].toLowerCase().indexOf(searchQuery.toLowerCase()) > 0;
                            });
                        }

                        vm.fleets = _.first(vm.fleets, [10]);
                    }

                    return vm.fleets;
                },
                    function () {
                    });
        }

        return vm;
    }
}