﻿module.exports = ['common', messageListDirective];

function messageListDirective(common) {

    var directive = {
        bindToController: true,
        controller: messageListController,
        controllerAs: 'ctrlMessageList',
        restrict: 'E',
        scope: {
            messages: '='
        },
        templateUrl: 'templates/messages/directives/message-list.html'
    };

    return directive;

    function messageListController() {
        var ctrlMessageList = this;

        ctrlMessageList.formSenderName = function (name) {
            if (!name)
                return '...';

            var nameParts = name.split(' ');
            if (nameParts.length == 0)
                return '...';
            if (nameParts.length == 1)
                return name.substr(0, 2);
            else
                return nameParts[0].substr(0, 1) + nameParts[1].substr(0, 1);
        }
    };

};