﻿module.exports = ['common', '$location', '$uibModalInstance', 'reservationCurrentService', duplicateReservationController];

function duplicateReservationController(common, $location, $uibModalInstance, reservationCurrent) {

    var vm = this;
    var reservationCopy = deepCopy(reservationCurrent);
    var existingReservation = {
        existing: reservationCopy
    };
    vm.addNewVessel = false;
    vm.loadData = loadData;
    vm.next = next;
    vm.closeWindow = closeWindow;
    vm.vessels = [];
    vm.current = reservationCopy;
    vm.sending = false;
    vm.dateDiff = calculateDifference(existingReservation.existing.reservation.etd, existingReservation.existing.reservation.currentEta);
    vm.newEtd = dateDifference(vm.dateDiff, existingReservation.existing.reservation.eta);
    vm.current.reservation.eta = combinedDateAndTime(existingReservation.existing.reservation.eta, vm.current.reservation.arriveTime);
    vm.current.reservation.etd = combinedDateAndTime(vm.newEtd, vm.current.reservation.departTime);
    vm.newReservationItems = [];
        //calculate the differences between arrival day and reservationItems start day, between items start day and item end day
         //calculate the end activity day, combine time and day of the items activity

        existingReservation.existing.reservation.reservationItems.forEach(function (item) {
            let itemDiffStart = calculateDifference(item.startsOn, existingReservation.existing.reservation.currentEta);
            let itemDiffEnd = calculateDifference(item.endsOn, item.startsOn);
            let startDay = dateDifference(itemDiffStart, existingReservation.existing.reservation.eta);
            let endDay = dateDifference(itemDiffEnd, startDay);
            let startsOn = combinedDateAndTime(startDay, item.timeStart);
            let endsOn = combinedDateAndTime(endDay, item.timeEnd);
            item.startsOn = startsOn;
            item.endsOn = endsOn;
            vm.newReservationItems.push(item);
        });

        // Deep Copy of the reservation 
        function deepCopy(obj) {
            var b = JSON.parse(JSON.stringify(obj));
            return b;
        }

        // Find the difference in day between existing ETA and ETD
        function calculateDifference(a, b) {
            let date1 = moment(a); //departure date
            let date2 = moment(b); // arrival date
            date1.startOf('day'); // start time is set to 00:00
            date2.startOf('day'); // start time is set to 00:00
            let timeDiff = Math.abs(date1.valueOf() - date2.valueOf());
            let diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
            //let diffDays = moment(b).diff(moment(a), 'days');
            return diffDays;
        }

        // Add the difference to the new selected ETA
        function dateDifference(diff, date) {
            let result = moment(date);
            result.add(diff, 'day');
            return result;
        }

        //combine new calcualted dates with the time from the existing reservation/planning              
        function combinedDateAndTime(date, time) {
            let minutes = moment(time.minutes, 'mm').format('mm');
            let hours = moment(time.hours, 'h').format('HH');

            let timeString = hours + ':' + minutes + ':00';
            let newDate = moment(date);
            let year = newDate.year();
            let month = (newDate.month() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            let day = (newDate.date()).toString();
            if (day.length === 1) {
                day = '0' + day;
            }
            let dateString = '' + year + '-' + month + '-' + day + '';
            let combined = moment(dateString + ' ' + timeString).format();
            return combined;
        }
        //load vessels on initial
        loadData();
        function loadData() {
            vm.vessels = vm.current.reservation.selectedVessel;
            vm.current.reservation.reservationItems = vm.newReservationItems;
            //console.log(existingReservation);
        }
        function closeWindow(msg) {
             $uibModalInstance.dismiss('cancel');
        }
        function next() {
            vm.sending = true;
            var id = vm.current.reservation.id;
            vm.errors = [];
            //console.log(vm.current.reservation);
            reservationCurrent.save(vm.current.reservation)
                .then(function () {
                    vm.sending = false;
                    common.$location.path('/reservations');
                    $uibModalInstance.close(true);
                });
        }



    return vm;
}