﻿module.exports = [planningController];

function planningController() {

    var vm = {

    };

    return vm;

};