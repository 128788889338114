﻿module.exports = {
    'backOverview': 'Back to Overview',
    'backStepOne': 'Back to step 1',
    'yourReservation': 'Your Reservation',
    'status': 'Status',
    'newReservation': 'New reservation',
    'regionConfirmation': 'Region confirmed',
    'berthGroupConfirmation': 'Berth group confirmed',
    'berthConfirmation': 'Berth confirmed',
    'reservationDetails': 'Details',
    'eta': 'ETA',
    'berth': 'Berth preference',
    'berthAlternative': 'Berth (alternative)',
    'berthGroup': 'Berth Group',
    'facilities': 'Facilities',
    'etd': 'ETD',
    'cancelReservation': 'Cancel Reservation',
    'berthPlanning': 'Berth Planning',
    'region': 'Region',
    'sendReservation': 'Send reservation',
    'changesomething':'Change something?',
    'cancelReservationconfirm': 'You are about to cancel your reservation. This cannot be undone.',
    'confirmed': 'Confirmed',
    'unconfirmed': 'Unconfirmed',
    'noPlanning': 'There is no confirmed planning from the port authority yet',
    'addDates': 'Duplicate reservation',
    'extendReservation': 'Extend reservation',
    'closeWindow': 'Close Window',
    'typeYourReason': 'Type your reason here...',
    'pending': 'Pending'
};