﻿module.exports = [reservationDolphinModelsService];

function reservationDolphinModelsService() {

    var service = {
        convertApiToDisplayModel: convertApiToDisplayModel,
        convertDisplayToApiModel: convertDisplayToApiModel,
        reservationDisplayModel: reservationDisplayModel,
        reservationApiModel: reservationApiModel,
        reservationItemApiModel: reservationItemApiModel,
        validateReservation: validateReservation
    };

    return service;

    function convertApiToDisplayModel(apiModel) {

        var displayModel = reservationDisplayModel();
        displayModel.eta = moment(apiModel.eta).toDate();
        displayModel.etd = moment(apiModel.etd).toDate();
        displayModel.processFlowId = apiModel.processFlowId;

        if (apiModel.primaryPreferredBerthId) {
            displayModel.berthId = apiModel.primaryPreferredBerthId;
        }

        if (apiModel.crn) {
            displayModel.crn = apiModel.crn;
        }

        if (apiModel.invoiceReference) {
            displayModel.invoiceReference = apiModel.invoiceReference;
        }

        if (apiModel.ship) {
            displayModel.ship = apiModel.ship;
        }

        if (apiModel.isAllowToChange !== undefined && apiModel.isAllowToChange !== null) {
            displayModel.isAllowToChange = apiModel.isAllowToChange;
        }

        if (apiModel.reservationItems && apiModel.reservationItems.length > 0) {
            var reservationItem = apiModel.reservationItems[0];
            if (reservationItem.commercialGoalId) {
                displayModel.commercialGoalId = reservationItem.commercialGoalId;
            }

            if (reservationItem.nauticalGoalId) {
                displayModel.nauticalGoalId = reservationItem.nauticalGoalId;
            }

            if (reservationItem.remarks) {
                displayModel.remarks = reservationItem.remarks;
            }
        }

        return displayModel;
    }

    function convertDisplayToApiModel(displayModel) {

        var apiModel = reservationApiModel();
        apiModel.eta = moment(displayModel.eta).toDate();
        apiModel.etd = moment(displayModel.etd).toDate();
        apiModel.processFlowId = displayModel.processFlowId;

        if (displayModel.berthId) {
            apiModel.primaryPreferredBerthId = displayModel.berthId;
        }

        if (displayModel.crn) {
            apiModel.crn = displayModel.crn;
        }

        if (displayModel.invoiceReference) {
            apiModel.invoiceReference = displayModel.invoiceReference;
        }
        
        var reservationItem = reservationItemApiModel();
        reservationItem.startsOn = displayModel.eta;
        reservationItem.endsOn = displayModel.etd;

        if (displayModel.berthId) {
            reservationItem.berthId = displayModel.berthId;
        }

        if (displayModel.commercialGoalId) {
            reservationItem.commercialGoalId = displayModel.commercialGoalId;
        }

        if (displayModel.nauticalGoalId) {
            reservationItem.nauticalGoalId = displayModel.nauticalGoalId;
        }

        if (displayModel.remarks) {
            reservationItem.remarks = displayModel.remarks;
        }

        apiModel.reservationItems = [reservationItem];

        return apiModel;
    }

    function reservationDisplayModel() {
        return {
            berthId: null,
            commercialGoalId: null,
            crn: null,
            eta: null,
            etd: null,
            invoiceReference: null,
            isAllowToChange: true,
            nauticalGoalId: null,
            ship: null,
            processFlowId: null
        };
    }

    function reservationApiModel() {
        return {
            crn: null,
            eta: null,
            etd: null,
            invoiceReference: null,
            primaryPreferredBerthId: null,
            reservationItems: null,
            processFlowId: null
        };
    }

    function reservationItemApiModel() {
        return {
            berthId: null,
            commercialGoalId: null,
            endsOn: null,
            nauticalGoalId: null,
            startsOn: null,
            reservationId: null
        };
    }
    
    /// <summary>
    /// Validates the reservation display model.
    /// </summary>
    /// <param name="displayModel">The reservation display model.</param>
    /// <param name="nauticalGoalPriorityLevel">The PriorityLevel of the nautical goal.</param>
    /// <param name="originalEta">The original eta. When the original eta is given, assume the eta could be changed and need to validate.</param>
    /// <param name="originalEtd">The original etd. When the original etd is given, assume the etd could be changed and need to validate.</param>
    /// <returns>Returns an array of errors. When all fields are validated, an empty array will be returned.</returns>
    function validateReservation(displayModel, nauticalGoalPriorityLevel, originalEta, originalEtd) {
        var errors = [];

        if (!displayModel) {
            errors.push("views.reservations.reservationwizzard.errors.noReservation");
            return errors;
        }

        // ETA is required
        if (!displayModel.eta) {
            errors.push("views.reservations.reservationwizzard.errors.arrivaldate");
        }

        // ETD is required
        if (!displayModel.etd) {
            errors.push("views.reservations.reservationwizzard.errors.departuredate");
        }

        if (displayModel.eta && displayModel.etd) {
            // ensure the date are in the correct format
            var eta = moment(displayModel.eta);
            var etd = moment(displayModel.etd);

            if (etd < eta || etd.diff(eta, 'minutes') <= 1) {
                errors.push("views.reservations.reservationwizzard.errors.arrivalbeforedeparturedate");
            }

            if (displayModel.isNew !== false) { // need to check value is not false, validate when value is undefined, null or true
                switch (nauticalGoalPriorityLevel) {
                    case "2": //Regular
                    case 2:
                        // Rule 1: reservation max 3 days; 3 * 86400000 ms = 259200000. but possible some delay during set the etd data, so 59000 ms (59 sec) margin
                        if ((etd - eta) > 259259000) {
                            errors.push("views.reservations.reservationwizzard.errors.moreThanThreeDays");
                        }

                        // Rule 2: reservation can only made between now and 3 days in the future
                        if (moment().diff(eta, 'days') > 0) {
                            errors.push("views.reservations.reservationwizzard.errors.arrivalDateIsInPast");
                        } else if (moment().diff(eta, 'days') < -2) {
                            errors.push("views.reservations.reservationwizzard.errors.arrivalDateMoreThanThreeDaysInFuture");
                        }
                        break;
                    case "1": //Priority
                    case 1:
                        // reservation max 7 days; 7 * 86400000 ms = 604800000. but possible some delay during set the etd data, so 59000 ms (59 sec) margin
                        if ((etd - eta) > 604859000) {
                            errors.push("views.reservations.reservationwizzard.errors.moreThanSevenDays");
                        }
                        break;
                }
            }

            if (originalEta) {
                var oriEta = moment(originalEta).toDate();
                if (eta < oriEta) {
                    errors.push('views.reservations.reservationwizzard.errors.newArrivalBeforeOriginalArrivalDate');
                }
            }

            if (originalEtd) {
                var oriEtd = moment(originalEtd).toDate();
                if (etd > oriEtd) {
                    errors.push('views.reservations.reservationwizzard.errors.newDepartureAfterOrigianlDepartureDate');
                }
            }
        }

        // Activity type is required
        if (!displayModel.nauticalGoalId) {
            errors.push("views.reservations.reservationwizzard.errors.activitytype");
        }

        // Validate CRN when CRN is set
        var pattern = /(^NLAMS|^NLAMB)/i;
        if (displayModel.crn !== undefined && displayModel.crn !== null && displayModel.crn !== "" && (!pattern.test(displayModel.crn) || displayModel.crn.length !== 13)) {
            errors.push('views.reservations.reservationwizzard.errors.validUcrn');
        }

        return errors;
    }
};