'use strict';

module.exports = ['common', 'dataContext', addressPreferenceDataServices];

function addressPreferenceDataServices(common, context) {

    var vm = {
        getAddressPreferences: getAddressPreferences,
        deleteAddressPreference: deleteAddressPreference,
        addAddressPreference: addAddressPreference,
        updateAddressPreference: updateAddressPreference,
        getCustomersOnly:getCustomersOnly,
        getBasedOnShip:getBasedOnShip,
        getBasedOnReservation:getBasedOnReservation,
        objectType: {
            customer: { id: 0, name: 'Customer' },
            ship: { id: 1, name: 'Ship' },
            reservation: { id: 2, name: 'Reservation' }
        },
        targetType: {
            default: { id: 0, name: 'Default' },
            invoiceTypes: { id: 1, name: 'Invoice Type' },
            shipTypes: { id: 2, name: 'Ship Type' },
            businessUnits: { id: 3, name: 'Business Unit' }
        },
        addressType: {
            email: { id: 0, name: 'Email' },
            debtorNumber: { id: 1, name: 'Debitor Number' }
        }
    };

    return vm;

    function getAddressPreferences(id) {
        var deferred = common.$q.defer();
        if(!id){
            id = 0;
        }

        context.get('/addresspreference/'+id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
    
    function getCustomersOnly() {
        var deferred = common.$q.defer();

        context.get('/addresspreference/customers')
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function getBasedOnShip(id) {
        var deferred = common.$q.defer();

        context.get('/addresspreference/ship/'+id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function getBasedOnReservation(id) {
        var deferred = common.$q.defer();

        context.get('/addresspreference/reservation/'+id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
    
    function getSpecificAddressPreferences(customerId,objectType,objectId,targetType,targetId) {
        var deferred = common.$q.defer();

        var url = '/addresspreference/specific'
        url = url+'?objectType='+objectType;
        url = url+'&objectId='+objectId;
        url = url+'&targetType='+targetType;
        url = url+'&targetId='+targetId;

        context.get(url)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function addAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.post('/addresspreference',addresspreference)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function updateAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.put('/addresspreference/' + addresspreference.id, addresspreference)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }

    function deleteAddressPreference(addresspreference) {
        var deferred = common.$q.defer();

        context.del('/addresspreference/' + addresspreference.id)
            .then(function (data) {
                    deferred.resolve(data);
                },
                function (error) {
                    deferred.reject(error);
                });

        return deferred.promise;
    }
}