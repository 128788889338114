﻿angular.module('poa.rprp.reservationsDolphin', [])
    .config(['$routeProvider', routeConfig])
     // Controllers
    .controller('reservationNewDolphinController', require('./controllers/reservation-new'))
    .controller('changeBerthModalController', require('./modals/change-berth'))
    // Directives
    .directive('newReservationDolphinStep1', require('./directives/new-reservation-step1'))
    .directive('newReservationDolphinStep2', require('./directives/new-reservation-step2'))
    .directive('newReservationDolphinStep3', require('./directives/new-reservation-step3'))
    .directive('newReservationDolphinShipDetails', require('./directives/new-reservation-ship-details'))
    .directive('updateReservationDolphin', require('./directives/update-reservation'))
    // Services
    .factory('reservationDolphinModelsService', require('./services/reservation-models'))
    .factory('reservationDolphinModals', require('./modals/modals'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/reservations/new-public-berth/:flowId', {
            templateUrl: 'templates/reservations-dolphin/controllers/reservation-new.html'
        })
        ;
};
