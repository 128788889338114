﻿'use strict';

module.exports = ['$location', '$rootScope', '$anchorScroll', '$timeout', 'common', 'isoCodesService', 'reservationDataService', 'reservationOverviewStateService', 'widgetModals', 'constantsService', dashboardController];

function dashboardController($location, $rootScope, $anchorScroll, $timeout, common, isoCodesService, reservationData, reservationOverviewState, modals, constantsService) {

    var vm = {
        countryCodes: isoCodesService.countryCodes,
        filter: reservationOverviewState.filter,
        getReservations: getReservations,
        go: go,
        identity: common.identity,
        loading: true,
        myFilter: function (item, b, list) {
            var result = true;

            if (vm.filter.showCancelledReservations === true && result === true) {
                result = item.isCancelled;
            }else{
                result = !item.isCancelled;
                if (vm.filter.showPastReservations === false && result === true) {
                    result = moment().isBefore(item.etd);
                }
            }
            return result;
        },
        reservations: [],
        searchquery: null,
        updateSearch: updateSearch,
        loadingLeft: 2,
        planning: {
            berths: null,
            dates: {
                eta: null,
                etd: null
            },
            data: null
        },
        lastSaved: reservationData.lastSaved,
        scrollTo: scrollTo,
        makeReservationDolphin: false,
        makeReservation: false,
        makeReservationSeacruise: false,
        shipTypes: [],
        processFlows: [],
        compareDates: compareDates,
        constants: constantsService,
        onSelectYear:onSelectYear,
        onSelectFilter: onSelectFilter,
        isMissingCrn: isMissingCrn
    };

    return vm;

    function updateSearch() {
        if(vm.searchquery!==null && vm.searchquery!==undefined 
            && vm.searchquery.trim()==='')
        return vm.getReservations(null);
    }

    function getReservations(searchquery) {

        vm.loading = true;
        reservationData.getReservations(searchquery)
            .then(function (result) {
                result = _.map(result, function (reservation) {
                    if (reservation.communicatedStatus && reservation.communicatedStatus.length > 0) {
                        // TODO need to fix this; the reservation.communicatedStatus should contain an array and not only one status
                        // since other communicatedStatus (cancelled, changed etc...) is stored into the database, the UI needs to updated
                        // need to check the impact of this changes; e.g. in reservation-duplicate-current, calendarblock etc...
                        reservation.communicatedStatus = _.last(_.filter(_.sortBy(reservation.communicatedStatus, 'communicatedOn'), function(obj) { return obj.status <= 3; }));
                    }

                    return reservation;
                });

                vm.filter.initYears(result);

                vm.reservations = _.filter(result,function(item){
                    var sortDate = item.eta;
                    return new Date(sortDate).getFullYear() === vm.filter.selectedYear;
                });
                vm.loading = false;

                if (vm.reservations.length > 0) {
                    if (vm.lastSaved !== null) {
                        if (moment().diff(vm.lastSaved.eta, 'days') > 0) {
                            vm.filter.showPastReservations = true;
                        }
                        $timeout(vm.scrollTo, 300);
                    } else if (_.some(vm.reservations, vm.myFilter) === false) {
                        vm.filter.showPastReservations = true;
                    }
                }
            });
        // Check shipTypes to make the right reservation 
        constantsService.preloaded.then(function () {
            for (var i = 0; i < constantsService.processFlows.length; i++) {
                var flow = constantsService.processFlows[i];
                if (flow.isActive === true && (flow.endsOn === null || moment(flow.endsOn) > moment())) {

                    var cloneObject = _.clone(flow);
                    switch (flow.systemCode) {
                        case "SEA_GOING":
                        case "INLAND":
                            cloneObject.url = '/reservations/new-public-berth/' + flow.id;
                            break;

                        case "SEA_CRUISE":
                            cloneObject.url = '/reservations/new-seacruise/' + flow.id;
                            break;

                        case "RIVER_CRUISE":
                        case "PARTY":            
                            cloneObject.url = '/reservations/new/' + flow.id;
                            break;

                        default:                
                            cloneObject.url = '/reservations/generic/new/' + flow.id;
                    }

                    vm.processFlows.push(cloneObject);
                }
            }
        });
    }

    function scrollTo(id) {
        id = vm.lastSaved.id;
        //$location.hash(id);
        $anchorScroll(id);
    }

    function go(path) {
        $location.path(path);
    }

    function compareDates(reservation) {
        if (reservation.plannedEta && reservation.plannedEtd) {
            let etaCompare = reservationData.compareHours(reservation.plannedEta, reservation.eta);
            let etdCompare = reservationData.compareHours(reservation.plannedEtd, reservation.etd);

            if (etaCompare && etdCompare) {
                return false;
            } else {
                return true;
            }

        }
    }

    function isMissingCrn(reservation) {
        return moment(reservation.eta).diff(moment(), 'days') <= 3 && !reservation.crn && reservation.ship.shipTypeId === 4 ? true : false;
    }
    
    function onSelectYear(){
        getReservations(vm.searchquery);
        vm.filter.onSelectYear();
    }

    function onSelectFilter() {
        vm.filter.onSelectFilter();
    }
}