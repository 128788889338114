﻿angular.module('poa.rprp.messages', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('messageController', require('./controllers/messages'))
    // Directives
    .directive('messageList', require('./directives/message-list'))
    .directive('sendMessage', require('./directives/send-message'))
    // Services
    .factory('messageDataService', require('./services/message-data'))
;

function routeConfig($routeProvider) {
    $routeProvider
    .when('/messages', {
        templateUrl: 'templates/messages/controllers/messages.html'
    })
    .when('/messages/:reservationId', {
        templateUrl: 'templates/messages/controllers/messages.html'
    });
};