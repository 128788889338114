﻿module.exports = ['$translate', 'common', 'fleetDataService', 'fleetModelService', 'reservationDataService', 'businessruleDataService', 'constantsService', reservationWizardService];

function reservationWizardService($translate, common, fleetData, fleetModels, reservationData, businessruleData, constantsService) {

    var service = {
        clear: clear,
        currentStep: 1,
        getFacilitiesDescription: getFacilitiesDescription,
        getVesselType: getVesselType,
        reservation: {
            reservationItems: [],
            eta: null,
            etd: null,
            newVessel: null,
            selectedVessel: null
        },
        prepareToSave: prepareToSave,
        save: save,
        validation: {
            step1: {
                errors: [],
                validate: validateStep1
            },
            step2: {
                errors: [],
                validate: validateStep2
            }
        },
        greenAwardShipTypes:null,
        greenAwardBerthGroupValidation:greenAwardBerthGroupValidation,
        loadedBerthGroupGreenAwardLevel:false,
        selectedBerthGroupId:null,
        selectedBerthGroupGreenAwardLevel:0,
        selectedVesselId:null,
        selectedVesselGreenAwardLevel:0
    };
    load();
    return service;

    function load() {
        service.greenAwardShipTypes = _.find(constantsService.shipPropertyTypes, function (shipPropertyType) { return shipPropertyType.systemCode.toLowerCase() == 'greenaward'});
    }

    function getFacilitiesDescription(facilities) {
        _.reduce(facilities, function (curItem, nextItem) { return curItem + ', ' + nextItem; }, '');
    }

    function clear() {
        service.currentStep = 1;
        service.reservation = {
            reservationItems: [],
            eta: null,
            etd: null,
            selectedVessel: null
        };
        service.validation.step1.errors = [];
        service.validation.step2.errors = [];
    }

    function getVesselType() {
        if (!service.reservation || !service.reservation.newVessel && !service.reservation.selectedVessel)
            return 1;

        if (service.reservation.selectedVessel)
            return service.reservation.selectedVessel.shipTypeId ? service.reservation.selectedVessel.shipTypeId : 1;
        else if (service.reservation.newVessel)
            return service.reservation.newVessel.shipTypeId ? service.reservation.newVessel.shipTypeId : 1;

        return 1;
    }

    function prepareToSave(reservation) {
        var result = reservation;
        if (result.activities) {
            result.reservationItems = _.map(result.activities, function (activity) {
                var item = _.clone(activity); // make a clone, ensure the property of the activity is not changed
                if (!item.endsOn)
                    item.endsOn = moment(item.startsOn).add(1, 'hours').toDate();
                var reservationItemId = item.id ? item.id : null;

                if (item.facilities && item.facilities.length > 0)
                    item.facilities = _.map(item.facilities, function (facility) {
                        return { facilityId: facility.id, reservationItemId: reservationItemId };
                    });
                if (!item.berthGroup)
                    item.berthGroup = result.berthGroup;
                return item;
            });
        }
        else {
            // Manually add one to accomodate the berth selection
            result.reservationItems = [{
                berthGroup: result.berthGroup,
                endsOn: result.etd,
                startsOn: result.eta
            }];
        }

        if (reservation.selectedVessel !== null) {
            result.length = reservation.selectedVessel.length;
            result.shipId = reservation.selectedVessel.id;
            result.ship = reservation.selectedVessel;
        } else if (reservation.newVessel !== null) {
            result.length = reservation.newVessel.length;
            result.shipId = -1;
            result.ship = reservation.newVessel;
        }

        return result;
    }

    function save() {
        var deferred = common.$q.defer();

        if (!validateStep2())
            deferred.reject(error);

        var reservation = prepareToSave(service.reservation);

        if (reservation.selectedVessel !== null) {
            reservation.length = reservation.selectedVessel.length;
            reservation.shipId = reservation.selectedVessel.id;
            var req = reservationData.addOrUpdateReservation(reservation)
                .then(function (data) {
                    clear();
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
        }
        else if (reservation.newVessel !== null) {
            fleetData.addShip(reservation.newVessel)
                .then(function (vessel) {
                    reservation.length = vessel.length;
                    reservation.shipId = vessel.id;

                    var req = reservationData.addOrUpdateReservation(reservation)
                        .then(function (data) {
                            clear();
                            deferred.resolve(data);
                        }, function (error) {
                            deferred.reject(error);
                        });
                }, function (error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise;
    }

    function validateStep1(addNewVessel) {
        service.validation.step1.errors = [];
        var validEta, validEtd = false;
        let lastYear = moment().subtract(1, 'year');
        let endOfTheYear = moment().endOf('year');
        let commingYear = moment(moment(endOfTheYear).add(1, 'year'));


        if (!addNewVessel && (service.reservation.selectedVessel === null || service.reservation.selectedVessel === undefined))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.vesselselect');
        else if (addNewVessel && fleetModels.validateVessel(service.reservation.newVessel) !== true) {
            var errors = fleetModels.validateVessel(service.reservation.newVessel);
            for (var i = 0; i < errors.length; i++) {
                service.validation.step1.errors.push(errors[i]);
            }
        }
        if (service.reservation.eta === null || service.reservation.eta === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivaldate');
        //else if (moment().diff(service.reservation.eta, 'days') > 0)
        //    service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.validarrivaldate');
        else
            validEta = true;

        if (moment(service.reservation.eta).isBefore(lastYear))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooOld');
        else validEta = true;

        if (moment(service.reservation.eta).isAfter(commingYear))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooFar');
        else validEta = true;

        if (service.reservation.etd === null || service.reservation.etd === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.departuredate');
        //else if (moment().diff(service.reservation.etd, 'days') > 0)
        //    service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.validdeparturedate');
        else
            validEtd = true;

        if(service.reservation.eta && !moment(service.reservation.eta).isBefore(moment('2023-01-01')) && greenAwardBerthGroupValidation().requiresWarning)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.greenAwardDenied');

        if (validEta && validEtd && service.reservation.etd < service.reservation.eta)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivalbeforedeparturedate');

        /* Added to accomodate the new reservation screen */
        if (!service.reservation.berthGroup || service.reservation.berthGroup === '')
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.noberth');

        return service.validation.step1.errors.length === 0;
    }

    function validateStep2() {
        var deferred = common.$q.defer();

        service.validation.step2.errors = [];
        if (service.validation.step2.errors.length > 0) {
            deferred.reject(service.validation.step2.errors);
        } else {
            var reservation = prepareToSave(service.reservation);
            if (!reservation.id && reservation.ship.shipTypeId === 1) {
                reservationData.getOverlappingReservations(reservation)
                    .then(function (result) {
                        deferred.resolve({ reservations: result });
                    }, function (error) {
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve({ reservations: [] });
            }
        }

        return deferred.promise;
    }

    function greenAwardBerthGroupValidation() {
        if(service.reservation && service.reservation.selectedVessel && service.reservation.selectedVessel.shipType && service.reservation.selectedVessel.shipType.id && service.greenAwardShipTypes && service.greenAwardShipTypes.shipTypes && service.greenAwardShipTypes.shipTypes.length && _.contains(_.pluck(service.greenAwardShipTypes.shipTypes, 'id'), service.reservation.selectedVessel.shipType.id) && service.reservation.berthGroup && service.reservation.berthGroup.id) {
            if(!service.selectedBerthGroupId || (service.selectedBerthGroupId && service.selectedBerthGroupId != service.reservation.berthGroup.id)) {
                service.selectedBerthGroupId = service.reservation.berthGroup.id;
                service.loadedBerthGroupGreenAwardLevel = false;
      
                let selectedBerthGreenAwardLevels = [];
      
                _.each(service.reservation.berthGroup.berths, function(berth) {
                    if(berth) {
                        businessruleData.getByBerth(berth.id).then(
                            function(rules) {
                                if(rules && rules.length) {
                                    let iRule = 0;
                                    let berthUsingNowDate = false;
                                    let relevantBerthGreenAward = { startDate: null, value: null };
        
                                    _.each(rules, function(rule) {
                                        iRule++;
        
                                        if(rule.businessRuleId == 8 && (!rule.endsOn || (!service.reservation.eta && moment(rule.endsOn).isAfter(moment()) || moment(rule.endsOn).isAfter(moment(service.reservation.eta))))) {
                                            if(!rule.startsOn) {
                                                if(!berthUsingNowDate && !relevantBerthGreenAward.startDate) {
                                                    relevantBerthGreenAward.startDate = moment();
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                                    berthUsingNowDate = true;
                                                }
                                      
                                            } else if(!service.reservation.eta) {
                                                if(!relevantBerthGreenAward.startDate) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                      
                                                } else if(moment(relevantBerthGreenAward.startDate).isBefore(moment(rule.startsOn))) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                      
                                                } else if(berthUsingNowDate) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                                    berthUsingNowDate = false;
                                                }
                                      
                                            } else if(moment(service.reservation.eta).isAfter(moment(rule.startsOn))) {
                                                if(!relevantBerthGreenAward.startDate) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                      
                                                } else if(moment(relevantBerthGreenAward.startDate).isBefore(moment(rule.startsOn))) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                      
                                                } else if(berthUsingNowDate) {
                                                    relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                                    relevantBerthGreenAward.value = rule.businessRuleParameters;
                                                    berthUsingNowDate = false;
                                                }
                                            }
                                        }
        
                                        if(iRule == rules.length) {
                                            if(relevantBerthGreenAward.value) {
                                                switch(relevantBerthGreenAward.value.toLowerCase()) {
                                                    case 'none':
                                                        selectedBerthGreenAwardLevels.push(0);
                                                    break;
        
                                                    case 'bronze':
                                                        selectedBerthGreenAwardLevels.push(1);
                                                    break;
        
                                                    case 'silver':
                                                        selectedBerthGreenAwardLevels.push(2);
                                                    break;
        
                                                    case 'gold':
                                                        selectedBerthGreenAwardLevels.push(3);
                                                    break;
                                                    
                                                    default:
                                                        selectedBerthGreenAwardLevels.push(4);
                                                    break;
                                                }
                                                
                                            } else {
                                                selectedBerthGreenAwardLevels.push(0);
                                            }
      
                                            if(selectedBerthGreenAwardLevels.length == service.reservation.berthGroup.berths.length) {
                                                service.selectedBerthGroupGreenAwardLevel = selectedBerthGreenAwardLevels.length ? Math.max(...selectedBerthGreenAwardLevels) : 0;
                                                service.loadedBerthGroupGreenAwardLevel = true;
                                            }
                                        }
                                    });
                                    
                                } else {
                                    service.selectedBerthGroupGreenAwardLevel = 0;
                                }
                            }
                        );
                    }
                });
            }
      
            if(!service.selectedVesselId || (service.selectedVesselId && service.selectedVesselId != service.reservation.selectedVessel.id)) {
                service.selectedVesselId = service.reservation.selectedVessel.id;
      
                if(service.reservation.selectedVessel.shipProperties && service.reservation.selectedVessel.shipProperties.length) {
                    let iVessel = 0;
                    let vesselUsingNowDate = false;
                    let relevantVesselGreenAward = { startDate: null, value: null };
      
                    _.each(service.reservation.selectedVessel.shipProperties, property => {
                        iVessel++;
      
                        if(property.property == 3 && (!property.endsOn || (!service.reservation.eta && moment(property.endsOn).isAfter(moment()) || moment(property.endsOn).isAfter(moment(service.reservation.eta))))) {
                            if(!property.startsOn) {
                                if(!vesselUsingNowDate && !relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment();
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = true;
                                }
      
                            } else if(!service.reservation.eta) {
                                if(!relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(moment(relevantVesselGreenAward.startDate).isBefore(moment(property.startsOn))) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(vesselUsingNowDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = false;
                                }
      
                            } else if(moment(service.reservation.eta).isAfter(moment(property.startsOn))) {
                                if(!relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(moment(relevantVesselGreenAward.startDate).isBefore(moment(property.startsOn))) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(vesselUsingNowDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = false;
                                }
                            }
                        }
                        
                        if(iVessel == service.reservation.selectedVessel.shipProperties.length) {
                            if(relevantVesselGreenAward.value) {
                                switch(relevantVesselGreenAward.value.toLowerCase()) {
                                    case 'none':
                                        service.selectedVesselGreenAwardLevel = 0;
                                    break;
      
                                    case 'bronze':
                                        service.selectedVesselGreenAwardLevel = 1;
                                    break;
      
                                    case 'silver':
                                        service.selectedVesselGreenAwardLevel = 2;
                                    break;
      
                                    case 'gold':
                                        service.selectedVesselGreenAwardLevel = 3;
                                    break;
                                    
                                    default:
                                        service.selectedVesselGreenAwardLevel = 4;
                                    break;
                                }
                                
                            } else {
                                service.selectedVesselGreenAwardLevel = 0;
                            }
                        }
                    });
      
                } else {
                    service.selectedVesselGreenAwardLevel = 0;
                }
            }

            return { requiresWarning: service.loadedBerthGroupGreenAwardLevel && (service.selectedBerthGroupGreenAwardLevel > service.selectedVesselGreenAwardLevel), vesselLevel: service.selectedVesselGreenAwardLevel, berthGroupLevel: service.selectedBerthGroupGreenAwardLevel };
      
        } else {
            return { requiresWarning: false, vesselLevel: 0, berthGroupLevel: 0 };
        }
    }

}
