﻿module.exports = [addressPreferenceModelsService];
function addressPreferenceModelsService() {

    var service = {
        addressPreferenceModel: addressPreferenceModel
    };

    return service;


    function addressPreferenceModel(addressPreferenceData) {
        var addressPreferenceModel = {
            id: null,
            customerId: null,
            displayName: null,
            objectType: null,
            objectId: null,
            object: null,
            targetType: null,
            targetId: null,
            addressType: null,
            addressValue: null
        };

        if (addressPreferenceData === undefined || addressPreferenceData === null)
            return addressPreferenceModel;

            addressPreferenceModel.id = addressPreferenceData.id;
            addressPreferenceModel.customerId = addressPreferenceData.customerId;
            addressPreferenceModel.displayName = addressPreferenceData.displayName,
            addressPreferenceModel.objectType = addressPreferenceData.objectType;
            addressPreferenceModel.objectId = addressPreferenceData.objectId;
            addressPreferenceModel.object = addressPreferenceData.object;
            addressPreferenceModel.targetType = addressPreferenceData.targetType;
            addressPreferenceModel.targetId = addressPreferenceData.targetId;
            addressPreferenceModel.addressType = addressPreferenceData.addressType;
            addressPreferenceModel.addressValue = addressPreferenceData.addressValue;

        return addressPreferenceModel;
    }

}