﻿module.exports = {
    countries: require('./countries'),
    languages: require('./languages'),
    shared: require('./shared-ui'),
    views: {
        account: {
            login: require('./account/login'),
            logout: require('./account/logout'),
            profile: require('./account/profile')
        },
        fleet: {
            fleet: require('./fleet/fleet'),
            fleetmodels: require('./fleet/fleetmodels'),
            shipform: require('./fleet/shipform')
        },
        messages: {
            messages: require('./messages/messages')
        },
        reservations: {
            reservationdetail: require('./reservations/reservationdetail'),
            reservations: require('./reservations/reservations'),
            reservationwizzard: require('./reservations/reservationwizzard')
        },
        users: {
            usermodels: require('./users/usermodels'),
            useroverview: require('./users/useroverview')
        },
    }
};
