﻿module.exports = {
    'myDetails': 'Mijn details',
    'personalInformation': 'Persoonlijke informatie',
    'companyInfo': 'Bedrijfsinformatie',
    'changePassword': 'Wachtwoord wijzigen',
    'currentPassword': 'Huidig wachtwoord',
    'newPassword': 'Nieuw Wachtwoord',
    'verifyPassword': 'Nieuw wachtwoord bevestigen',
    'update': {
        'failed': 'Vul a.u.b. een bedrijfsnaam in',
        'sucess': 'Vul a.u.b. een e-mailadres in',
        }
};