﻿angular.module('poa.rprp.search', [])
    .config(['$routeProvider', routeConfig])
    .controller('searchResultController', require('./controllers/search-results'))
    .directive('searchControl', require('./directives/search-control'))
    .factory('searchDataService', require('./services/search-data'))
;
function routeConfig($routeProvider) {
    $routeProvider
    .when('/search/:searchText', {
        templateUrl: 'templates/search/controllers/search-results.html'
    });

};