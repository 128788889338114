﻿'use strict';

module.exports = ['common', 'dataContext', financialInfoService];

function financialInfoService(common, context) {
    var service = {
        getFinancialData: getFinancialData,
        sendFinancialData: sendFinancialData,
        getFinancialDataById: getFinancialDataById,
        getFinancialDataByDebtorNumber: getFinancialDataByDebtorNumber,
        getFinancialDataByReservationIdandInvoiceType: getFinancialDataByReservationIdandInvoiceType
    };

    return service;

    function sendFinancialData(data, shipId) {
        var deferred = common.$q.defer();

        var url = '/addresspreference/debtor';
        if (shipId && shipId > 0)
            url += '?shipid=' + shipId;

        var request = context.post(url, data)
            .then(function (response) {
                deferred.resolve(response);
            }, function (error) {
                deferred.reject(error);
            });
        return deferred.promise;
    }

    function getFinancialData(shipId) {
        var deferred = common.$q.defer();

        var url = '/debtor';
        if (shipId && shipId > 0)
            url += '?shipId=' + shipId;

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFinancialDataByDebtorNumber(debtorNumber) {
        var deferred = common.$q.defer();

        debtorNumber = debtorNumber.replace(':', '_');

        var url = '/addresspreference/debtor/' + debtorNumber;
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getFinancialDataById(id) {
        var deferred = common.$q.defer();

        var url = '/addresspreference/debtor';
        if (id && id > 0)
            url += '?id=' + id;
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFinancialDataByReservationIdandInvoiceType(reservationId, invoiceTypeId) {
        var deferred = common.$q.defer();

        var url = '/addresspreference/specific/';
        var urlArgs = [];
        urlArgs.push('objectType=' + 2); // Reservation
        urlArgs.push('objectId=' + reservationId);
        urlArgs.push('targetType=' + 1); // InvoiceTypes
        urlArgs.push('targetId=' + invoiceTypeId);

        url += '?' + urlArgs.join('&');
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}