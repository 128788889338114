﻿'use strict';

module.exports = ['$routeParams', '$rootScope', '$translate', '$window', 'common', 'constantsService', 'messageDataService',
    'reservationDataService', 'reservationPlanningService', 'widgetModals', 'reservationWizardService', 'reservationWizardSeacruiseService',
    'reservationCurrentService', 'reservationDolphinModals', 'reservationDolphinModelsService', 'fleetModelService', 'fleetDataService',
    'financialInfoService', reservationDetailController];

function reservationDetailController($routeParams, $rootScope, $translate, $window, common, constantsService, messageDataService,
    reservationData, reservationPlanning, widgetModals, reservationWizard, reservationWizardSeacruise,
    reservationCurrent, reservationDolphinModals, reservationDolphinModels, fleetModel, fleetData,
    financialInfoService) {

    var vm = {
        berthFilter: berthFilter,
        cancelReservation: {
            showReason: false,
            cancel: cancelReservation
        },
        changeBerthDolphin: changeBerthDolphin,
        goBack: function () { $window.history.back(); },
        remarks: {
            reasons: ['Bad weather', 'COVID', 'High water', 'Voyage cancelled', 'Other'],
            selectedReason: 'Bad weather'
        },
        canUpdateOrCancel: canUpdateOrCancel,
        dateTimeNow: moment().subtract(1, 'd').format(),
        dateTimeNowNoCorrection: moment().format(),
        getItem: getItem,
        getMessages: getMessages,
        showModal: false,
        display: {
            changeMinimumDate: null,
            planningMenuItem: 'messages',
            showReservations: false,
            unrespondedMessages: 0
        },
        loadingLeft: 2,
        planning: {
            berths: null,
            dates: {
                eta: null,
                etd: null
            },
            data: null
        },
        onShowDuplicateDatePicker: onShowDuplicateDatePicker,
        duplicateReservation: duplicateReservation,
        datePickerOptions: {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        },
        editingDolphin: { // only for Dolphin
            reservationDisplayModel: null,
            reservation: null,
            ship: null
        },
        isSaving: false,
        enableChangeButton: true,
        reservation: null,
        reservationPlanning: reservationPlanning,
        updateReservation: updateReservation,
        updateReservationDolphin: updateReservationDolphin,
        updateErrors: [],
        compareDates: compareDates,
        checkPermission: checkPermission,
        reservationCurrent: reservationCurrent,
        lastLink: null,
        agent: null,
        invoiceAddressPreference: null,
        extendReservation: extendReservation,
        isEtaExpired: isEtaExpired,
        openObjectionForm: openObjectionForm
    };

    load();
    return vm;

    function berthFilter(berthId) {
        return function (planningItem) {
            if (planningItem.destination) {
                return planningItem.destination.id === berthId;
            }
        };
    }

    function canUpdateOrCancel() {
        var reservation = vm.reservation;
        //if (reservation !== null && (reservation.communicatedStatus === null || reservation.communicatedStatus.length === 0)) return true;
        if (reservation !== null &&
            reservation.isCancelledByAuthority === false &&
            reservation.isCancelled === false &&
            common.identity.hasPermission('reservation_admin')) return true;

        return false;
    }

    function changeBerthDolphin() {
        reservationDolphinModals.changeBerth(vm.reservation)
            .result
            .then(function (berthSelection) {
                if (berthSelection.berth && berthSelection.remarks) {
                    vm.isSaving = true;
                    vm.reservation.primaryPreferredBerthId = berthSelection.berth.id;
                    if (vm.reservation.reservationItems)
                        vm.reservationItems = _.map(vm.reservation.reservationItems, function (item) { item.berthId = berthSelection.berth.id; return item; });

                    reservationData.addOrUpdateReservation(vm.reservation)
                        .then(function (reservationResult) {
                            var remarks = "I would like to change my berth ";
                            if (vm.reservation.primaryPreferredBerth) {
                                remarks += "from '" + vm.reservation.primaryPreferredBerth.name + "' ";
                            }
                            remarks += "to '" + berthSelection.berth.name + "', because: " + berthSelection.remarks;

                            reservationData.addMessage(vm.reservation.id, remarks, false)
                                .then(function (messageResult) {
                                    vm.getMessages();
                                });

                            vm.getItem();
                            vm.isSaving = false;
                        }).catch(function () {
                            vm.isSaving = false;
                        });
                }
            });
    }

    function checkPermission() {
        if (common.identity.hasPermission('reservation_admin')) return true;

        return false;
    }

    function updateReservation() {
        var reservation = vm.reservation;
        if (vm.canUpdateOrCancel()) {

            // for PublicBerths reservation (Dolphin)
            if (reservation.ship &&
                reservation.ship.shipTypeId && 
                constantsService.isBusinessUnitShipType(1, reservation.ship.shipTypeId)) {
                
                vm.editingDolphin.reservationDisplayModel = reservationDolphinModels.convertApiToDisplayModel(reservation);
                vm.editingDolphin.reservation = {};
                _.extend(vm.editingDolphin.reservation, reservation);
                vm.editingDolphin.ship = {};
                _.extend(vm.editingDolphin.ship, reservation.ship);
            } else {
                var eta = new Date(reservation.eta);
                var etd = new Date(reservation.etd);
                //let eta = moment(reservation.eta).format("DD-MM-YYYY HH:mm");
                //let etd = moment(reservation.etd).format("DD-MM-YYYY HH:mm"); 

                var wizard;
                var flow = _.find(constantsService.processFlows, (f) => { return f.id === reservation.processFlowId; });
                if (flow) {
                    switch (flow.systemCode) {
                        case "SEA_CRUISE":
                            common.$location.path("/reservations/update-seacruise/" + reservation.id);
                            wizard = reservationWizardSeacruise;
                            break;

                        case "RIVER_CRUISE":
                        case "PARTY":
                            common.$location.path("/reservations/update/" + reservation.id);
                            wizard = reservationWizard;
                            break;

                        default:
                            common.$location.path("/reservations/generic/" + reservation.id);
                            wizard = reservationWizard;
                    }
                } else {
                    common.$location.path("/reservations/update/" + reservation.id);
                    wizard = reservationWizard;
                }

                wizard.reservation = {
                    activities: reservation.reservationItems,
                    berthGroup: reservation.reservationItems ? reservation.reservationItems[0].berthGroup : null,
                    eta: eta,
                    etd: etd,
                    newVessel: new fleetModel.vesselModel(),
                    selectedVessel: reservation.ship,
                    id: reservation.id,
                    processFlowId: reservation.processFlowId
                };
                wizard.currentStep = 1;
            }
        }
    }

    function onShowDuplicateDatePicker() {
        vm.reservationCurrent.reservation.eta = moment(vm.reservation.eta).toDate();
    }

    function duplicateReservation(newEta) {
        if (newEta === undefined)
            return;
        var reservation = vm.reservation;
        if (vm.checkPermission()) {
            // If Planning is made the data of the planning will be taken for duplication
            // Structure dates and time is split to get the most accurate calculation 
            let eta = moment();
            let etd = moment();
            if (vm.planning.dates && vm.planning.dates.eta && vm.planning.dates.etd) {
                 eta = moment(vm.planning.dates.eta);
                 etd = moment(vm.planning.dates.etd);
            } else {
                 eta = moment(reservation.eta);
                 etd = moment(reservation.etd);
            }
            let startHours = eta.hours();
            let endHours = etd.hours();
            let startMin = eta.minutes();
            let endMin = etd.minutes();
            reservation.reservationItems.forEach(function (item) {
                let start = moment(item.startsOn);
                let end = moment(item.endsOn);
                let startHours = start.hours();
                let endHours = end.hours();
                let startMin = start.minutes();
                let endMin = end.minutes();
                item.timeStart = { hours: startHours, minutes: startMin };
                item.timeEnd = { hours: endHours, minutes: endMin };
            });

            reservationCurrent.reservation = {
                reservationItems: reservation.reservationItems,
                eta: newEta,
                currentEta: eta,
                etd: etd,
                selectedVessel: reservation.ship,
                id: reservation.id,
                arriveTime: { hours: startHours, minutes: startMin },
                departTime: { hours: endHours, minutes: endMin },
                planning: vm.planning,
                processFlowId: reservation.processFlowId
            };
            widgetModals.duplicateReservationWithNewDates()
                .result.then(function (res) {
                  
                }, function (c) {
                    if (c === 'cancel') {
                        vm.reservationCurrent.reservation.eta = null;
                    }
                });

        }
    }
    function load() {
        $translate([
            'views.reservations.reservationdetail.cancelReservationconfirm'
        ]).then(function (translations) { vm.translations = translations; });

        if (vm.reservationCurrent && vm.reservationCurrent.reservation && vm.reservationCurrent.reservation.eta) {
            vm.reservationCurrent.reservation.eta = null;
        }
    }
    function compareDates(str) {
        if (vm.planning.dates.eta && vm.planning.dates.etd && vm.reservation && vm.reservation.eta && vm.reservation.etd) {
            let etaCompare = reservationData.compareHours(vm.planning.dates.eta, vm.reservation.eta);
            let etdCompare = reservationData.compareHours(vm.planning.dates.etd, vm.reservation.etd);
            if (str === 'eta') {
                if (etaCompare) {
                    return false;
                } else {
                    return true;
                }
            } else if (str === 'etd') {
                if (etdCompare) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (etaCompare && etdCompare) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }
    function calculatePlanning() {
        reservationPlanning.calculateDays(vm.planning.data);

        vm.planning.data = reservationPlanning.calculateItem(vm.planning.data);

        if (vm.planning.data && vm.planning.data.length > 0) {
            vm.planning.dates.eta = vm.planning.data[0].eta;
            vm.planning.dates.etd = vm.planning.data[vm.planning.data.length - 1].etd;
        }
        else {
            vm.planning.eta = null;
            vm.planning.etd = null;
        }


        vm.loadingLeft--;
    }

    function cancelReservation() {
        widgetModals.areYouSure(vm.translations['views.reservations.reservationdetail.cancelReservationconfirm']).result
            .then(function (result) {
                if (result === true) {
                    reservationData.cancelReservation(vm.reservation.id, vm.remarks.selectedReason)
                        .then(function (result) {
                            common.$location.path('/reservations');
                        });
                }
            });
    }

    function getItem() {
        var id = $routeParams.reservationId;

        reservationData.getReservation(id)
            .then(function (reservationResult) {
                vm.reservation = reservationResult;
                if (vm.reservation) {
                    if (vm.reservation.reservationStatus.isIncomplete === true) {
                        var param = { reservationid: vm.reservation.id };
                        common.$location.path("/reservations/new-public-berth/" + vm.reservation.processFlowId).search(param);
                    }

                    if (vm.reservation.communicatedStatus && vm.reservation.communicatedStatus.length > 0)
                        vm.reservation.communicatedStatus = _.filter(_.sortBy(vm.reservation.communicatedStatus, 'communicatedOn'), function (obj) { return obj.status <= 3; });

                    if (vm.reservation.isAllowToChange === false) {
                        // not allow to change the saved values, but if some fields are empty, still allow to set the value
                        var hasEmptyField = false;
                        if (!vm.reservation.crn || vm.reservation.crn === "") {
                            hasEmptyField = true;
                        }

                        if (!vm.reservation.invoiceReference || vm.reservation.invoiceReference === "") {
                            hasEmptyField = true;
                        }

                        if (vm.reservation.ship.shipTypeId === 4) {
                            if (!vm.reservation.ship.imo || vm.reservation.ship.imo === "") {
                                hasEmptyField = true;
                            }
                        } else {
                            if (!vm.reservation.ship.eni || vm.reservation.ship.eni === "") {
                                hasEmptyField = true;
                            }
                        }
                        vm.enableChangeButton = hasEmptyField;
                    }

                    if (vm.reservation.ship.shipTypeId === 3) {
                        financialInfoService.getFinancialDataByReservationIdandInvoiceType(vm.reservation.id, 3)
                            .then(function (result) {
                                if (result) {
                                    vm.agent = _.first(_.filter(result, function (r) { return r.addressType === 0; }));
                                    vm.invoiceAddressPreference = _.first(_.filter(result, function (r) { return r.addressType === 1; }));
                                }
                            });
                    }

                }

                vm.loadingLeft--;
                getMessages();
            });

        reservationData.getReservationPlanning(id)
            .then(function (planningResult) {
                vm.planning.data = planningResult;

                if (planningResult && planningResult.planningItems && planningResult.planningItems.length > 0) {
                    planningResult.planningItems = _.filter(planningResult.planningItems, function (planningItem) { return planningItem.destination !== null; });

                    vm.planning.berths = _.uniq(_.map(planningResult.planningItems, function (planningItem) {
                        return planningItem.destination;
                    }), false, function (uniqueItem) {
                        return uniqueItem ? uniqueItem.id: null;
                    });

                    calculatePlanning();
                }
                else
                    vm.loadingLeft--;
            }, function () {
                vm.loadingLeft--;
            });

    }

    function getMessages() {
        vm.display.unrespondedMessages = 0;

        if (vm.reservation === null || !common.identity.hasPermission('message,message_admin'))
            return;

        reservationData.getMessages(vm.reservation.id)
            .then(function (result) {
                if (result && result.length > 0) {
                    vm.messages = result[0].messages;
                }

                if (vm.messages) {
                    for (var i = vm.messages.length - 1; i >= 0; i--) {
                        if (vm.messages[i].createdBy.portAuthorityId !== null) {
                            if (vm.messages[i].isIntern)
                                continue;
                            else
                                break;
                        }

                        vm.display.unrespondedMessages++;
                    }
                }
            });
    }

    function updateReservationDolphin() {
        
        vm.editingDolphin.reservation.eta = vm.editingDolphin.reservationDisplayModel.eta;
        vm.editingDolphin.reservation.etd = vm.editingDolphin.reservationDisplayModel.etd;
        vm.editingDolphin.reservation.crn = vm.editingDolphin.reservationDisplayModel.crn;
        vm.editingDolphin.reservation.invoiceReference = vm.editingDolphin.reservationDisplayModel.invoiceReference;
        vm.editingDolphin.reservation.ship = vm.editingDolphin.ship;
        reservationData.addOrUpdateReservation(vm.editingDolphin.reservation)
            .then(function (data) {
                vm.updateErrors = [];
                if (data instanceof Array) {
                    vm.updateErrors = data;
                } else {
                    vm.reservation = data;
                    vm.editingDolphin.reservationDisplayModel = null;
                    vm.editingDolphin.reservation = null;
                    vm.editingDolphin.ship = null;
                }
            }, function (error) {
                vm.updateErrors = [error];
            });
    }

    function extendReservation() {

        var prioLevel = vm.reservation.reservationItems[0].nauticalGoal.priorityLevel;
        var tempModel = reservationDolphinModels.convertApiToDisplayModel(vm.reservation);
        tempModel.eta = moment(vm.reservation.etd).toDate();
        tempModel.etd = moment(vm.reservation.etd).add(prioLevel === 1 ? 7 : 3, 'days').toDate();
        tempModel.reservationItems = vm.reservation.reservationItems;
        tempModel.reservationItems[0].startsOn = moment(tempModel.eta).toDate();
        tempModel.reservationItems[0].endsOn = moment(tempModel.etd).toDate();

        // If available, use the berth from the planning object instead of the reservation
        if (vm.planning && vm.planning.berths && vm.planning.berths.length > 0) {
            tempModel.reservationItems[0].berth = vm.planning.berths[0];
            tempModel.reservationItems[0].berthId = vm.planning.berths[0].id;
            tempModel.berthId = vm.planning.berths[0].id;
        }

        if (vm.reservation.reservationSlaves && vm.reservation.reservationSlaves.length > 0) {
            tempModel.reservationSlaves = [reservationDolphinModels.convertApiToDisplayModel(vm.reservation.reservationSlaves[0])];
            tempModel.reservationSlaves.reservationItems = vm.reservation.reservationSlaves[0].reservationItems;
            tempModel.reservationSlaves.reservationItems[0].startsOn = moment(tempModel.eta).toDate();
            tempModel.reservationSlaves.reservationItems[0].endsOn = moment(tempModel.etd).toDate();
        }

        widgetModals.extendReservation({ reservation: tempModel, nauticalGoalPriorityLevel: prioLevel })
            .result.then(function () {
                // the save is in the modal and after save the page will be redirect, so don't need handle the result
            }, function () { });
    }

    function isEtaExpired() {
        return moment(vm.reservation.eta) < moment();
    }

    function openObjectionForm() {
        $window.open(common.config.objectionFormUrl, '_blank');
    }
}
