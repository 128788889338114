﻿module.exports = {
    'addNew': 'Add a new reservation',
    'vesselName': 'Vessel name',
    'selectVessel': 'Select a Vessel',
    'eni': 'ENI',
    'addNewVessel': 'Add a New Vessel',
    'resProblem': 'There is a problem with your reservation:',
    'greenAwardWarning': 'The selected vessel is missing the sufficient Green Award for this berth. Please note that the reservation can not be guaranteed as vessels with a sufficient Green Award enjoy priority at the selected berth.',
    'greenAwardDenied': 'The selected vessel is missing the sufficient Green Award for this berth.',
    'vesselHasNoGreenAward': 'Vessel has no Green Award',
    'vesselHasBronzeGreenAward': 'Vessel has bronze Green Award',
    'vesselHasSilverGreenAward': 'Vessel has silver Green Award',
    'vesselHasGoldGreenAward': 'Vessel has gold Green Award',
    'berthHasPlatinumGreenAward': 'Vessel has platinum Green Award',
    'berthHasNoGreenAward': 'Berth has no Green Award',
    'berthHasBronzeGreenAward': 'Berth has bronze Green Award',
    'berthHasSilverGreenAward': 'Berth has silver Green Award',
    'berthHasGoldGreenAward': 'Berth has gold Green Award',
    'berthHasPlatinumGreenAward': 'Berth has platinum Green Award',    
    'berthGroupHasNoGreenAward': 'Berth group has no Green Award',
    'berthGroupHasBronzeGreenAward': 'Berth group has bronze Green Award',
    'berthGroupHasSilverGreenAward': 'Berth group has silver Green Award',
    'berthGroupHasGoldGreenAward': 'Berth group has gold Green Award',
    'berthGroupHasPlatinumGreenAward': 'Berth group has platinum Green Award',    
    'nextstep': 'Next Step',
    'yourRes': 'Your Reservation',
    'berthtbd': 'Berth Tbd',
    'savedReservationProblem': 'Your reservation could not be saved at this time',
    'berthselect': 'Select your berth',
    'sendReservation': 'Send reservation',
    'specifyActivities': 'Specify your activities',
    'specifyActivitiesdetail': 'Please specify your activities as detailed as possible so we can reserve the optimal berth',
    'specifyAdditionalFacilities': 'Specify additional facilities',
    'specifyBerth': 'Request a specific berth',
    'activityPlace': 'This activity will take place on',
    'until': 'Until',
    'remarks': 'Remarks',
    'addActivity': 'Add activity to my reservation',
    'pleasevesselselect': 'Please select a vessel',
    'updateReservation': 'Update your reservation',
    'duplicateReservation': 'New arrival date',
    'customer': 'Customer',
    'pleasecustomerselect': 'Please select a customer',
    'overlappingReservations': 'Found adjacent reservation(s)',
    'errors': {
        'activitytype': 'Please specify an activity type',
        'cargotype': 'Please specify a cargo type',
        'validdate': 'Please specify a valid start date',
        'validenddate': 'Please specify a valid end date',
        'vesselselect':'Please select a vessel',
        'arrivaldate':'Please specify an arrival date',
        'departuredate':'Please specify a departure date',
        'arrivalbeforedeparturedate': 'The arrival date cannot be after the departure date',
        'validarrivaldate': 'Please specify a valid arrival date',
        'validdeparturedate': 'Please specify a valid departure date',
        'noberth': 'Please select a berth',
        'tooOld': 'It is not possible to create reservation that olden then a year', 
        'tooFar': 'it is not possible to create reservation that far in advance',
        'validUcrn': 'Please specify a valid UCRN (or leave it empty when UCRN is unknown)',
        'moreThanSevenDays': 'It is not possible to create reservation that more than 7x24 hours',
        'moreThanThreeDays': 'It is not possible to create reservation that more than 72 hours',
        'noReservation': 'Please specify a reservation',
        'arrivalDateIsInPast': 'The ETA of the reservation cannot be in the past',
        'arrivalDateMoreThanThreeDaysInFuture': 'The ETA of the reservation should be no more than 72 hours in the future',
        'newArrivalBeforeOriginalArrivalDate': 'The arrival date cannot be before the original arrival date',
        'newDepartureAfterOrigianlDepartureDate': 'The departure date cannot be after the original departure date',
        'nocustomer': 'Please select a customer',
        'noInvoiceAddress': 'Please select a invoice address',
        'noInvoiceReference': 'Please enter a invoice reference',
        'minReservationItems':'The reservation has insufficient amount of activities, please add more activities.',        
        'maxReservationItems':'The reservation contains more activities than the maximum amount allowed, please remove activities.',
        'notAllowedReservationItems':'The reservation is not allowed to contain activities, please remove the activities.',
        'periodMin':'The period between ETA and ETD is too short',
        'periodMax':'The period between ETA and ETD is more than is allowed',
        'etaInPast':'The ETA can\'t be set in the past',
        'etdInPast':'The ETD can\'t be set in the past',
        'ucrn': 'Please specify a UCRN'
    }
};