﻿module.exports = ['$uibModalInstance', '$filter', 'modalArguments', 'reservationDataService', 'reservationPlanningService', changeBerthModalController];

function changeBerthModalController($uibModalInstance, $filter, modalArguments, reservationData, reservationPlanning) {
    var vm = {
        args: modalArguments,
        attached: attached,
        cancel: cancel,
        checkDisabled: checkDisabled,
        getRowsForBerth: getRowsForBerth,
        hasError: false,
        ok: ok,
        planning: {
            berthDetails: [],
            berths: [],
            calendar: reservationPlanning
        },
        remarks: null,
        selectedBerth: null,
        setSelectionBerth: setSelectionBerth,
        filteredBerths: [],
        berthErrors: []
    };

    function attached() {
        reservationData.getBerthGroupsByBusinessUnit(1)
            .then(function (result) {
                result = _.map(result, function (berthGroup) {
                    berthGroup.berths = _.map(berthGroup.berths, function (berth) {
                        // If the berth name starts with the group name, remove it for display purposes
                        if (berth.name.indexOf(berthGroup.name) === 0 && berth.name.length > berthGroup.name.length) {
                            berth.name = berth.name.substring(berthGroup.name.length).trim();
                        }
                        return berth;
                    });
                    return berthGroup;
                });

                vm.planning.berths = _.filter(result, function (berthGroup) {
                    return berthGroup.berths && berthGroup.berths.length > 0;
                });
                calculateBerths();
            });

        loadReservations(vm.args);
    }

    function calculateBerths() {
        vm.filteredBerths = [];
        vm.berthErrors = [];

        reservationData.getFilteredBerthGroups(1, [vm.args.processFlowId], vm.args.ship.length, null, null, null, vm.args.eta, vm.args.etd)
            .then(function (res) {
                if (res) {
                    if (res.berthAvailability)
                        vm.filteredBerths = _.map(res.berthAvailability, "id");

                    if (res.errors)
                        vm.berthErrors = res.errors;
                }

                var berths = [];
                for (var a = 0; a < vm.planning.berths.length; a++) {
                    for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                        berths.push(vm.planning.berths[a].berths[b]);
                    }
                }
                vm.planning.berthDetails = berths;
                calculateObstructions();
            });
    }

    function calculateObstructions(obstructions) {
        reservationPlanning.calculateBerths(vm.planning.berthDetails, obstructions);
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function checkDisabled(berth) {
        if (vm.filteredBerths && !_.contains(vm.filteredBerths, berth.id)) {
            return true;
        }

        if ((!vm.planning.calendar.data.planned || vm.planning.calendar.data.planned.length === 0) && (!vm.planning.calendar.data.unplanned || vm.planning.calendar.data.unplanned.length === 0)) {
            return false;
        }
        let possibleData = [];

        if (vm.planning.calendar.data.planned) {
            possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.planned, function (item) {
                return item.berthId === berth.id && item.eta && vm.args.eta <= item.etd && vm.args.etd >= item.eta;
            }));
        }
        if (vm.planning.calendar.data.unplanned) {
            possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.unplanned, function (item) { return item.berthId === berth.id; }));
        }
        if (vm.planning.calendar.data.berthObstructions) {
            possibleData = possibleData.concat(_.filter(vm.planning.calendar.data.berthObstructions, function (item) { return item.berthId === berth.id; }));
        }

        var result = _.max(possibleData, function (item) { return item.display.row; });
        var row = result && result.display ? result.display.row + 1 : 0;

        return berth.capacity && berth.capacity > row ? false : true; // true = disabled
    }

    function getRowsForBerth(berth) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

        if (!items || items.length === 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row; });
        var row = result ? result.display.row + 1 : 1;

        return berth.capacity && berth.capacity > row ? row + 1 : row;
    }

    function loadReservations(filter) {
        //filter.berthGroupId = null;
        //filter.nauticalGoalId = null;
        reservationData.getAvailability(filter.eta, filter.etd, 1, null, null)
            .then(function (result) {
                //vm.status.isBlurred = true;

                reservationPlanning.calculateDays({
                    eta: filter.eta,
                    etd: filter.etd,
                    planningItems: result
                }, 1);

                var mappedItems = _.map(result, function (item) {
                    item.customer = { name: item.customerName };
                    item.ship = { id: item.shipId, name: item.shipName };
                    return item;
                });

                reservationPlanning.calculateItems(_.filter(result, function (r) { return r.isConfirmed; }), 'planned');
                reservationPlanning.calculateItems(_.filter(result, function (r) { return !r.isConfirmed; }), 'unplanned');
            });

    }

    function setSelectionBerth(berth) {
        if (checkDisabled(berth)) {
            return;
        }
        vm.selectedBerth = berth;
    }

    function ok() {
        vm.hasError = false;
        if (vm.remarks === null || vm.remarks === '' || vm.remarks.length === 0) {
            vm.hasError = true;
        } else {
            $uibModalInstance.close({ berth: vm.selectedBerth, remarks: vm.remarks });
        }
    }

    return vm;
}