﻿module.exports = ['common', 'fleetDataService', 'reservationCurrentService', reservationDuplicateCurrentDirective];

function reservationDuplicateCurrentDirective(common, fleetData, reservationCurrent) {
  
 
    var directive = {
        bindToController: true,
        controller: 'duplicateReservationController',
        controllerAs: 'ctrlReservationDuplicate',
        restrict: 'E',
        templateUrl: 'templates/reservations/directives/reservation-duplicate-current.html'
    };


    return directive;

};