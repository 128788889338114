﻿module.exports = ['addressPreferenceDataServices', 'constantsService', 'identity', 'widgetModals', 'toastr', addressPreferencesFormDirective];

function addressPreferencesFormDirective(addressPreferenceDataServices, constantsService, identity, widgetModals, toastr) {
    var directive = {
        bindToController: true,
        controller: addressPreferencesFormController,
        controllerAs: 'ctrlAddressPreferencesForm',
        restrict: 'E',
        scope: {
            addressPreferencesData: '=',
            shipsList: '=',
            onAddressPreferencesUpdate: '&'
        },
        link: addressPreferencesFormLink,
        templateUrl: 'templates/financial/directives/address-preferences-form.html'
    };

    return directive;


    function addressPreferencesFormLink(scope, elm, attrs, ctrl) {
        scope.$watch(function () {
            return ctrl.addressPreferencesData;
        }, function (newValue, oldValue) {
            if (!newValue) {
                return;
            }

            var addressPreferenceId = newValue.id || 0;
            ctrl.isNewAddressPreference = addressPreferenceId <= 0;

            if (ctrl.addressPreferencesData.customerId === null) {
                ctrl.addressPreferencesData.customerId = identity.userInfo.customer.id;
            }

            if (ctrl.isNewAddressPreference && ctrl.addressPreferencesData.objectType === null) {
                ctrl.addressPreferencesData.objectType = addressPreferenceDataServices.objectType.customer.id; // default Customer
                ctrl.addressPreferencesData.objectId = identity.userInfo.customer.id;
            }

            if (ctrl.isNewAddressPreference && ctrl.addressPreferencesData.targetType === null) {
                ctrl.addressPreferencesData.targetType = addressPreferenceDataServices.targetType.invoiceTypes.id; // default InvoiceType
            }

            if (ctrl.isNewAddressPreference && ctrl.addressPreferencesData.addressType === null) {
                ctrl.addressPreferencesData.addressType = addressPreferenceDataServices.addressType.debtorNumber.id; // default debtor number
            }

                ctrl.enableTargetOption = ctrl.addressPreferencesData.targetType !== addressPreferenceDataServices.targetType.default.id;
                ctrl.targetTypeDisplayObject = _.find(_.values(addressPreferenceDataServices.targetType), function (obj) {
                    return obj.id === ctrl.addressPreferencesData.targetType;
                });

            if (ctrl.addressPreferencesData.targetType === addressPreferenceDataServices.targetType.invoiceTypes.id) {
                ctrl.targetOption = identity.userInfo.invoiceTypes;
            }

            if (ctrl.addressPreferencesData.targetType === addressPreferenceDataServices.targetType.shipTypes.id) {
                ctrl.targetOption = constantsService.shipTypes;
            }

            if (ctrl.isNewAddressPreference && ctrl.addressPreferencesData.targetId === null) {
                ctrl.addressPreferencesData.targetId = ctrl.targetOption[0].id;
            }

        }, true);

        scope.$watch(function () {
            return ctrl.shipsList;
        }, function (newValue, oldValue) {
            if (!newValue) {
                return;
            }
            if (ctrl.objectOption.length === 0 && ctrl.shipsList !== null) {
                ctrl.objectOption = ctrl.shipsList;
            }
        }, true);

    }

    function addressPreferencesFormController() {

        var vm = {
            debtorInfo: null,
            deleteAddressPreferences: deleteAddressPreferences,
            saveNewAddressPreferences: saveNewAddressPreferences,
            updateAddressPreference: updateAddressPreference,
            isNewAddressPreference: true,
            enableTargetOption: false,
            objectOption: [],
            targetOption: [],
            status: {
                errors: []
            },
            errorResult: errorResult,
            successResult: successResult,
            loadedDebtorInfo: loadedDebtorInfo,
            targetTypeDisplayObject: null
        };
        return vm;
        
        function saveNewAddressPreferences(result) {
            if (!vm.isNewAddressPreference) return;

            if (vm.addressPreferencesData.addressType === addressPreferenceDataServices.addressType.debtorNumber.id) {
                vm.addressPreferencesData.addressValue = 'TEMPF:' + result.id;
            }

            if (!vm.addressPreferencesData.displayName) {
                if (!result || !result.companyName) {
                    vm.addressPreferencesData.displayName = "(None)";
                } else {
                    vm.addressPreferencesData.displayName = result.companyName;
                }
            }

            addressPreferenceDataServices.addAddressPreference(vm.addressPreferencesData)
                .then(function (result) { vm.successResult(result); },
                    function (error) { vm.errorResult(error); });
        }

        function deleteAddressPreferences() {
            widgetModals.areYouSure('You want to delete this Address?').result
                .then(function (result) {
                    if (result === true) {
                        addressPreferenceDataServices.deleteAddressPreference(vm.addressPreferencesData)
                            .then(function (result) { vm.successResult(result); },
                                function (error) { vm.errorResult(error); });
                    }
                });
        }

        function updateAddressPreference() {

            if (!vm.addressPreferencesData.displayName) {
                if (vm.debtorInfo === null || !vm.debtorInfo.companyName) {
                    vm.addressPreferencesData.displayName = "(None)";
                } else {
                    vm.addressPreferencesData.displayName = vm.debtorInfo.companyName;
                }
            }

            addressPreferenceDataServices.updateAddressPreference(vm.addressPreferencesData)
                .then(function (result) { vm.successResult(result, true); },
                    function (error) { vm.errorResult(error, true); });
        }

        function errorResult(error, showToast) {
            if (showToast === true) {
                toastr.clear();
                toastr.error(error.data);
            }
        }

        function successResult(result, needShowToast) {

            if (needShowToast === true) {
                toastr.success('You data has been successfully saved!');
            }
            vm.onAddressPreferencesUpdate();
        }

        function loadedDebtorInfo(result) {
            vm.debtorInfo = result;
        }
    }
}