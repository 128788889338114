﻿angular.module('poa.rprp.reservationsOverview', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('reservationsController', require('./controllers/reservations'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/reservations', {
            templateUrl: 'templates/reservationsOverview/controllers/reservations.html'
        });
};
