﻿module.exports = ['common', 'dataContext', constantsService];

function constantsService(common, context) {
    var qPreload = common.$q.defer();
    var constants = {
        elementsToLoad: 0,
        hasBusinessUnit: hasBusinessUnit,
        isBusinessUnitShipType: isBusinessUnitShipType,
        init: init,
        isLoaded: false,
        isLoading: false,
        loadShipTypes: loadShipTypes,
        loadShipPropertyTypes: loadShipPropertyTypes,
        preloaded: qPreload.promise,
        businessUnits: [],
        commercialGoals: [],
        nauticalGoals: [],
        processFlows: [],
        shipTypes: [],
        shipPropertyTypes: [],
        systemMessages: [],
        systemInfo: null,
        loadSystemMessages: loadSystemMessages,
        loadSystemInfo: loadSystemInfo,        
        connectorTypes:[
            { id: -1, name: ''},
            { id: 0, name: 'Other'},
            { id: 1, name: '1 large plug (total of 250 Amp)'},
            { id: 2, name: '2 medium plugs (total of 250 Amp)'},
            { id: 3, name: '5 small plugs (total of 400 Amp, Powerlock)'},
        ],
        connectorLocations:[
            { id: null, name: ''},
            { id: 0, name: 'Front End'},
            { id: 1, name: 'Rear End'}
        ],
        shipTypeConfigs: [
            null,
            { req: ["eni", "pax"] }, // 1 = river cruise
            { maxAtdMin: 120, resItemAtdMin: 120, disableBeforeAtd: true, req: ["eni"] }, // 2 = party boat
            { req: ["imo", "pax"] }, // 3 = sea cruise
            { req: ["imo"] }, // 4 = seagoing
            { req: ["eni"] }, // 5 = inland
            {}, // 6 = navy
            {}, // 7 = port authority
            {}, // 8 = pilot
            {}, // 9 = police
            { req: ["imo"] }, // 10 = super jacht
            {
                maxAtdMin: 15, resItemAtdMin: 15,
                prop: {
                    "length": 23.60,
                    "width": 4.40
                }
            } // 11 = canal boat
        ],
        shipIssueList:[],
        loadShipIssueList:loadShipIssueList,
        updateShipIssueList:updateShipIssueList
    };

    function hasBusinessUnit(businessUnit) {
        if (constants.businessUnits.length === 0)
            return false;

        if (typeof (businessUnit) !== "number") {
            businessUnit = parseInt(businessUnit);
        }

        return _.some(constants.businessUnits, function (unit) { return unit.id === businessUnit; });
    }

    function isBusinessUnitShipType(businessUnit, shipType) {
        var businessUnitId = businessUnit;
        if (typeof (businessUnit) !== "number") {
            businessUnitId = parseInt(businessUnit);
        }

        var shipTypeId = shipType;
        if (typeof (shipType) !== "number") {
            shipTypeId = parseInt(shipType);
        }

        var unit = _.find(constants.businessUnits, function (u) { return u.id === businessUnitId; });
        if (unit !== undefined) {
            return _.some(unit.shipTypes, function (type) { return type.id === shipTypeId; });
        }
        return false;
    }

    function init() {
        if (constants.isLoading === true) return;

        constants.isLoading = true;
        // reset the promise
        qPreload = common.$q.defer();
        constants.preloaded = qPreload.promise;
        constants.elementsToLoad = 0;

        constants.elementsToLoad++;
        loadBusinessUnits()
            .then(function (businessUnits) {
                constants.businessUnits = businessUnits;
                if (businessUnits && businessUnits.length > 0) {
                    for (var i = 0; i < businessUnits.length; i++) {
                        constants.shipTypes = _.union(constants.shipTypes, businessUnits[i].shipTypes);
                    }
                    _.uniq(constants.shipTypes, false, function (item) { return item.id; });
                }
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        constants.elementsToLoad++;
        loadCommercialGoals()
            .then(function (cGoals) {
                constants.commercialGoals = cGoals;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        constants.elementsToLoad++;
        loadNauticalGoals()
            .then(function (nGoals) {
                constants.nauticalGoals = nGoals;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        constants.elementsToLoad++;
        loadProcessFlows()
            .then(function (processFlows) {
                constants.processFlows = processFlows;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });

        constants.elementsToLoad++;
        loadShipPropertyTypes()
            .then(function (shipPropertyTypes) {
                constants.shipPropertyTypes = shipPropertyTypes;
                preloadStepDone();
            }, function () {
                preloadStepDone();
            });


        constants.elementsToLoad++;
        loadShipIssueList()
            .then(function (shipIssueList) {
                constants.shipIssueList = shipIssueList;

                preloadStepDone();
            }, function() {
                preloadStepDone();
            });
        
        constants.elementsToLoad++;
        loadSystemMessages()
            .then(function (systemMessages) {
                constants.systemMessages = systemMessages;
                preloadStepDone();
            }, function() {
                preloadStepDone();
            });
    }

    function preloadStepDone() {
        constants.elementsToLoad--;
        if (constants.elementsToLoad === 0) {
            qPreload.resolve();
            constants.isLoaded = true;
            constants.isLoading = false;
        }
    }

    function loadBusinessUnits() {
        var deferred = common.$q.defer();
        var req = context.get('/businessunit')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadCommercialGoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/commercial')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadNauticalGoals() {
        var deferred = common.$q.defer();
        var req = context.get('/goal/nautical')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadProcessFlows() {
        var deferred = common.$q.defer();
        var req = context.get('/processflow')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadShipTypes() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/shiptype')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadShipPropertyTypes() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/shippropertytype')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function loadSystemMessages() {
        var deferred = common.$q.defer();
        if (constants.systemMessages || !constants.systemMessages.length > 0)
        {
            var req = context.get('/system-messages')
                .then(function (data) {
                    constants.systemMessages = data;
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
        } else {
            deferred.resolve(constants.systemMessages);
        }
        return deferred.promise;
    }
    
    function loadSystemInfo() {
        var deferred = common.$q.defer();
        var req = context.get('/account/systeminfo')
            .then(function (data) {
                constants.systemInfo = data;
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function loadShipIssueList() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/ship-issue-list')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }

    function updateShipIssueList() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/ship-issue-list')
            .then(function (data) {
                constants.shipIssueList = data;

                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }

    //init();

    return constants;
}
