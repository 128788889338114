﻿module.exports = [ 'reservationWizardGenericService', '$routeParams', 'businessruleDataService', 'constantsService', reservationGenericController];

function reservationGenericController(reservationWizard, $routeParams, businessruleData, constantsService) {

    var vm = {
        configuration:reservationWizard.configuration,
        options:{
            berthGroups:null,
        },
        flow:null,
        shipTypeIds:null,
        addNewVessel:false,
        onAddNewVessel:onAddNewVessel,
        wizard:reservationWizard,
        next:next,
        loaded:false,
        greenAwardShipTypes:null,
        greenAwardWarning:greenAwardWarning,
        selectedBerthId:null,
        selectedBerthGreenAwardLevel:0,
        selectedVesselId:null,
        selectedVesselGreenAwardLevel:0,
        greenAwardVesselLevel:0,
        greenAwardBerthLevel:0
    };

    load();

    function load(){
        if($routeParams.reservationId){
            vm.wizard.loadReservation($routeParams.reservationId).then(function(){
               init();
            });
        }else{
            reservationWizard.clear();
            init();
        }
    }

    function init() {
        if (vm.wizard.reservation.processFlowId === undefined || vm.wizard.reservation.processFlowId === null) {
            vm.wizard.reservation.processFlowId = parseInt($routeParams.flowId);
        }
        vm.greenAwardShipTypes = _.find(constantsService.shipPropertyTypes, function (shipPropertyType) { return shipPropertyType.systemCode.toLowerCase() == 'greenaward'});
        vm.flow = _.find(constantsService.processFlows, function (flow) { return flow.id === vm.wizard.reservation.processFlowId; });
        reservationWizard.setConfiguration(vm.flow);
        vm.shipTypeIds = _.pluck(vm.flow.shipTypes, 'id');
        vm.loaded =true;
    }

    function onAddNewVessel(value){
        vm.addNewVessel = value;
    }

    function next() {
        if ( reservationWizard.currentStep === 1 && reservationWizard.validation.step1.validate(vm.addNewVessel)) {
            if (vm.addNewVessel)
                reservationWizard.reservation.selectedVessel = null;
            else
                reservationWizard.reservation.newVessel = null;

            // also update the activity time
            if (vm.wizard.reservation.id > 0 && vm.configuration.items.allowItems === false && vm.wizard.reservation.activities && vm.wizard.reservation.activities.length === 1) {
                vm.wizard.reservation.activities[0].startsOn = vm.wizard.reservation.eta;
                vm.wizard.reservation.activities[0].endsOn = vm.wizard.reservation.etd;
            }

            reservationWizard.currentStep = 2;
        }
    }

    function greenAwardWarning() {
        let warningLevel = (!vm.wizard.reservation.eta && moment().isBefore(moment('2023-01-01'))) || moment(vm.wizard.reservation.eta).isBefore(moment('2023-01-01')) ? 'warning' : 'danger';
        let greenAwardBerthValidation = vm.wizard.greenAwardBerthValidation();

        vm.greenAwardVesselLevel = greenAwardBerthValidation.vesselLevel;
        vm.greenAwardBerthLevel = greenAwardBerthValidation.berthLevel;

        return greenAwardBerthValidation.requiresWarning ? warningLevel : null;
    }

    return vm;
}