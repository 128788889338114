﻿module.exports = ['financialInfoService', 'common', 'toastr', financialInformationDirective];

function financialInformationDirective(financialInfoService, common, toastr) {
    var directive = {
        bindToController: true,
        controller: financialInformationController,
        controllerAs: 'ctrlFinancialInformation',
        link: function (scope) {
            //            console.log('link called');
            //            scope.ctrlFinancialInformation.loadInformation();

            scope.$watch('ctrlFinancialInformation.debtorNumber', scope.ctrlFinancialInformation.loadInformation);
            scope.$watch('ctrlFinancialInformation.shipId', scope.ctrlFinancialInformation.loadInformation);
        },
        restrict: 'E',
        scope: {
            debtorNumber: '=',
            isReadOnly: '=',
            isEdit: '=',
            shipId: '=',
            onLoadedByDebtorNumber: '&',
            onSave: '&'
        },
        templateUrl: 'templates/account/directives/financial-information.html'
    };

    return directive;

    function financialInformationController() {
        var ctrlFinancialInformation = this;
        ctrlFinancialInformation.financialData = null;
        ctrlFinancialInformation.financialDataEdit = null;
        ctrlFinancialInformation.isLoading = false;

        ctrlFinancialInformation.loadInformation = function () {
            ctrlFinancialInformation.isLoading = true;
            ctrlFinancialInformation.financialDataEdit = null;

            if (ctrlFinancialInformation.debtorNumber) {
                financialInfoService.getFinancialDataByDebtorNumber(ctrlFinancialInformation.debtorNumber)
                    .then(function (response) {
                        ctrlFinancialInformation.financialData = response;
                        if (ctrlFinancialInformation.isEditOnly) {
                            ctrlFinancialInformation.financialDataEdit = response;
                        }

                        ctrlFinancialInformation.isLoading = false;
                        ctrlFinancialInformation.onLoadedByDebtorNumber({ result: response });
                    }, function (error) {
                        ctrlFinancialInformation.isLoading = false;
                        ctrlFinancialInformation.onLoadedByDebtorNumber({ result: null });
                    });
            } else {
                financialInfoService.getFinancialData(ctrlFinancialInformation.shipId)
                    .then(function (response) {
                        ctrlFinancialInformation.financialData = response;
                        ctrlFinancialInformation.isLoading = false;
                    }, function (error) {
                        ctrlFinancialInformation.isLoading = false;
                    });
            }
        };

        ctrlFinancialInformation.saveFinancialInfo = function () {
            financialInfoService.sendFinancialData(ctrlFinancialInformation.financialDataEdit, ctrlFinancialInformation.shipId)
                .then(function (result) {
                    toastr.success('The information has been sent to our financial department. It can take a while before the changes take effect.', '', { timeOut: 7000 });
                    ctrlFinancialInformation.setEdit(false);
                    ctrlFinancialInformation.onSave({ result: result });
                });
        };

        ctrlFinancialInformation.setEdit = function (enable) {
            if (enable === true || enable === undefined) {
                if (ctrlFinancialInformation.financialData === null)
                    ctrlFinancialInformation.financialDataEdit = {
                        address: null,
                        city: null,
                        cocNumber: null,
                        companyName: null,
                        country: null,
                        debtorNumber: null,
                        hasAutomaticCollection: null,
                        hasGreenCertificate: null,
                        iban: null,
                        invoiceEmail: null,
                        phoneNumber: null,
                        postalCode: null,
                        vatNumber: null
                    };
                else
                    ctrlFinancialInformation.financialDataEdit = ctrlFinancialInformation.financialData;

                ctrlFinancialInformation.isEdit = true;
            }
            else {
                ctrlFinancialInformation.financialDataEdit = null;
                ctrlFinancialInformation.isEdit = false;
            }
        };
    }
}
