﻿module.exports = ['$cookies', '$http', '$location', '$q', '$rootScope', '$timeout', 'config', identityService];

function identityService($cookies, $http, $location, $q, $rootScope, $timeout, config, context) {

    var qPreload = $q.defer();
    var identity = {
        hasPermission: hasPermission,
        isAuthenticated: false,
        permissions: [],
        preloaded: qPreload.promise,
        reloadUserInfo: getUserInfo,
        set: set,
        token: {
            tokenValue: null,
            tokenExpiresOn: null,
            tokenIssuedOn: null
        },
        userInfo: null
    };

    function getFromCookie() {
        var token = $cookies.getObject('authToken');
        if (token !== undefined && token !== null && token !== '') {
            identity.isAuthenticated = true;
            identity.token = token;
            getUserInfo().then(function () {
                qPreload.resolve();
            });
        }
        else
            qPreload.resolve();
    };

    function getUserInfo() {
        var deferred = $q.defer();

        var authHeader = {};

        if (identity.token.tokenValue) {
            authHeader['Authorization'] = 'Bearer ' + identity.token.tokenValue;
        }

        var req = $http({ method: 'GET', url: config.apiHost + '/account/userinfo', headers: authHeader })
            .then(function (result) {
                if (result.data.externalAccountId)
                    identity.isAuthenticated = true;
                identity.userInfo = result.data;
                if (identity.userInfo && identity.userInfo.roles)
                    processPermissions(identity.userInfo.roles);
                    $http({ method: 'GET', url: config.apiHost + '/account/invoicetypes', headers: authHeader })
            .then(function (result) {
                identity.userInfo.invoiceTypes = result.data;
            });
                deferred.resolve(result.data);
            }, function (error) {
                if (error && error.status && error.status === 401) {
                    identity.set(null);
                    $timeout(function () {
                        $location.path('/account/login');
                    }, 1000);
                }

                deferred.reject(error);
            });

        return deferred.promise;
    };

    function hasPermission(name) {
        if (!name)
            return false;

        name = name.toLowerCase();
        let inverseResult = false;
        if (name.indexOf('!') === 0) {
            inverseResult = true;
            name = name.substr(1, name.length - 1);
        }

        if (name.indexOf(',') > -1) {
            var names = name.split(',');
            for (var i = 0; i < names.length; i++) {
                if (_.indexOf(identity.permissions, names[i]) > -1) {
                    return true;
                }
            }
            return false;
        }
        else
            return inverseResult ? !(_.indexOf(identity.permissions, name) > -1) : _.indexOf(identity.permissions, name) > -1;
    };

    function processPermissions(functionRoles) {
        identity.permissions = [];
        if (!functionRoles)
            return;
        for (var a = 0; a < functionRoles.length; a++) {
            for (var i = 0; i < functionRoles[a].roles.length; i++) {
                var permissions = _.map(functionRoles[a].roles[i].permissions, function (item) { return item.internalName; });
                identity.permissions = _.union(identity.permissions, permissions);
            }
        }
    };

    function set(userName, data, rememberMe) {
        if (!data || !data.access_token) {
            identity.isAuthenticated = false;
            identity.permissions = [];
            identity.token.tokenValue = null;
            identity.token.tokenExpiresOn = null;
            identity.token.tokenIssuedOn = null;
            identity.userInfo = null;
            $rootScope.$emit('identity:clear');
            $cookies.remove('authToken');
        }
        else {
            identity.token = {
                tokenValue: data.access_token.token,
                tokenExpiresOn: data.access_token.expiryDate,
                tokenIssuedOn: data.access_token.issueDate
            };

            $cookies.putObject('authToken', identity.token);
        }

        //DONT call getUserInfo() method here, otherwise it causes an infinite loop! because getUserInfo() method calls this method
    }

    getFromCookie();

    return identity;
};
