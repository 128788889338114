﻿module.exports = ['$uibModalInstance', 'modalArguments', 'fleetModelService', modalShipPropertiesController];

function modalShipPropertiesController($uibModalInstance, args, fleetModels) {

    if (!args)
        args = {};

    var properties = [];
    _.each(args.shipProperties, function (shipProperty) {
        if (shipProperty.property === args.shipPropertyType.id) {
            var model = fleetModels.shipPropertyModel(shipProperty);
            model.startsOn = moment(model.startsOn).toDate();

            if (model.endsOn !== undefined && model.endsOn !== null)
                model.endsOn = moment(model.endsOn).toDate();

            properties.push(model);
        }
    });

    var vm = {
        add: add,
        args: args,
        cancel: cancel,
        datePickerOptions: {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        },
        errors: [],
        ok: ok,
        remove: remove,
        shipPropertyType: args.shipPropertyType,
        shipProperties: _.sortBy(properties, 'startsOn')
    };

    function add() {
        var item = fleetModels.shipPropertyModel();
        item.property = vm.shipPropertyType.id;
        item.startsOn = new Date().setHours(0, 0, 0, 0);

        if (vm.shipPropertyType.defaultValues) {
            item.value = vm.shipPropertyType.defaultValues[0];
        }
        vm.shipProperties.push(item);
    }

    function cancel() {
        $uibModalInstance.close(null);
    }

    function ok() {
        vm.errors = [];
        var properties = _.filter(args.shipProperties, function (shipProperty) { return shipProperty.property !== args.shipPropertyType.id; })
        _.each(vm.shipProperties, function (property) {
            property.startsOn = moment(property.startsOn).toDate();

            if (property.endsOn !== undefined && property.endsOn !== null) {
                property.endsOn = moment(property.endsOn).toDate();

                if (moment(property.endsOn) <= moment(property.startsOn)) {
                    vm.errors.push(property.value + ': Ends on cannot be before Starts on');
                }
            }

            properties.push(property);
        });

        if (vm.errors.length > 0) return;

        $uibModalInstance.close(properties);
    }

    function remove(index) {
        vm.shipProperties.splice(index, 1);
    }

    return vm;

}