﻿module.exports = ['$routeParams', 'addressPreferenceDataServices', 'common', 'fleetDataService', 'fleetModelService', 'reservationDataService', 'reservationWizardSeacruiseService', 'userAccountService', reservationWizardStep1Directive];

function reservationWizardStep1Directive($routeParams, addressPreferenceDataServices, common, fleetData, fleetModel, reservationData, reservationWizard, userData) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep1Controller,
        controllerAs: 'ctrlReservationWizard1',
        restrict: 'E',
        templateUrl: 'templates/reservations-seacruise/directives/reservation-wizard-step1.html'
    };

    return directive;

    function reservationWizardStep1Controller() {
        var vm = this;
        vm.addNewVessel = false;
        vm.attached = attached;
        vm.berthGroups = [];
        vm.customers = [];
        vm.invoiceAddresses = [];
        vm.datePickerOptions = {
            altInputFormats: [            
                'd-M-yyyy',          
                'd-M-yyyy H:mm',            
                'd-M-yyyy HH:mm',  
                'dd-MM-yyyy', 
                'dd-MM-yyyy H:mm',       
                'dd-MM-yyyy HH:mm'
            ]
        };

        vm.etaChanged = etaChanged;
        vm.isEditMode = false;
        vm.loadCustomers = loadCustomers;
        vm.loadInvoiceAddresses = loadInvoiceAddresses;
        vm.isInvoiceAddressRequested = isInvoiceAddressRequested;
        vm.next = next;
        vm.vessels = [];
        vm.wizard = reservationWizard;


        function attached() {
            loadBerths();
            loadVessels();
            loadInvoiceAddresses();

            if (vm.wizard.reservation !== undefined &&
                vm.wizard.reservation !== null &&
                vm.wizard.reservation.id !== undefined &&
                vm.wizard.reservation.id !== null) {
                vm.isEditMode = true;
            } else if (vm.wizard.reservation.processFlowId === undefined || vm.wizard.reservation.processFlowId === null) {
                if ($routeParams.reservationId !== undefined && $routeParams.reservationId !== null) {
                    reservationData.getReservation(parseInt($routeParams.reservationId))
                        .then(function (reservationResult) {

                            if (reservationResult) {
                                vm.wizard.reservation = {
                                    activities: reservationResult.reservationItems,
                                    berthGroup: reservationResult.reservationItems ? reservationResult.reservationItems[0].berthGroup : null,
                                    eta: new Date(reservationResult.eta),
                                    etd: new Date(reservationResult.etd),
                                    newVessel: new fleetModel.vesselModel(),
                                    selectedVessel: reservationResult.ship,
                                    id: reservationResult.id,
                                    processFlowId: reservationResult.processFlowId
                                };
                                vm.isEditMode = true;
                            } else {
                                common.$location.path("/reservations");
                            }
                        });

                } else if ($routeParams.flowId !== undefined && $routeParams.flowId !== null) {
                    vm.wizard.reservation.processFlowId = parseInt($routeParams.flowId); // during create new reservation, the flow id will be set in the url

                } else {
                    common.$location.path("/reservations"); // no reservation id and flow id are found in the url, redirect to the reservation overview page
                }
            }
        }

        function etaChanged(newEta) {
            if (newEta === undefined)
                return;

            if (vm.wizard.reservation.eta >= vm.wizard.reservation.etd) {
                vm.wizard.reservation.etd = moment(vm.wizard.reservation.eta).add(8, 'hours').toDate();
            }
        }

        function loadCustomers() {
            userData.getCustomerDebtor([3])
                .then(
                    function (result) {
                        vm.customers = [];
                        if (result !== undefined && result !== null && result.length > 0) {
                            vm.customers = result;
                        }

                        if (vm.wizard.reservation.selectedVessel !== undefined &&
                            vm.wizard.reservation.selectedVessel !== null &&
                            vm.wizard.reservation.selectedVessel.id !== undefined &&
                            vm.wizard.reservation.selectedVessel.id !== null) {

                            fleetData.getDebtorNumberByShipId(vm.wizard.reservation.selectedVessel.id)
                                .then(
                                    function(result) {
                                        if (result && result.length > 0) {
                                            vm.wizard.reservation.customer = _.find(vm.customers, function(customer) { return customer.debtorNumber === result });
                                        }
                                    },
                                    function() {
                                    });
                        }
                    }, function () {
                    });
        }

        function loadInvoiceAddresses(){
            addressPreferenceDataServices.getCustomersOnly().then(function (result) {
                vm.invoiceAddresses = _.filter(result, function (x) { return x.addressType === 1 && !isInvoiceAddressRequested(x.addressValue) && x.displayName !== null; });
            });
        } 

        function isInvoiceAddressRequested(value){
            return value.toLowerCase().indexOf('tempf') !== -1;
        }

        function loadBerths() {
            reservationData.getBerthGroupsByShipType(3)
                .then(function (result) {
                    vm.berthGroups = result;
                });
        }

        function loadVessels() {
            fleetData.getFleet([3])
                .then(
                function (result) {
                    vm.vessels = _.map(_.groupBy(result, 'name'), function (v, k) { return _.first(v); });
                    if (!vm.wizard.reservation.selectedVessel && vm.vessels.length === 1) {
                        vm.wizard.reservation.selectedVessel = vm.vessels[0];
                    }

                    vm.loadCustomers();
                }, function (response, status, headers, config) {
                });
        }

        function next() {
            if (vm.wizard.validation.step1.validate(vm.addNewVessel)) {
                if (vm.addNewVessel)
                    vm.wizard.reservation.selectedVessel = null;
                else
                    vm.wizard.reservation.newVessel = null;

                vm.wizard.currentStep = 2;
           }
        };
    }

};