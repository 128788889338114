﻿module.exports = ['$translate', '$routeParams', 'constantsService', 'fleetModelService', 'reservationDataService', 'widgetModals', 'reservationDolphinModelsService', newReservationDolphinStep3];

function newReservationDolphinStep3($translate, $routeParams, constants, fleetModel, reservationDataService, widgetModals, reservationDolphinModels) {
    var directive = {
        bindToController: true,
        controller: step3Controller,
        controllerAs: 'ctrlStep3',
        restrict: 'E',
        scope: {
            isSaving: "=",
            onFormSubmitted: '&',
            reservationData: '='
        },
        link: function (scope) {
            //console.log(scope);
        },
        templateUrl: 'templates/reservations-dolphin/directives/new-reservation-step3.html'
    };

    return directive;

    function step3Controller() {

        var vm = {
            constants: constants,
            commercialGoals: [],
            createShipModel: createShipModel,
            errors: [],
            errorsSlaveShip: [],
            fleetModel: fleetModel,
            imoFieldForShipType: 4,
            init: init,
            isShipToShip: false,
            nauticalGoals: [],
            newReservation: {
                selectedNauticalGoal: null,
                masterShip: createShipModel(),
                slaveShip: createShipModel()
            },
            shipTypes: [],
            processFlow: null,
            submitForm: submitForm,
            validateShip: validateShip,
            berthValidation: berthValidation,
            setNauticalGoal: setNauticalGoal
        };

        function createShipModel() {
            var model = new fleetModel.vesselModel();
            model.shipTypeId = 4; // default show IMO field
            model.maxDraught = null;
            return model;
        }

        function init() {
            vm.reservationData.berthId = vm.reservationData.berth.id;
            vm.reservationData.crn = null;
            vm.reservationData.invoiceReference = null;
            vm.reservationData.processFlowId = parseInt($routeParams.flowId);
            vm.newReservation.masterShip.length = vm.reservationData.loa;

            constants.preloaded
                .then(function () {
                    vm.commercialGoals = _.filter(constants.commercialGoals, function (item) { return item.businessUnit === 1; });
                    vm.nauticalGoals = _.filter(constants.nauticalGoals, function (item) { return item.businessUnit === 1; });
                    vm.shipTypes = constants.shipTypes;
                    vm.processFlow = _.filter(constants.processFlows, function (flow) { return flow.id === vm.reservationData.processFlowId; })[0];
                    vm.setNauticalGoal();
                },
                    function () { });
        }

        function setNauticalGoal() {
            vm.newReservation.selectedNauticalGoal = _.find(vm.nauticalGoals, function (goal) { return goal.id === vm.reservationData.nauticalGoalId; });
            var patt = /STS/gi;
            if (vm.newReservation.selectedNauticalGoal)
                vm.isShipToShip = patt.test(vm.newReservation.selectedNauticalGoal.name);
        }

        function submitForm() {

            vm.errorsSlaveShip = [];
            vm.errors = reservationDolphinModels.validateReservation(vm.reservationData, vm.newReservation.selectedNauticalGoal ? vm.newReservation.selectedNauticalGoal.priorityLevel : "");

            // validate cargo type
            if (vm.reservationData.commercialGoalId === undefined || vm.reservationData.commercialGoalId === null || vm.reservationData.commercialGoalId === '') {
                vm.errors.push('views.reservations.reservationwizzard.errors.cargotype');
            }

            // validate UCRN
            if (vm.processFlow && vm.processFlow.systemCode === 'SEA_GOING') {
                if (vm.reservationData.crn === undefined || vm.reservationData.crn === null || vm.reservationData.crn === '') {
                    vm.errors.push('views.reservations.reservationwizzard.errors.ucrn');
                }
            }

            // validate the ship and merge the errors
            vm.errors = vm.errors.concat(vm.validateShip(vm.newReservation.masterShip));

            // validate slave ship when it needs and merge the errors
            if (vm.isShipToShip === true) {
                vm.errorsSlaveShip = vm.errorsSlaveShip.concat(vm.validateShip(vm.newReservation.slaveShip));
            }

            if (vm.errors.length > 0 || vm.errorsSlaveShip.length > 0) {
                return;
            }
            vm.berthValidation();
        }

        function validateShip(shipModel) {
            var errors = [];

            if (shipModel.shipTypeId !== vm.imoFieldForShipType) {
                shipModel.imo = null; // ensure the IMO field is clear when ENI needs to validate, otherwise possible get the wrong error message
            } else {
                shipModel.eni = null; // ensure the ENI field is clear when IMO needs to validate, otherwise possible get the wrong error message
            }

            if (shipModel.maxDraught === undefined || shipModel.maxDraught === null || shipModel.maxDraught === '') {
                errors.push('views.fleet.fleetmodels.errors.vesseldraught');
            }

            var validation = fleetModel.validateVessel(shipModel);

            if (validation !== true) {
                errors = validation.concat(errors);
            }

            if (shipModel.dangerousGoods === true && (shipModel.unnumber === undefined || shipModel.unnumber === null || shipModel.unnumber === '')) {
                errors.push('views.fleet.fleetmodels.errors.vesselunnumber');
            }

            return errors; // return empty array when all fields are validated
        }

        function berthValidation() {

            let reservation = {
                masterReservation: reservationDolphinModels.convertDisplayToApiModel(vm.reservationData),
                masterShip: vm.newReservation.masterShip,
                slaveReservation: null,
                slaveShip: null
            };

            var remarksMaster = "Tanker: " + (vm.newReservation.masterShip.tanker === true ? "Yes" : "No");
            if (vm.newReservation.masterShip.tanker === true) {
                remarksMaster += "\nInert: " + (vm.newReservation.masterShip.inert === true ? "Yes" : "No");
            }

            remarksMaster += "\nDangerous goods: " + (vm.newReservation.masterShip.dangerousGoods === true ? "Yes" : "No");
            if (vm.newReservation.masterShip.dangerousGoods === true) {
                remarksMaster += "\nProduct: " + vm.newReservation.masterShip.unnumber;
            }

            for (var i = 0; i < reservation.masterReservation.reservationItems.length; i++) {
                reservation.masterReservation.reservationItems[i].remarks = remarksMaster;
            }

            if (vm.isShipToShip === true) {
                reservation.slaveReservation = reservationDolphinModels.convertDisplayToApiModel(vm.reservationData);
                reservation.slaveReservation.crn = null; // reset the crn of the slave reservation
                reservation.slaveShip = vm.newReservation.slaveShip;

                var remarksSlave = "Tanker: " + (vm.newReservation.slaveShip.tanker === true ? "Yes" : "No");
                if (vm.newReservation.slaveShip.tanker === true) {
                    remarksSlave += "\nInert: " + (vm.newReservation.slaveShip.inert === true ? "Yes" : "No");
                }

                remarksSlave += "\nDangerous goods: " + (vm.newReservation.slaveShip.dangerousGoods === true ? "Yes" : "No");
                if (vm.newReservation.slaveShip.dangerousGoods === true) {
                    remarksSlave += "\nProduct: " + vm.newReservation.slaveShip.unnumber;
                }

                for (var j = 0; j < reservation.slaveReservation.reservationItems.length; j++) {
                    reservation.slaveReservation.reservationItems[j].remarks = remarksSlave;
                }
            }

            if (vm.reservationData.isNew !== false) { // need to check berth if value is not false, always check berth when value is undefined, null or true
                reservationDataService.checkAvailabilityByBerth(vm.reservationData.berth.id, vm.newReservation.masterShip.length, vm.reservationData.nauticalGoalId, vm.reservationData.commercialGoalId, vm.reservationData.eta, vm.reservationData.etd)
                    .then(function (res) {

                        if (res.errors && res.errors.length > 0) {
                            vm.errors = res.errors;
                            return;
                        } else if (res.warnings && res.warnings.length > 0) {
                            widgetModals.reviewWarnings(res.warnings)
                                .result
                                .then(function (res) {
                                    if (res === true) {
                                        // save reservation via the parent controller
                                        if (vm.onFormSubmitted) {
                                            vm.isSaving = true;
                                            vm.onFormSubmitted({ data: reservation });
                                        }
                                    }
                                }).catch(function (err) {

                                });
                        } else {
                            // save reservation via the parent controller
                            if (vm.onFormSubmitted) {
                                vm.isSaving = true;
                                vm.onFormSubmitted({ data: reservation });
                            }
                        }
                    });
            } else {
                // save reservation via the parent controller
                if (vm.onFormSubmitted) {
                    vm.isSaving = true;
                    vm.onFormSubmitted({ data: reservation });
                }
            }
        }
        return vm;
    }
}