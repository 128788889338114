﻿module.exports = ['$q', 'constantsService', 'identity', preloaderCtrl];

function preloaderCtrl($q, constantsService, identityService) {
    var deferred = $q.defer();
    var vm = {
        done: deferred.promise,
        identity: identityService,
        isDone: false,
        steps: 3,
        version: null
    };

    init();
    function init(){
        constantsService.loadSystemInfo().then(function(result){
            if(result.version)
                vm.version ='('+result.version+')';
        });
        constantsService.loadSystemMessages();
    }

    //constantsService.preloaded
    //   .then(function () { preLoadStepDone('constants'); }, function () { preLoadStepDone('constants-error'); });
    identityService.preloaded
        .then(function () {
            preLoadStepDone('identity');
            getConstants();
            getUserInfo();
        }, function () { preLoadStepDone('identity-error'); });

    function getConstants() {
        constantsService.preloaded.then(function () { preLoadStepDone('constants'); }, function () { preLoadStepDone('constants-error'); });

        if (identityService.isAuthenticated)
            constantsService.init();
        else
            preLoadStepDone('constants');
    }

    function getUserInfo() {
        if (identityService.isAuthenticated)
            identityService.reloadUserInfo().then(function () { preLoadStepDone('userinfo'); }, function () { preLoadStepDone('userinfo-error'); });
        else
            preLoadStepDone('userinfo');
    }

    function preLoadStepDone(source) {
        vm.steps--;
        if (vm.steps === 0) {
            vm.isDone = true;
            deferred.resolve(true);
        }
    };

    return vm;
};