﻿'use strict';

module.exports = ['$location', '$rootScope', '$scope', '$translate', 'constantsService', 'toastr', 'userAccountService', 'widgetModals', loginController];

function loginController($location, $rootScope, $scope, $translate, constantsService, toastr, userAccountService, widgetModals) {
    var vm = this;

    vm.display = 'login';
    vm.loginModel = {
        password: null,
        rememberMe: false,
        username: null
    };
    vm.readPS = function () {
        userAccountService.readPrivacy().then(function (res) {
            widgetModals.privacy(res)
                .result
                .then(function (res) {
                  
                }).catch(function (err) {
                  
                });
        });
    }
    vm.passwordforgotmodel = {
        useremail: null
    }
    vm.loginStatus = {
        error: null,
        loading: false
    };
    vm.registerModel = {
        companyName: null,
        businessUnit: null,
        businessUnits: [],
        confirmPassword: null,
        email: null,
        password: null,
        phoneNumber: null,
        invoiceDetails: {
            address: null,
            postalCode: null,
            city: null,
            country: null,
            cocNumber: null,
            vatNumber: null,
            iban: null,
            invoiceEmail: null

        }

    };

    vm.registerStatus = {
        error: null,
        loading: false
    };

    vm.translations = [];
    vm.getBusinessUnits = function () {
        userAccountService.getBusinessUnits()
            .then(function (result) {
                vm.registerModel.businessUnits = result;
            });
    };
    vm.constants = constantsService;
    
    function load() {
        vm.getBusinessUnits();
        $translate([
            'views.account.login.errors.missingcompany',
            'views.account.login.errors.missingemail',
            'views.account.login.errors.missingpassword',
            'views.account.login.errors.missingconfirmpassword',
            'views.account.login.errors.mismatchedpassword',
            'views.account.login.errors.missingaddress',
            'views.account.login.errors.missingpostcode',
            'views.account.login.errors.missingcity',
            'views.account.login.errors.missingcountry',
            'views.account.login.errors.missingkvk',
            'views.account.login.errors.missingbtw',
            'views.account.login.errors.missingiban',
            'views.account.login.errors.missingphone',
            'views.account.login.errors.missingbusinessunit'
        ]).then(function (translations) { vm.translations = translations; });
    }

    vm.requestPasswordReset = function () {
        userAccountService.requestPasswordChange(vm.passwordforgotmodel.useremail).then(reqSuccess, reqError);

        function reqSuccess(data, status, headers, config) {
            toastr.clear();
            toastr.success('Check your email for a password reset instructions');
            vm.passwordforgotmodel.useremail = null;
            vm.display = 'login';

        }
        function reqError(error, status) {
            toastr.clear();
            toastr.error(error.data);
        }
    }

    vm.login = function (redirect) {
        vm.loginStatus.error = null;
        vm.loginStatus.loading = true;

        if (vm.loginModel.username === null || vm.loginModel.password === null) {
            vm.loginStatus.loading = false;
            return;
        }

        var req = userAccountService.loginUser(vm.loginModel, vm.loginModel.rememberMe)
            .then(loginSuccess, loginError);

        function loginSuccess(data, status, headers, config) {
            if (data.error && data.error === 'Account inactive') {
                vm.loginStatus.error = {
                    data: {
                        error_description: data.error
                    }
                };
                vm.loginStatus.loading = false;

                return;
            }
            $rootScope.$on('identity:set', function () {
                constantsService.init();

                if (redirect !== undefined && redirect !== null)
                    $location.path(redirect);
                else
                    $location.path('/');
            });
        }

        function loginError(error, status) {
            vm.loginStatus.loading = false;
            vm.loginStatus.error = error;
        }
    };

    vm.register = function () {

        vm.registerStatus.error = [];

        if (vm.registerModel.companyName === null || vm.registerModel.companyName === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingcompany']);

        if (vm.registerModel.businessUnit === null || vm.registerModel.businessUnit === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingbusinessunit']);

        if (vm.registerModel.phoneNumber === null || vm.registerModel.phoneNumber === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingphone']);

        if (vm.registerModel.email === null || vm.registerModel.email === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingemail']);

        if (vm.registerModel.invoiceDetails.address === null || vm.registerModel.invoiceDetails.address === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingaddress']);

        if (vm.registerModel.invoiceDetails.postalCode === null || vm.registerModel.invoiceDetails.postalCode === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingpostcode']);

        if (vm.registerModel.invoiceDetails.city === null || vm.registerModel.invoiceDetails.city === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingcity']);

        if (vm.registerModel.invoiceDetails.country === null || vm.registerModel.invoiceDetails.country === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingcountry']);     

        if (vm.registerModel.invoiceDetails.cocNumber === null || vm.registerModel.invoiceDetails.cocNumber === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingkvk']);

        if (vm.registerModel.invoiceDetails.vatNumber === null || vm.registerModel.invoiceDetails.vatNumber === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingbtw']);

        if (vm.registerModel.password === null || vm.registerModel.password === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingpassword']);

        else if (vm.registerModel.confirmPassword === null || vm.registerModel.confirmPassword === '')
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.missingconfirmpassword']);

        else if (vm.registerModel.password !== vm.registerModel.confirmPassword)
            vm.registerStatus.error.push(vm.translations['views.account.login.errors.mismatchedpassword']);

        if (vm.registerStatus.error.length === 0)
            vm.registerStatus.error = null;
        else
            return;

        vm.registerModel.invoiceDetails.invoiceEmail = vm.registerModel.email;
        vm.registerModel.invoiceDetails.companyName = vm.registerModel.companyName;

        vm.registerStatus.loading = true;
        var req = userAccountService.registerUser(vm.registerModel);

        req.then(registerSuccess, registerError);

        function registerSuccess(data, status, headers, config) {
            vm.registerStatus.loading = false;

            if (data.succeeded === false) {
                vm.registerStatus.error = data.errors;
            } else {
                // Now log in
                toastr.success('Your account has been registered successfully. You will be notified when your account is active. ');
                vm.display = 'login';
            }
        }

        function registerError(error, status) {
            vm.registerStatus.loading = false;
            var errors = error;
            if (error.data && error.data.errors)
                errors = error.data.errors;

            vm.registerStatus.error = errors;
        }
    };

    load();
}
