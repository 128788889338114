﻿module.exports = ['common', 'dataContext', calendarDataService];

function calendarDataService(common, context) {
    var service = {
        getNautical: getNautical
    };

    function getNautical(pageIndex, pageSize, query) {
        var deferred = common.$q.defer();
        var url = "/calendar/nautical-provider";
        if (!pageSize)
            pageSize = 50;

        url = url + '?pindex=' + pageIndex + '&psize=' + pageSize;
        if (query)
            url += '&q=' + query;

        var req = context.get(url)
            .then(function (data) {
                var result = data;
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    return service;
}