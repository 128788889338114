﻿module.exports = {
    'ok': 'OK',
    'login': 'Inloggen',
    'logout': 'Uitloggen',
    'myprofile': 'Mijn profiel',
    'password': 'Wachtwoord',
    'email': 'E-mailadres',
    'phone':'Telefoonnummer',
    'companyName': 'Bedrijfsnaam',
    'displayName':'Weergavenaam',
    'confirmPassword': 'Wachtwoord bevestigen',
    'planning': 'Planning:',
    'registerAccount': 'Account aanmelden',
    'register': 'Registreren',
    'change': 'Wijzigen',
    'save': 'Opslaan',
    'saveChanges': 'Wijzigingen Opslaan',
    'edit': 'Bewerken',
    'all': 'Alles',
    'sent': 'Verstuurd',
    'notSent': 'Niet Verzonden',
    'arrivalDate': 'Aankomstdatum',
    'depDate': 'Vertrekdatum',
    'details': 'Details',
    'searchfail': 'Er zijn geen resultaten gevonden!',
    'search': 'Mijn zoekresultaten',
    'searchbox': 'Zoek ...',
    'role': 'Role',
    'reservations': 'Reserveringen',
    'fleet': 'Vloot',
    'users': 'Gebruikers',
    'sure': 'Weet je het zeker?',
    'cancel': 'Annuleren',
    'messages': 'Berichten',
    'language': 'NL',
    'cancelled': 'Geannuleerd',
    'cancelledByAuthority': 'Geannuleerd door haven autoriteit',
    'endsOn': 'Eindigt op',
    'startsOn': 'Begint op',
    'rejected': 'Afgewezen',
    'value': 'Waarde'
};