﻿module.exports = [reservationOverviewState];

function reservationOverviewState() {

    var service = {
        filter: {
            showPastReservations: false,
            sortAscending: true,
            showCancelledReservations: true,
            sortField: 'eta',
            selectedYear: null,
            yearOptions:[],
            selectedFilter: null,
            onSelectYear:onSelectYear,
            onSelectFilter:onSelectFilter,
            initYears:initYears
        }
    };

    function initYearOptions(){
        var currentYear = new Date().getFullYear();
        service.filter.selectedYear = currentYear;
    }
    function initFilterOptions(){
        service.filter.selectedFilter = 'upcoming';
        onSelectFilter();
    }

    function onSelectYear(){
        var currentYear = new Date().getFullYear();
        var diff = service.filter.selectedYear - currentYear;
        service.filter.selectedFilter = (diff< 0)?'all':'upcoming';
        onSelectFilter();
    }

    function initYears(list){
        service.filter.yearOptions = _.chain(list).map(function(item){ return new Date(item.eta).getFullYear()}).uniq().value();
    }

    function onSelectFilter() {
        switch(service.filter.selectedFilter.toLowerCase()){
            case 'upcoming':
                service.filter.showPastReservations = false;
                service.filter.showCancelledReservations = false;
                break;
            case 'all': 
                service.filter.showPastReservations = true;
                service.filter.showCancelledReservations = false;
                break;
            case 'cancelled': 
                service.filter.showCancelledReservations = true; 
                break;
        }
    }

    initYearOptions();
    initFilterOptions();

    return service;

}