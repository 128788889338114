﻿module.exports = ['common', 'reservationDataService', 'toastr', reservationReceiptPriceDirective];

function reservationReceiptPriceDirective(common, reservationData, toastr) {

    var directive = {
        bindToController: true,
        controller: reservationReceiptPriceController,
        controllerAs: 'ctrlReservationReceiptPrice',
        link: function (scope) {
            scope.$watch('ctrlReservationReceiptPrice.reservationData', scope.ctrlReservationReceiptPrice.valueChanged);

            //scope.ctrlReservationReceiptPrice.reservation = scope.reservationData;
        },
        restrict: 'E',
        scope: {
            reservationData: '='
        },
        templateUrl: 'templates/reservations/directives/reservation-receipt-price.html'
    };

    return directive;

    function reservationReceiptPriceController() {
        function valueChanged(newValue, origValue, scope) {

            if (!newValue || origValue === newValue)
                return;

            calculatedTotal();
        }

        var vm = {
            valueChanged: valueChanged,
            calculatedTotal: calculatedTotal,
            invoice: null,
            invoiceRemarks: null,
            loading: false,
            reservationData: null,
            totalPrice: totalPrice
        };

        function calculatedTotal() {
            vm.invoiceRemarks = null;
            vm.loading = true;

            var res = {
                eta: vm.reservationData.eta,
                etd: vm.reservationData.etd,
                primaryPreferredBerthGroupId:vm.reservationData.berthGroup!= null? vm.reservationData.berthGroup.id:null,
                primaryPreferredBerthId:vm.reservationData.berth!=null? vm.reservationData.berth.id:null,
                ship: vm.reservationData.newVessel ? vm.reservationData.newVessel : vm.reservationData.selectedVessel,
                reservationItems: vm.reservationData.activities,
                processFlowId: vm.reservationData.processFlowId
            };

            reservationData.getCalculatedPrice(res)
                .then(function (result) {
                    if (result) {
                        vm.invoice = result.invoice;
                        vm.invoiceRemarks = result.remarks;
                    }
                    vm.loading = false;
                }, function (error) {
                    toastr.clear();

                    toastr.error(error);
                    vm.loading = false;
                });
        }

        function totalPrice() {
            if (vm.invoice && vm.invoice.invoiceItems) {
                return _.reduce(vm.invoice.invoiceItems, function (memo, item) { return memo + item.totalAmount; }, 0);
            }
        }

        return vm;
    }

};