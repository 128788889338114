﻿module.exports = ['$translate', userModels];

function userModels($translate) {

    var service = {
        userModel: userModel,
        userRegistrationModel: userRegistrationModel,
        validateUser: validateUser,
        validateUserRegistration: validateUserRegistration
    };
    load();
    return service

    function load() {

    }

    function userModel(userData) {
        var userModel = {
            customerId: null,
            id: null,
            emailAddress: null,
            phoneNumber: null,
            portAuthorityId: null,
            roleId: null,
            name: null
        };

        if (userData === undefined || userData === null)
            return userModel;

        userModel.customerId = userData.customerId;
        userModel.id = userData.id;
        userModel.emailAddress = userData.emailAddress;
        userModel.phoneNumber = userData.phoneNumber;
        userModel.portAuthorityId = userData.portAuthorityId;
        userModel.name = userData.name;

        if (userData.roles !== undefined && userData.roles !== null && userData.roles.length > 0) {
            // JV: Temporary fix until roles are correctly implemented in the coming release
            if (userData.roles[0].businessUnit !== undefined && userData.roles[0].businessUnit !== null) {
                if (userData.roles[0].roles && userData.roles[0].roles.length > 0)
                    userModel.roleId = userData.roles[0].roles[0].id;
            }
            else
                userModel.roleId = userData.roles[0].id;
        }
        userModel.roles = userData.roles;

        return userModel;
    }

    function userRegistrationModel() {
        return {
            companyName: null,
            email: null,
            name: null,
            password: null,
            confirmPassword: null,
            roles: [{ businessUnit: 0, roles: [] }]
        };
    }

    function validateUser(user) {
        var errors = [];

        if (user.name === null || user.name === '')
            errors.push('views.users.usermodels.errors.displayname');

        if (user.emailAddress === null || user.emailAddress === '')
            errors.push('views.users.usermodels.errors.missingemail');

        return errors.length > 0 ? errors : true;
    }

    function validateUserRegistration(userRegistration) {
        var errors = [];

        if (userRegistration.name === null || userRegistration.name === '')
            errors.push('views.users.usermodels.errors.displayname');

        if (userRegistration.emailAddress === null || userRegistration.emailAddress === '')
            errors.push('views.users.usermodels.errors.missingemail');

        if (userRegistration.password === null || userRegistration.password === '')
            errors.push('views.users.usermodels.errors.missingpassword');
        else if (userRegistration.confirmPassword === null || userRegistration.confirmPassword === '')
            errors.push('views.users.usermodels.errors.missingconfirmpassword');
        else if (userRegistration.password !== userRegistration.confirmPassword)
            errors.push('views.users.usermodels.errors.mismatchedpassword');

        return errors.length > 0 ? errors : true;
    }

};
