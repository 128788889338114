﻿module.exports = {
    'errors': {
        'vesselgreenaward': 'Vul a.u.b. een Green award in',
        'vesselselect': 'Kies een schip',
        'vesselname': 'Vul a.u.b. een naam in',
        'vessellength': 'Vul a.u.b. een lengte in',
        'vesselwidth': 'Vul a.u.b. een breedte in',
        'vesseldraught': 'Vul a.u.b. een diepgang in',
        'veselnamelength': 'Schip naam mag niet meer dan 50 tekens bevatten',
        'vesseltype': 'Vul a.u.b. een type in',
        'lengthformat': 'Schip lengte moet een nummer zijn',
        'widthformat': 'Schip breedte moet een nummer zijn',
        'maxdraughtformat': 'Schip diepgang moet een nummer zijn',
        'eninumberformat': 'ENI nummer moet een nummer zijn',
        'eninumberlength': 'ENI nummer moet 8 cijfers bevatten',
        'imonumberformat': 'IMO nummer moet een nummer zijn',
        'imonumberlength': 'IMO nummer moet 7 cijfers bevatten',
        'paxCapacity': 'Please specify amount of people',
        'vesselunnumber': 'Vul a.u.b. een UN nummer.'
    }
};