﻿module.exports = ['common', '$routeParams', 'fleetDataService', 'reservationDataService', 'reservationPlanningService', 'businessruleDataService', 'toastr', reservationNewDolphinController];

function reservationNewDolphinController(common, $routeParams, fleetData, reservationData, reservationPlanning, businessRuleData, toastr) {

    var vm = {
        attached: attached,
        filter: {
            berth: null,
            berthGroupId: null,
            commercialGoalId: null,
            eta: null,
            etd: null,
            loa: null,
            nauticalGoalId: null,
            isNew: true,
            flowId: null
        },
        filterChanged: filterChanged,
        newReservationData: {
            masterReservation: null,
            masterShip: null,
            slaveReservation: null,
            slaveShip: null
        },
        initialReservation: null,
        reservations: [],
        filteredBerths: [],
        previewsFilter: [],
        save: save,
        setSelectionBerth: setSelectionBerth,
        status: {
            isBlurred: false,
            showAll: false,
            isLoading: false,
            isSaving: false
        },
        showAllBerths: showAllBerths
    };

    function attached() {
        vm.filter.isNew = true;

        if ($routeParams.flowId) {
            vm.filter.flowId = $routeParams.flowId;
        }

        if ($routeParams.reservationid) {
            vm.status.isLoading = true;
            reservationData.getReservation($routeParams.reservationid)
                .then(function (reservationResult) {
                    vm.initialReservation = reservationResult;
                    if (vm.initialReservation) {
                        vm.filter.isNew = false;
                        vm.filter.eta = vm.initialReservation.eta;
                        vm.filter.etd = vm.initialReservation.etd;
                        if (vm.initialReservation.reservationItems && vm.initialReservation.reservationItems.length > 0 && vm.initialReservation.reservationItems[0].berth) {
                            vm.filter.berth = vm.initialReservation.reservationItems[0].berth;
                        } else {
                            vm.filter.berth = { id: vm.initialReservation.primaryPreferredBerthId };
                        }
                    }

                    vm.status.isLoading = false;
                },
                () => {
                    vm.status.isLoading = false;
                });
        }        
    }

    function filterChanged(filter) {
        _.extend(vm.filter, filter);
        loadReservations(vm.filter);
        loadFilteredBerths(vm.filter);
        loadBerthObstructions(vm.filter);
    }

    function loadFilteredBerths(filter) {
        var flowIds = vm.filter.flowId ? [vm.filter.flowId] : null;
        reservationData.getFilteredBerthGroups(1, flowIds, filter.loa, filter.berthGroupId,
            filter.nauticalGoalId, filter.commercialGoalId, filter.eta, filter.etd)
            .then(function (res) {
                vm.filteredBerths = res.berthAvailability;
            });
    }

    function loadReservations(filter) {
        //filter.berthGroupId = null;
        //filter.nauticalGoalId = null;
        reservationData.getAvailability(filter.eta, filter.etd, 1, null, null)
            .then(function (result) {
                vm.status.isBlurred = true;

                reservationPlanning.calculateDays({
                    eta: vm.filter.eta,
                    etd: vm.filter.etd,
                    planningItems: result
                }, 1);

                var mappedItems = _.map(result, function (item) {
                    item.customer = { name: item.customerName };
                    item.ship = { id: item.shipId, name: item.shipName };
                    return item;
                });

                reservationPlanning.calculateItems(_.filter(result, function (r) { return r.isConfirmed; }), 'planned');
                reservationPlanning.calculateItems(_.filter(result, function (r) { return !r.isConfirmed; }), 'unplanned');
                for (var i = 0; i < reservationPlanning.data.berthObstructions.length; i++) {
                    var startTF = 'availableFrom';
                    var endTF = 'availableUntil';
                        if (reservationPlanning.data.berthObstructions[i]['blockStart']) {
                            startTF = 'blockStart';
                            endTF = 'availableFrom';
                        }
                        if (reservationPlanning.data.berthObstructions[i]['blockEnd']) {
                            startTF = 'availableUntil';
                            endTF = 'blockEnd'
                        }
                    result = reservationPlanning.calculateCalendarBlock(reservationPlanning.data.berthObstructions[i],startTF,endTF);
                }
            });

    }

    function loadBerthObstructions(filter) {
        var startDate = (moment.isMoment(filter.eta) ? filter.eta : moment(filter.eta));
        var endDate = (moment.isMoment(filter.etd) ? filter.etd : moment(filter.etd));

        businessRuleData.getByType(3, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                reservationPlanning.calculateBerths(vm.filteredBerths, result);
            });
    }

    function setSelectionBerth(filter) {
        _.extend(vm.filter, filter);
    }

    function showAllBerths() {
        vm.status.showAll = false;
        vm.previewsFilter = JSON.parse(JSON.stringify(vm.filteredBerths));

        let filter = {
            berthGroupId: null,
            commercialGoalId: null,
            eta: null, //moment(),
            etd: null, //moment().add(7, 'days').toDate(),
            loa: null,
            nauticalGoalId: null
        };

        if (vm.filter.eta) {
            filter.eta = vm.filter.eta;

            if (vm.filter.etd) {
                filter.etd = vm.filter.etd;
            } else {
                filter.etd = moment(vm.filter.eta).add(7, 'days').toDate();
            }
        }

        loadReservations(filter);
        loadFilteredBerths(filter);
    }
    function save(data) {

        _.extend(vm.newReservationData, data);
        // if Inland-Seagoing STS, then switch to Seagoing-Inland STS
        if (vm.newReservationData.masterShip.shipTypeId === 5 && vm.newReservationData.slaveReservation !== null && vm.newReservationData.slaveShip !== null && vm.newReservationData.slaveShip.shipTypeId === 4) {
            vm.newReservationData = {
                masterReservation: vm.newReservationData.slaveReservation,
                masterShip: vm.newReservationData.slaveShip,
                slaveReservation: vm.newReservationData.masterReservation,
                slaveShip: vm.newReservationData.masterShip
            };
        }

        var deferredMaster = common.$q.defer();
        deferredMaster.promise.then(function(masterReservation) {

                if (vm.newReservationData.slaveShip !== null && vm.newReservationData.slaveReservation !== null) {
                    vm.newReservationData.slaveReservation.masterReservationId = masterReservation.id;
                    
                    var deferredSlave = common.$q.defer();
                    deferredSlave.promise.then(function() {
                        reservationData.lastSaved = masterReservation;
                        onSaveSucceeded();
                    }, onSaveFailed);

                    saveReservationWithShip(deferredSlave,
                        vm.newReservationData.slaveShip,
                        vm.newReservationData.slaveReservation);
                } else {
                    onSaveSucceeded();
                }
            },
            onSaveFailed);

        if (vm.initialReservation) {
            vm.newReservationData.masterShip.id = vm.initialReservation.shipId;
            vm.newReservationData.masterShip.customerId = vm.initialReservation.customerId;
            for (var i = 0; i < vm.initialReservation.reservationItems.length; i++) {
                var originalItemId = vm.initialReservation.reservationItems[i].id;
                var newItem = vm.newReservationData.masterReservation.reservationItems[i];
                newItem.id = originalItemId;
                newItem.reservationId = vm.initialReservation.id;
                vm.initialReservation.reservationItems[i] = newItem;
            }

            fleetData.saveShip(vm.newReservationData.masterShip)
                .then(function (vessel) {

                    reservationData.addOrUpdateReservation(vm.initialReservation)
                        .then(function (data) {
                            deferredMaster.resolve(data);
                        }, function (error) {
                            deferredMaster.reject(error);
                        });
                }, function (error) {
                    deferredMaster.reject(error);
                });
        } else {
            saveReservationWithShip(deferredMaster, vm.newReservationData.masterShip, vm.newReservationData.masterReservation);
        }
    }

    function saveReservationWithShip(deferred, newShip, newReservation) {
        fleetData.addShip(newShip)
            .then(function (vessel) {

                newReservation.shipId = vessel.id;
                reservationData.addOrUpdateReservation(newReservation)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (error) {
                        deferred.reject(error);
                    });
            }, function (error) {
                deferred.reject(error);
            });
    }

    function onSaveFailed(error) {
        toastr.error('Error adding new reservation.');
        vm.status.isSaving = false;
    }

    function onSaveSucceeded() {
        vm.status.isSaving = false;
        toastr.success('Your reservation has been saved successfully.');
        location.href = '/#!/reservations'; // return to the overview page with reload
    }

    return vm;
}