﻿module.exports = {
    'title': 'Aanmelden',
    'register': 'Account aanmaken',
    'username': 'Gebruikersnaam',
    'password': 'Wachtwoord',
    'remember': 'Onthouden',
    'errorLoggingIn': 'Er is een fout opgetreden tijdens het inloggen:',
    'invalidCombination': 'De gebruikersnaam / wachtwoord-combinatie is ongeldig',
    'falsestatus': 'Uw account is niet actief, neem contact op met uw beheerder',
    'processingRegistration': 'Verwerking Registratie',
    'errorRegistering': 'Er is een fout bij het registreren van uw account:',
    'loginProcessing': 'Verwerking Inloggen',
    'loginParagraph': 'Schrijf iets',
    'errors': {
        'missingbusinessunit': 'Vul a.u.b. een bedrijfstype',
        'missingcompany': 'Vul a.u.b. een bedrijfsnaam',
        'missingemail': 'Vul a.u.b. een e-mailaddress',
        'missingpassword': 'Vul a.u.b. een wachtwoord',
        'missingconfirmpassword': 'Bevestig je wachtwoord',
        'mismatchedpassword': 'De wachtwoorden komen niet overeen',
    },
    'passwordforgot': 'Forgot Password?',
    'passwordforgotTitle': 'Forgot your password?',
    'passwordforgotBody' : "Enter your email. We'll email instructions on how to reset you password.",
    'requestpasswordreset': 'Request a new password',
    'email': 'email'
};