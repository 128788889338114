﻿module.exports = ['$parse', formValidationDirective];

function formValidationDirective($parse) {
    return {
        restrict: 'A',
        require: ['submitAfterValidation', '?form'],
        controller: ['$scope', function ($scope) {
            var formController = null;

            this.setFormController = function (controller) {
                formController = controller;
            };

            this.needsAttention = function (fieldModelController) {
                if (!formController) return false;

                if (fieldModelController) {
                    return fieldModelController.$invalid &&
                           (fieldModelController.$dirty || this.attempted);
                } else {
                    return formController && formController.$invalid &&
                          (formController.$dirty || this.attempted);
                }
            };

            this.attempted = false;

            this.setAttempted = function () {
                this.attempted = true;
            };
        }],
        compile: function (cElement, cAttributes, transclude) {
            return {
                pre: function (scope, formElement, attributes, controllers) {
                    var submitController = controllers[0];

                    var formController = (controllers.length > 1) ?
                                             controllers[1] : null;
                    submitController.setFormController(formController);

                    scope.formValidation = scope.formValidation || {};
                    scope.formValidation[attributes.name] = submitController;
                },
                post: function (scope, formElement, attributes, controllers) {
                    var submitController = controllers[0];
                    var formController = (controllers.length > 1) ?
                                         controllers[1] : null;

                    var fn = $parse(attributes.submitAfterValidation);

                    formElement.bind('submit', function (event) {
                        submitController.setAttempted();
                        if (!scope.$$phase) scope.$apply();

                        if (!formController.$valid) return false;

                        scope.$apply(function () {
                            fn(scope, { $event: event });
                        });
                    });
                }
            };
        }
    };
}
