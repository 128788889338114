﻿'use strict';

module.exports = ['common', '$location', '$routeParams', 'toastr', 'userAccountService', resetPasswordControl];

function resetPasswordControl(common, $location, $routeParams, toastr, userAccountService) {
    var vm = this;

    vm.savePassword = savePassword;
    vm.newPassword = null;
    vm.confirmPassword = null;
    vm.errors = [];

    function savePassword() {
        vm.errors = [];
        if (vm.newPassword !== vm.confirmPassword) {
            vm.errors.push('Password does not match the confirm password.');
            return;
        }

        var resetpasmodel = { newPassword: vm.newPassword, token: $routeParams.token, userId: $routeParams.userId }
        userAccountService.resetPassword(resetpasmodel).then(reqSuccess, reqError);

        function reqSuccess(data, status, headers, config) {
            toastr.clear();
            toastr.success('You password has been successfully reset!');
            $location.path('/');
            vm.newPassword = null;
            vm.confirmPassword = null;

        }
        function reqError(error, status) {
            vm.errors = error.data.errors;
        }
    }
}