﻿'use strict';

module.exports = ['common', 'identity', 'userAccountService', 'toastr', '$translate', 'financialInfoService', profileController];

function profileController(common, identity, userAccountService, toastr, $translate, financialInfoService) {
    var vm = {
        saveCustomerInfo: saveCustomerInfo,
        saveProfile: saveProfile,
        userAccount: identity.userInfo,
        changePasswordData: { oldPassword: null, newPassword: null, confirmPassword: null },
        savePassword: savePassword,
        getCustomerInfo: getCustomerInfo
    };

    function load() {
        $translate([
            'views.account.profile.update.failed',
            'views.account.profile.update.sucess',
            'views.account.profile.update.emptycompanyname',
            'views.account.profile.update.emptydisplayname'
        ]).then(function (translations) { vm.translations = translations; });
    }
    function init() {
        load();

        userAccountService.getUserInfo()
            .then(function (result) {
                vm.userAccount = result;
                vm.getCustomerInfo();
            });
    }
    function getCustomerInfo() {
        userAccountService.getCustomerInfo()
            .then(function (response) {
            }, function (error) {
            });
    }
    function saveCustomerInfo() {
        if (!common.identity.hasPermission('user_admin'))
            return;

        if (!validateEmail(vm.userAccount.customer.emailAddress)) {
            toastr.clear();
            toastr.error('Invalid email address.');
            return;
        }

        if (vm.userAccount.customer.name === '') {
            toastr.clear();
            toastr.error(vm.translations['views.account.profile.update.emptycompanyname']);
            return;
        }

        userAccountService.saveCustomerInfo(vm.userAccount.customer)
            .then(function (response) {
                successCallback(response);
            }, function (response, status, headers, config) {
                errorCallback(response.data, status);
            });
    }

    function saveProfile() {
        if (!validateEmail(vm.userAccount.emailAddress)) {
            toastr.clear();
            toastr.error('Invalid email address.');
            return;
        }

        if (vm.userAccount.name === '') {
            toastr.clear();
            toastr.error(vm.translations['views.account.profile.update.emptydisplayname']);
            return;
        }

        userAccountService.saveUserInfo(vm.userAccount)
            .then(function (response) {
                successCallback(response);
            }, function (response, status, headers, config) {
                errorCallback(response.data, status);
            });
    }


    function errorCallback(data, status) {
        var errorsTxt = _.reduce(data.errors, function (result, item)
        {
            if (result === '' || result === null || result === undefined)
                result = item;
            else
                result = result + ',' + item;
            return result
        });

        toastr.clear();
        toastr.error(vm.translations['views.account.profile.update.failed'] + ' ' + errorsTxt);
    }

    function successCallback(data) {
        toastr.clear();
        toastr.success(vm.translations['views.account.profile.update.sucess']);
    }

    function savePassword() {
        if (vm.changePasswordData.newPassword === undefined) {
            toastr.clear();
            toastr.error('Please provide new password.');
            return;
        }

        if (vm.changePasswordData.confirmPassword === undefined) {
            toastr.clear();
            toastr.error('Please verify new password.');
            return;
        }

        if (vm.changePasswordData.newPassword !== vm.changePasswordData.confirmPassword) {
            toastr.clear();
            toastr.error('Password does not match the confirm password.');
            return;
        }

        userAccountService.changePassword(vm.changePasswordData)
            .then (function (response) {
                successCallback(response);
            }, function (response, status, headers, config) {
                errorCallback(response.data, status);
            });
    }

    function validateEmail(email) {
        var re = /.+@.+/;

        return re.test(email);
    }

    init();

    return vm;

}
