﻿module.exports = ['reservationWizardGenericService', 'constantsService', genericReservationSelectTime];

function genericReservationSelectTime(reservationWizard, constantsService) {
    var directive = {
        bindToController: true,
        controller: genericReservationSelectTimeController,
        controllerAs: 'ctrlGenericReservationSelectTime',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-select-time.html'
    };

    return directive;

    function genericReservationSelectTimeController() {

        var vm = {
            init:init,
            etaChanged:etaChanged,
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yyyy',
                    'd-M-yyyy H:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yyyy',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yyyy HH:mm'
                ],
                minDate: null
            },
            wizard:reservationWizard,
            isEtaRequired:isEtaRequired,
            isEtdRequired:isEtdRequired
        };
    
        function init() {
        }

        
        function etaChanged(newEta) {
            vm.warnings = [];

            if (newEta === undefined)
                return;

            var shipType = reservationWizard.getVesselType();
            var config = constantsService.shipTypeConfigs[shipType];

            if (shipType && config && config.disableBeforeAtd === true) {
                var atdMin = config.maxAtdMin && config.maxAtdMin > 0 ? config.maxAtdMin : 480; // default 8 hours
                let backAtdMins = moment().subtract(atdMin, 'minutes').toDate();

                if (moment(reservationWizard.reservation.eta).isBefore(backAtdMins)) {
                    reservationWizard.validation.step1.errors.push("The arrival date for can't be more then " + atdMin + " mins in past, please check the arrival and departure dates");
                    
                }
            }

            if (config && config.maxAtdMin) {
                if (config.maxAtdMin > 0) {
                    reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(config.maxAtdMin, 'minutes').toDate();
                }
            } else if (reservationWizard.reservation.eta >= reservationWizard.reservation.etd) {
                reservationWizard.reservation.etd = moment(reservationWizard.reservation.eta).add(8, 'hours').toDate();
            }
        }

        function isEtaRequired(){
            return reservationWizard.isRequired('eta');
        }

        function isEtdRequired(){
            return reservationWizard.isRequired('etd');
        }

        return vm;
    }
}