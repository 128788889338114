﻿module.exports = ['common', 'dataContext', searchDataService];

function searchDataService(common, context) {

    var service = {
        find: find,
        searchResuls:[]
    };

    return service;

    function find(searchText) {
        var dfr = common.$q.defer();

        context
            .get('/search?q=' + searchText)
            .then(function (result) {
                dfr.resolve(result);
            }, function (error) {
                dfr.reject(error);
            });

        return dfr.promise;
    }
}