﻿module.exports = ['$locale', '$translate', 'tmhDynamicLocale', languageService];

function languageService($locale, $translate, dynamicLocale) {
    var service = {
        $locale: $locale,
        $translate: $translate,
        get: getLanguage,
        set: setLanguage,
        supportedLanguages: ['de', 'en', 'fr', 'nl']
    };

    function getLanguage() {
        var language = dynamicLocale.get();
        if (!language)
            dynamicLocale.set('en');
        return dynamicLocale.get();
    };

    function setLanguage(language) {
        if (!language)
            return;
        if (service.supportedLanguages.indexOf(language) > -1) {
            dynamicLocale.set(language);
            $translate.use(language);
        }
    };

    return service;
};