﻿module.exports = ['$location', 'reservationWizardService', newReservationController];

function newReservationController($location, reservationWizard) {

    var vm = {
        wizard: reservationWizard
    };

    init();
    function init() {
        vm.wizard.clear();
    }

    return vm;
};