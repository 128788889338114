﻿angular
    .module('poa.rprp.widgets', [])
    // Controllers
    .controller('cancelReservationOfShipController', require('./modals/cancel-reservation-of-ship'))
    .controller('extendReservationController', require('./modals/extend-reservation'))
    .controller('reviewWarningsController', require('./modals/review-warnings'))
    .controller('modalOkController', require('./modals/modal-ok'))
    // Directives
    .directive('datePeriodPicker', require('./directives/date-period-picker'))
    .directive('rprpNavbar', require('./directives/navbar'))
    .directive('rprpNavbarUserProfile', require('./directives/navbar-user-profile'))

    // Modals
    .factory('widgetModals', require('./modals/modals'))
;