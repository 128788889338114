﻿module.exports = {
    'backOverview': 'Terug naar het overzicht',
    'backStepOne': 'Terug naar stap 1',
    'yourReservation': 'Uw reservering',
    'status': 'Status',
    'newReservation': 'Nieuwe reservering',
    'regionConfirmation': 'Regio bevestigd',
    'berthGroupConfirmation': 'Kade bevestigd',
    'berthConfirmation': 'Ligplaats bevestigd',
    'reservationDetails': 'Reservering detail',
    'eta': 'ETA',
    'berth': 'Ligplaats voorkeur',
    'berthAlternative': 'Ligplaats (alternatief)',
    'berthGroup': 'Ligplaats Groep',
    'facilities': 'Faciliteiten',
    'etd': 'ETD',
    'cancelReservation': 'Annuleren Reservering',
    'berthPlanning': 'Ligplaats',
    'region': 'Regio',
    'sendReservation': 'Reservering versturen',
    'changesomething': 'Wijzigen?',
    'cancelReservationconfirm': 'Let op! U staat op het punt om uw reservering te annuleren. Dit kan niet ongedaan gemaakt worden.',
    'confirmed': 'Bevestigd',
    'unconfirmed': 'Niet bevestigd',
    'noPlanning': 'Er is nog geen bevestigde planning vanuit het havenbedrijf',
    'addDates': 'Reservering dupliceren',
    'closeWindow': 'Scherm sluiten',
    'typeYourReason': 'Type your reason here...',
    'pending': 'Pending'
};