﻿module.exports = [ 'constantsService','common','reservationWizardGenericService','reservationModals', genericReservationYourReservation];

function genericReservationYourReservation(constantsService,common, reservationWizard, reservationModals) {
    var directive = {
        bindToController: true,
        controller: genericReservationYourReservationController,
        controllerAs: 'ctrlGenericReservationYourReservation',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-your-reservation.html'
    };

    return directive;

    function genericReservationYourReservationController() {

        var vm = {
            wizard:reservationWizard,
            deleteActivity:deleteActivity,
            next:next
        };

        function deleteActivity(activity) {
            var index = _.findIndex(vm.wizard.reservation.activities, activity);
            if (index >= 0) {
                vm.wizard.reservation.activities.splice(index, 1);
                vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            }
        }

        function next() {
            vm.sending = true;

            vm.errors = [];
            reservationWizard.validation.step2.validate()
                .then(function (validationResult) {
                    if (validationResult.reservations && validationResult.reservations.length > 0) {
                        reservationModals.overlappingReservation(validationResult.reservations)
                            .result
                            .then(function (targetReservation) {
                                if (targetReservation.id !== undefined && targetReservation.id !== null) {
                                    if (targetReservation.id === -1) {
                                        reservationWizard.save()
                                            .then(function () {
                                                vm.sending = false;
                                                common.$location.path('/reservations/');
                                            });
                                    } else {
                                        // Set the ETD of the existing reservation to the new ETD
                                        targetReservation.etd = reservationWizard.reservation.etd;
                                        if (targetReservation.reservationItems && targetReservation.reservationItems.length > 0) {
                                            // If the existing reservation has items
                                            if (reservationWizard.reservation.reservationItems === null || reservationWizard.reservation.reservationItems.length === 0) {
                                                // If the new reservation does not have items set the ETD of the existing last item to the new ETD
                                                targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.etd;
                                            } else {
                                                if (targetReservation.reservationItems[targetReservation.reservationItems.length - 1].berthGroupId === reservationWizard.reservation.berthGroup.id
                                                    || targetReservation.reservationItems[targetReservation.reservationItems.length - 1].berthId === reservationWizard.reservation.berth.id) {
                                                    // If the new reservation does have items, and the items match in terms of berth group
                                                    // combine those two items
                                                    targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.reservationItems[0].etd;
                                                    // Remove the first item of the new reservation
                                                    reservationWizard.reservation.reservationItems.splice(0, 1);
                                                    // Add the remaining items to the existing reservation
                                                    targetReservation.reservationItems = targetReservation.reservationItems.concat(reservationWizard.reservation.reservationItems);
                                                } else {
                                                    // If the new reservation does have items, but the items do not match in terms of berth group
                                                    // set the ETD of the existing last item to the new ETD
                                                    targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.eta;
                                                    // Add the new items to the end of the existing items
                                                    targetReservation.reservationItems = targetReservation.reservationItems.concat(reservationWizard.reservation.reservationItems);
                                                }
                                            }
                                        } else if (reservationWizard.reservation.reservationItems !== null && reservationWizard.reservation.reservationItems.length > 0) {
                                            // If the existing reservation does not have items, but the new one does, just add those to the existing reservation
                                            targetReservation.reservationItems = reservationWizard.reservation.reservationItems;
                                        }

                                        // Save the existing reservation
                                        reservationData.addOrUpdateReservation(targetReservation)
                                            .then(function () {
                                                vm.sending = false;
                                                common.$location.path('/reservations/');
                                            });
                                    }
                                }
                            }, function (error) {
                                if (error === 'cancel') {
                                    vm.errors = [];
                                } else {
                                    vm.errors = ['There was an unknown error'];
                                }
                                vm.sending = false;
                            });
                    } else if (validationResult.reservations && validationResult.reservations.length === 0) {
                        reservationWizard.save()
                            .then(function () {
                                vm.sending = false;
                                common.$location.path('/reservations/');
                            });
                    } else {
                        vm.sending = false;
                        vm.errors = reservationWizard.validation.step2.errors;
                    }
                }, function (validationError) {
                    vm.sending = false;
                    vm.errors = reservationWizard.validation.step2.errors;
                });
        }

        return vm;
    }
}