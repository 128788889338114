﻿angular.module('poa.rprp')
    .value('config', {
        apiHost: 'http://localhost:5155',
        //apiHost: '/api',
        applicationKey: 'a',
        applicationSecret: 'b',
        publicBerthInfoUrl: "https://www.portofamsterdam.com/en/shipping/seashipping/details-berth",
        objectionFormUrl: "https://klachtenportofamsterdam.topdesk.net/xfg/toeristenbelasting",
        version: '0.1'
    })
    .config(['$locationProvider', locationConfigurator])
    .config(['$routeProvider', routeConfig])
    .config(['$translateProvider', translationConfigurator])
    .config([dateTimeConfig])
    .config(toastConf)
    .run(['$location', '$rootScope', 'config', 'identity', authenticateRoutes]);


function toastConf(toastrConfig) {
    angular.extend(toastrConfig, {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-bottom-right",
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    });
};

function locationConfigurator($locationProvider) {
    //$locationProvider.html5Mode(true);
};

function routeConfig($routeProvider) {
    var defaultRoute = '/dashboard';

    $routeProvider
        .otherwise({
            redirectTo: defaultRoute
        });
};

function translationConfigurator($translateProvider) {
    var translation_en = require('./translations/en/_index');
    var translation_nl = require('./translations/nl/_index');

    $translateProvider
        .translations('en', translation_en)
        //.translations('nl', translation_nl)
        .preferredLanguage('en')
        .fallbackLanguage('en');
};

function authenticateRoutes($location, $rootScope, config, identity) {
    $rootScope.$on("$locationChangeStart", function (event, next, current) {
        if (identity.isAuthenticated)
            return;

        var unauthenticatedRoutes = [
            '/account/login', '/account/reset-password'
        ];

        var locationToCheck = $location.path();

        if (unauthenticatedRoutes.indexOf(locationToCheck) > -1)
            return;

        $location.path('/account/login');
        event.preventDefault();
    });
};

function dateTimeConfig() {
    // Override toJSON(). Stop JSON.stringify from converting datetime to UTC.
    Date.prototype.toJSON = function () { return moment(this).format(); }
}
