﻿module.exports = [convertToDecimalDirective];

function convertToDecimalDirective() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (val) {
                return val != null ? parseFloat(val.replace(/,/g, '.')) : null;
            });
            ngModel.$formatters.push(function (val) {
                return val != null ? '' + val : null;
            });
        }
    };
};
