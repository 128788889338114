﻿module.exports = ['$uibModalInstance', modalOkController];

function modalOkController($uibModalInstance) {
    var vm = {
        ok: ok
    };

    function ok() {
        $uibModalInstance.close(true);
    }

    return vm;

}