﻿module.exports = ['$translate', 'common', 'constantsService', 'reservationDataService', 'reservationPlanningService', 'reservationWizardService', 'reservationModals', reservationWizardStep1Directive];

function reservationWizardStep1Directive($translate, common, constantsService, reservationData, reservationPlanning, reservationWizard, reservationModals) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep2Controller,
        controllerAs: 'ctrlReservationWizard2',
        restrict: 'E',
        templateUrl: 'templates/reservations/directives/reservation-wizard-step2-alternative.html'
    };

    return directive;

    function reservationWizardStep2Controller() {
        var vm = this;
        vm.addActivity = addActivity;
        vm.berthGroups = [];
        vm.commercialGoals = [];
        vm.common = common;
        vm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        };
        vm.errors = [];
        vm.facilities = [];
        vm.getVesselType = reservationWizard.getVesselType;
        vm.planning = {
            days: [],
            items: []
        };
        vm.nauticalGoals = [];
        vm.next = next;
        vm.newActivity = null;
        vm.wizard = reservationWizard;
        vm.deleteActivity = deleteActivity;
        vm.reservation = reservationWizard.reservation;
        vm.sending = false;
        vm.etaChanged = etaChanged;
        vm.skipReceiptPrice = false;
        init();

        function load() {
            $translate([
                'views.reservations.reservationwizzard.errors.activitytype',
                'views.reservations.reservationwizzard.errors.validdate',
                'views.reservations.reservationwizzard.errors.validenddate',
                'views.reservations.reservationwizzard.errors.noberth',
            ]).then(function (translations) { vm.translations = translations; });

            if (vm.reservation && vm.reservation.processFlowId) {
                var flow = _.find(constantsService.processFlows, function (flow) { return flow.id === vm.reservation.processFlowId; });
                if (flow && (!flow.invoiceTypes || flow.invoiceTypes.length === 0)) {
                    vm.skipReceiptPrice = true;
                }
            }
        }

        function etaChanged(newEta) {
            if (newEta === undefined)
                return;

            var shipType = reservationWizard.getVesselType();
            var config = constantsService.shipTypeConfigs[shipType];

            if (vm.newActivity.startsOn >= vm.newActivity.endsOn) {
                var atd = config && config.resItemAtdMin && config.resItemAtdMin > 0 ? config.resItemAtdMin : 0;
                vm.newActivity.endsOn = moment(vm.newActivity.startsOn).add(atd, 'minutes').toDate();
            }
        }

        function deleteActivity(activity) {
            var index = _.findIndex(vm.wizard.reservation.activities, activity);
            if (index >= 0) {
                vm.wizard.reservation.activities.splice(index, 1);
                vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            }
        }

        function addActivity() {
            vm.errors = [];
            if (!vm.newActivity.nauticalGoal)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.activitytype']);
            if (!vm.newActivity.startsOn)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.eta).diff(vm.newActivity.startsOn, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.etd).diff(vm.newActivity.endsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);
            if (moment(vm.newActivity.startsOn).diff(vm.reservation.etd, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.newActivity.endsOn).diff(vm.newActivity.startsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);

            if (vm.errors.length > 0)
                return;

            if (!vm.wizard.reservation.activities)
                vm.wizard.reservation.activities = [];

            if (vm.newActivity.endsOn === undefined || vm.newActivity.endsOn === null || vm.newActivity.endsOn === '') {
                vm.newActivity.endsOn = vm.reservation.etd;
            }
            vm.wizard.reservation.activities.push(vm.newActivity);
            vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            clearNewActivity();
        }

        function clearNewActivity() {
            vm.newActivity = {
                berthGroup: null,
                commercialGoal: null,
                endsOn: null,
                nauticalGoal: null,
                startsOn: null
            };

            if (!reservationWizard.reservation.activities || reservationWizard.reservation.activities.length === 0)
                vm.newActivity.startsOn = reservationWizard.reservation.eta;
            else if (reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn)
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn;
            else
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].startsOn;

            if (reservationWizard.reservation.berthGroup)
                vm.newActivity.berthGroup = reservationWizard.reservation.berthGroup;
        }

        function generatePlanning() {
            //vm.planning = reservationPlanning.calculateCalendar([{
            //    eta: reservationWizard.reservation.eta,
            //    etd: reservationWizard.reservation.etd
            //}]);
        }

        function init() {
            var vesselType = vm.getVesselType();

            reservationData.getBerthGroupsByShipType(vesselType)
                .then(function (result) {
                    vm.berthGroups = result;
                });
            reservationData.getCommercialGoals()
                .then(function (result) {
                    vm.commercialGoals = result;
                });
            reservationData.getFacilities()
                .then(function (result) {
                    vm.facilities = result;
                });
            reservationData.getNauticalGoals()
                .then(function (result) {
                    vm.nauticalGoals = result;
                });
            clearNewActivity();
            generatePlanning();
            load();
        }

        function next() {
            vm.sending = true;

            vm.errors = [];
            reservationWizard.validation.step2.validate()
                .then(function (validationResult) {
                    if (validationResult.reservations && validationResult.reservations.length > 0) {
                        reservationModals.overlappingReservation(validationResult.reservations)
                            .result
                            .then(function (targetReservation) {
                                if (targetReservation.id !== undefined && targetReservation.id !== null) {
                                    if (targetReservation.id === -1) {
                                        reservationWizard.save()
                                            .then(function () {
                                                vm.sending = false;
                                                common.$location.path('/reservations/');
                                            });
                                    } else {
                                        // Set the ETD of the existing reservation to the new ETD
                                        targetReservation.etd = reservationWizard.reservation.etd;
                                        if (targetReservation.reservationItems && targetReservation.reservationItems.length > 0) {
                                            // If the existing reservation has items
                                            if (reservationWizard.reservation.reservationItems === null || reservationWizard.reservation.reservationItems.length === 0) {
                                                // If the new reservation does not have items set the ETD of the existing last item to the new ETD
                                                targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.etd;
                                            } else {
                                                if (targetReservation.reservationItems[targetReservation.reservationItems.length - 1].berthGroupId === reservationWizard.reservation.berthGroup.id) {
                                                    // If the new reservation does have items, and the items match in terms of berth group
                                                    // combine those two items
                                                    targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.reservationItems[0].etd;
                                                    // Remove the first item of the new reservation
                                                    reservationWizard.reservation.reservationItems.splice(0, 1);
                                                    // Add the remaining items to the existing reservation
                                                    targetReservation.reservationItems = targetReservation.reservationItems.concat(reservationWizard.reservation.reservationItems);
                                                } else {
                                                    // If the new reservation does have items, but the items do not match in terms of berth group
                                                    // set the ETD of the existing last item to the new ETD
                                                    targetReservation.reservationItems[targetReservation.reservationItems.length - 1].etd = reservationWizard.reservation.eta;
                                                    // Add the new items to the end of the existing items
                                                    targetReservation.reservationItems = targetReservation.reservationItems.concat(reservationWizard.reservation.reservationItems);
                                                }
                                            }
                                        } else if (reservationWizard.reservation.reservationItems !== null && reservationWizard.reservation.reservationItems.length > 0) {
                                            // If the existing reservation does not have items, but the new one does, just add those to the existing reservation
                                            targetReservation.reservationItems = reservationWizard.reservation.reservationItems;
                                        }

                                        // invoice reference
                                        targetReservation.invoiceReference = reservationWizard.reservation.invoiceReference;

                                        // Save the existing reservation
                                        reservationData.addOrUpdateReservation(targetReservation)
                                            .then(function () {
                                                vm.sending = false;
                                                common.$location.path('/reservations/');
                                            });
                                    }
                                }
                            }, function (error) {
                                if (error === 'cancel') {
                                    vm.errors = [];
                                } else {
                                    vm.errors = ['There was an unknown error'];
                                }
                                vm.sending = false;
                            });
                    } else if (validationResult.reservations && validationResult.reservations.length === 0) {
                        reservationWizard.save()
                            .then(function () {
                                vm.sending = false;
                                common.$location.path('/reservations/');
                            });
                    } else {
                        vm.sending = false;
                        vm.errors = reservationWizard.validation.step2.errors;
                    }
                }, function (validationError) {
                    vm.sending = false;
                    vm.errors = reservationWizard.validation.step2.errors;
                });
        }
    }
};
