﻿'use strict';

module.exports = ['$location', '$anchorScroll', '$routeParams', '$translate', '$filter', 'common', 'businessruleDataService', 'constantsService', 'dataContext', 'reservationDataService', 'reservationPlanningService', calendarController];

function calendarController($location, $anchorScroll, $routeParams, $translate, $filter, common, businessRuleData, constants, context, reservationData, reservationPlanning) {
    var vm = {
        attached: attached,
        exportCsv: exportCsv,
        getCalendar: getCalendar,
        reservations: [],
        rows: [],
        loading: true,
        filter: {
            sortAscending: true,
            sortField: 'eta',
            searchquery: null,
            search: search
        },
        eta: null,
        etd: null,
        downloadFilename: 'planning_' + moment().format('YYYYMMDDHHmmss') + '.csv',
        // planning calendar
        planningView: {
            loading: true,
            filter: {
                startDate: null,
                endDate: null
            },
            planning: {
                berthDetails: [],
                berths: [],
                calendar: reservationPlanning
            }
        },
        getRowsForBerth: getRowsForBerth,
        loadPlanningView: loadPlanningView
    };

    init();

    function init() {
        // list view
        vm.eta = moment().format('D MMM YYYY');
        vm.etd = moment().add(365, 'days').format('D MMM YYYY');

        // planning view
        vm.planningView.filter.startDate = moment();
        vm.planningView.filter.endDate = moment().add(6, 'days');
    }

    function attached() {
        vm.loadPlanningView(true); // planning view
        vm.getCalendar(); // list view
    }

    function loadPlanningView(forceLoad) {
        if (forceLoad) {
            getBerths();
            calculateDays();
            loadReservationsForPlanningView();
        } else {
            // reload
            // use timeout to wait the date-period-picker is closed; otherwise the startDate and endDate are not updated
            common.$timeout(function () {
                loadBerthObstructions();
                loadReservationsForPlanningView();
            }, 200);
        }
    }

    function getCalendar() {
        vm.loading = true;

        reservationData.getCalendar(vm.eta, vm.etd)
            .then(function (result) {
                vm.reservations = result;
                _.map(vm.reservations, function (item) {
                    item.eta = moment(item.eta).toDate();
                    item.etd = moment(item.etd).toDate();
                });

                vm.rows = vm.reservations;
                vm.loading = false;
            });
    }

    function exportCsv() {
        var uri = '/calendar?dateFrom=&dateUntil=&days=365&page=0&size=65000&output=csv&csv_include_header=1&csv_date_format=yyyy-MM-dd+HH:mm';

        context.get(uri)
            .then(function (result) {
                var filename = 'planning-export-' + moment().format('YYYYMMDDHHmmss') + '.csv';
                var contentType = 'application/csv;charset=utf-8';

                var blob = new Blob(["\ufeff", result], { type: contentType });
                if (navigator.appVersion.toString().indexOf('.NET') > 0)
                    window.navigator.msSaveBlob(blob, filename);
                else {
                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(blob);

                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", filename);

                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) { }
                }

            });
        //.then(blobby => {
        //    var objectUrl = window.URL.createObjectURL(blobby);

        //    anchor.href = objectUrl;
        //    anchor.download = 'planning.csv';
        //    anchor.click();

        //    window.URL.revokeObjectURL(objectUrl);
        //});
    }

    function search(query) {
        query = query.toLowerCase();

        if (query === null || query === undefined || query.length === 0) {
            vm.rows = vm.reservations;
        } else {
            vm.rows = _.filter(vm.reservations, function (item) {
                return item.shipName.toLowerCase().indexOf(query) !== -1 ||
                    item.eta.toString().toLowerCase().indexOf(query) !== -1 ||
                    item.etd.toString().toLowerCase().indexOf(query) !== -1 ||
                    item.berth.toLowerCase().indexOf(query) !== -1 ||
                    item.customer.toLowerCase().indexOf(query) !== -1;
            });
        }
    }


    // -------------------------------------------------
    // Planning Calendar view
    // -------------------------------------------------

    function calculateDays() {
        reservationPlanning.calculateDays({
            eta: vm.planningView.filter.startDate,
            etd: vm.planningView.filter.endDate
        });
    }

    function getBerths() {
        reservationData.getBerthGroupsByBusinessUnit(2)
            .then(function (result) {
                result = _.map(result, function (berthGroup) {
                    berthGroup.berths = _.map(_.filter(berthGroup.berths, function (activeBerth) { return activeBerth.isActive; }), function (berth) {
                        // If the berth name starts with the group name, remove it for display purposes
                        if (berth.name.indexOf(berthGroup.name) === 0 && berth.name.length > berthGroup.name.length) {
                            berth.name = berth.name.substring(berthGroup.name.length).trim();
                        }
                        return berth;
                    });
                    return berthGroup;
                });

                vm.planningView.planning.berths = _.filter(result, function (berthGroup) {
                    return berthGroup.berths && berthGroup.berths.length > 0;
                });
                calculateBerths();
                loadBerthObstructions();
            });
    }

    function calculateBerths() {
        var berths = [];
        for (var a = 0; a < vm.planningView.planning.berths.length; a++) {
            for (var b = 0; b < vm.planningView.planning.berths[a].berths.length; b++) {
                berths.push(vm.planningView.planning.berths[a].berths[b]);
            }
        }
        vm.planningView.planning.berthDetails = berths;
    }

    function loadBerthObstructions() {
        businessRuleData.getByType(3, vm.planningView.filter.startDate.format('YYYY-MM-DD'), vm.planningView.filter.endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                calculateObstructions(result);
            });
    }

    function calculateObstructions(obstructions) {
        reservationPlanning.calculateBerths(vm.planningView.planning.berthDetails, obstructions);
    }

    function loadReservationsForPlanningView() {
        reservationData.getAvailability(vm.planningView.filter.startDate, vm.planningView.filter.endDate, 2)
            .then(function (result) {
                reservationPlanning.calculateDays({
                    eta: vm.planningView.filter.startDate,
                    etd: vm.planningView.filter.endDate,
                    planningItems: result
                });

                var mappedItems = _.map(result, function (item) {
                    item.customer = { name: item.customerName };
                    item.ship = { id: item.shipId, name: item.shipName };
                    return item;
                });

                reservationPlanning.calculateItems(_.filter(result, function (r) { return r.isConfirmed; }), 'planned');
                reservationPlanning.calculateItems(_.filter(result, function (r) { return !r.isConfirmed; }), 'unplanned');

                vm.planningView.loading = false;
            });
    }

    function getRowsForBerth(berth) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

        if (!items || items.length === 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row; });
        var row = result ? result.display.row + 1 : 1;

        return berth.capacity && berth.capacity > row ? row + 1 : row;
    }

    return vm;
}