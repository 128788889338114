﻿module.exports = ['common', 'dataContext', messageDataService];

function messageDataService(common, context) {

    var service = {
        getMessages: getMessages,
        getCustomerMessages: getCustomerMessages,
        getMessageInbox: getMessageInbox,
        getCustomerMessageInbox: getCustomerMessageInbox,
        addCustomerMessage: addCustomerMessage
    };

    function getMessageInbox(pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message/inbox?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
    
    function getCustomerMessageInbox(pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message/inbox/customer?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getMessages(pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getCustomerMessages(pageSize, pageIndex) {
        if (pageIndex === null || pageIndex === undefined)
            pageIndex = 0;
        if (pageSize === null || pageSize === undefined)
            pageSize = 10;

        var deferred = common.$q.defer();
        var req = context.get('/message/customer?pindex=' + pageIndex + '&psize=' + pageSize)
            .then(function (data) {
                messagesToLocalTime(data);
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function addCustomerMessage(text, isIntern) {
        var deferred = common.$q.defer();

        var args = {
            isIntern: isIntern,
            text: text
        };

        var req = context.post('/message/customer', args)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function messagesToLocalTime(data) {
        return _.map(data, function (item) {
            item.createdOn = moment(item.createdOn).add(moment().utcOffset(), 'minutes').format('DD MMMM YYYY HH:mm');
            return item;
        });
    }

    return service;

}