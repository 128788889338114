﻿module.exports = {
    'title': 'Log In',
    'register': 'Register an Account',
    'username': 'Username',
    'password': 'Password',
    'remember': 'Remember Me',
    'errorLoggingIn': 'There was an error logging in:',
    'invalidCombination': 'The username/password combination was invalid',
    'falsestatus': 'Your account is not active, please contact your administrator',
    'processingRegistration': 'Processing registration',
    'errorRegistering': 'There was an error registering your account:',
    'registerNotification':'Your account must be activated by authority. Please come back later',
    'loginProcessing': 'Processing Login',
    'loginParagraph': 'Welcome to the Port of Amsterdam online berth reservation environment. Please log in to use the application.',
    'errors': {
        'missingbusinessunit': 'Please specify a company type',
        'missingcompany': 'Please specify a company name',
        'missingemail': 'Please specify an email address',
        'missingpassword': 'Please specify a password',
        'missingconfirmpassword': 'Please confirm your password',
        'mismatchedpassword': 'The passwords do not match',
        'missingaddress': 'Please specify shipping address',
        'missingpostcode': 'Please specify postal code',
        'missingcity': 'Please specify city',
        'missingcountry': 'Please specify country',
        'missingkvk': 'Please specify chamber of commerce number',
        'missingbtw': 'Please specify VAT number',
        'missingiban': 'Please specify IBAN number',
        'missingphone': 'Please specify phone number',
        'missingtype': 'Please select business unit'
    },
    'passwordforgot': 'Forgot Password?',
    'passwordforgotTitle': 'Forgot your password?',
    'passwordforgotBody': "After you have entered your email address you will receive a message with instructions on how to reset your password",
    'requestpasswordreset': 'Request a new password',
    'email': 'email'
};