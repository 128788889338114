﻿angular.module('poa.rprp.reservationsSeacruise', [])
    .config(['$routeProvider', routeConfig])
     // Controllers
    .controller('reservationNewSeacruiseController', require('./controllers/reservation-new-seacruise'))
    .controller('updateSeacruiseReservationController', require('./controllers/reservation-update-seacruise'))
    // Directives
    .directive('reservationWizardStep1Seacruise', require('./directives/reservation-wizard-step1'))
    .directive('reservationWizardStep2Seacruise', require('./directives/reservation-wizard-step2'))
    // Services
    .factory('reservationWizardSeacruiseService', require('./services/reservation-wizard'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/reservations/new-seacruise/:flowId', {
            templateUrl: 'templates/reservations-seacruise/controllers/reservation-new-seacruise.html'
        })
        .when('/reservations/update-seacruise', {
            templateUrl: 'templates/reservations-seacruise/controllers/reservation-update-seacruise.html'
        })
        .when('/reservations/update-seacruise/:reservationId', {
            templateUrl: 'templates/reservations-seacruise/controllers/reservation-update-seacruise.html'
        })
        ;
};
