﻿module.exports = ['$rootScope', 'common', 'dataContext', navbarDirective];

function navbarDirective($rootScope, common, context) {
    var directive = {
        bindToController: true,
        controller: navbarController,
        controllerAs: 'ctrlNavbarUserProfile',
        restrict: 'E',
        templateUrl: 'templates/widgets/directives/navbar-user-profile.html'
    };

    return directive;

    function navbarController() {
        var vm = this;
        vm.identity = common.identity;

        $rootScope.$on('identity:set', common.identity.reloadUserInfo);
        $rootScope.$on('account:changed', common.identity.reloadUserInfo);

    };

};

