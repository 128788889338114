﻿module.exports = {
    'myFleet': 'Mijn Vloot',
    'activateVessel': 'Activeer schip',
    'addVessel': 'Toevoegen',
    'editVessel': 'Schip bewerken',
    'fleetCompleted': 'Uw wijzigingen zijn succesvol opgeslagen',
    'fleetError': 'Er is een fout opgetreden tijdens het opslaan:',
    'deleteVessel': 'Deactiveer schip',
    'addNewVessel': 'Voeg een nieuw schip toe',
    'saveVessel': 'Schip opslaan',
    'loading': 'De schepen worden geladen...',
    'noFleet': 'Er zijn nog geen schepen toegevoegd',
    'issues': 'Fout(en)',
    'conditions': {
        'areyousure': 'Wilt u dit schip verwijderen uit uw vloot?',
        'cancelReservations': 'Er zijn meerdere reserveringen verbonden aan dit schip. Wanneer u doorgaat met deze handeling zullen de reserveringen geannuleerd worden. Aantal reserveringen: ',
        'canceledReservations': 'Geannuleerde reserveringen: ',
        'completedReservations': 'Voltooid',
        'processingReservations': 'Verwerken',
    }
};