﻿module.exports = ['$uibModal', reservationDolphinModalsService];

function reservationDolphinModalsService($uibModal) {

    var service = {
        changeBerth: changeBerth
    };

    return service;

    function changeBerth(reservation) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/reservations-dolphin/modals/change-berth.html',
            controllerAs: 'ctrlChangeBerth',
            controller: 'changeBerthModalController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return reservation;
                }
            }
        });
    }
}
