﻿module.exports = ['constantsService', 'userDataService', userFormDirective];

function userFormDirective(constants, userData) {
    var directive = {
        bindToController: true,
        controller: userFormController,
        controllerAs: 'ctrlUserForm',
        restrict: 'E',
        scope: {
            showPassword: '=',
            showRole: '=',
            userData: '='
        },
        templateUrl: 'templates/users/directives/user-form.html'
    };

    return directive;

    function userFormController() {
        var ctrlUserForm = this;
        ctrlUserForm.constants = constants;
        ctrlUserForm.roles = [];

        userData.getRoles()
            .then(function (data) {
                ctrlUserForm.roles = _.map(data, function (item) { item.permissions = []; return item; });
            });
        ctrlUserForm.selectedRoles = {};
        /*
        ctrlUserForm.checkBoxChange = function () {
            ctrlUserForm.userRoles = ctrlUserForm.selectedRoles.map(function (key) {
                return { id: parseInt(key) };
            });
            if (ctrlUserForm.userRoles && ctrlUserForm.userRoles.length > 0) {
                ctrlUserForm.userRoles = _.filter(ctrlUserForm.userRoles, function (r) {
                    if (r) {
                        return r !== undefined;
                    }
                });
            }
            ctrlUserForm.userData.userRoles = ctrlUserForm.userRoles;
        };
        */
    }

};
