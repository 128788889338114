﻿'use strict';
module.exports = [reservationDateDisplayFilter];

function reservationDateDisplayFilter() {
    return function (reservation, passedEta, passedEtd) {
        if (!reservation)
            return null;

        if (!reservation.eta || !reservation.etd)
            return null;

        var eta = moment(passedEta ? passedEta : reservation.eta);
        var etd = moment(passedEtd ? passedEtd : reservation.etd);

        if (eta.year() === etd.year()) {
            if (eta.month() === etd.month()) {
                if (eta.date() === etd.date())
                    return eta.format('D MMM YYYY | HH:mm') + ' - ' + etd.format('HH:mm');
                return eta.format('D') + ' - ' + etd.format('D MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
            }
            return eta.format('D MMM') + ' - ' + etd.format('D MMM YYYY') + ' | ' + eta.format('HH:mm') + ' - ' + etd.format('HH:mm');
        }

        return eta.format('D MMM YYYY HH:mm') + ' - ' + etd.format('D MMM YYYY HH:mm');
    };
};