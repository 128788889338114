﻿module.exports = {
    'myFleet': 'Fleet Overview',
    'activateVessel': 'Activate Vessel',
    'addVessel': 'Add Vessel',
    'editVessel': 'Edit Vessel',
    'fleetCompleted': 'Your changes have been saved successfully',
    'fleetError': 'There was an error saving your changes:',
    'deleteVessel': 'Deactivate Vessel',
    'addNewVessel': 'Add a New Vessel',
    'saveVessel': 'Save Vessel',
    'loading': 'The fleet is being loaded...',
    'noFleet': 'No ships have been added yet',
    'issues': 'Issue(s)',
    'conditions': {
        'areyousure': 'Are you sure that you want to delete this vessel from your fleet?',
        'cancelReservations': 'There are several reservations attached to this vessel. If you continue, the reservations will be canceled. Number of reservations: ',
        'canceledReservations': 'Canceled reservations: ',
        'completedReservations': 'Completed',
        'processingReservations': 'Processing',
    },
    'errors': {
        'delete': 'Can\'t delete vessel with reservation.',
    }
};