﻿module.exports = [formatting];

function formatting() {
    var service = {
        differenceInDays: differenceInDays,
        formatDate: formatDate,
        formatDateFromNow: formatDateFromNow,
        padLeft: padLeft,
        padRight: padRight
    };

    return service;

    function differenceInDays(date1, date2) {
        if (!date1 || !date2)
            return null;

        var utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        var utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

        var _MS_PER_DAY = 1000 * 60 * 60 * 24;
        return Math.ceil((utc2 - utc1) / _MS_PER_DAY) + 1;
    };

    function formatDate(date) {
        return moment(date).format('DD-MM-YYYY HH:mm');
    };

    function formatDateFromNow(date, useAgo) {
        if (useAgo === undefined || useAgo === null)
            useAgo = false;
        return moment(date).fromNow(useAgo);
    };

    function padLeft(str, padChar, totalSize) {
        if (!padChar)
            return str;
        var result = str;
        while (result.length < totalSize) {
            result = padChar + result;
        }
        return result;
    };

    function padRight(str, padChar, totalSize) {
        if (!padChar)
            return str;
        var result = str;
        while (result.length < totalSize) {
            result = result + padChar;
        }
        return result;
    };
};
