﻿module.exports = ['$translate', 'common', 'fleetDataService', 'fleetModelService', 'reservationDataService', 'businessruleDataService', 'constantsService', reservationWizardGenericService];

function reservationWizardGenericService($translate, common, fleetData, fleetModels, reservationData, businessruleData, constantsService) {

    var service = {
        clear: clear,
        currentStep: 1,
        getFacilitiesDescription: getFacilitiesDescription,
        getVesselType: getVesselType,
        reservation: {
            reservationItems: [],
            eta: null,
            etd: null,
            newVessel: null,
            selectedVessel: null
        },
        prepareToSave: prepareToSave,
        save: save,
        validation: {
            step1: {
                errors: [],
                validate: validateStep1
            },
            step2: {
                errors: [],
                validate: validateStep2
            }
        },
        configuration:{
            hasVessel:true,
            hasTimes:true,
            hasBerth:true,
            hasCustomer:true,
            hasInvoiceAddress: true,
            allowInvoiceRef: true,
            showPrice:true,
            requiredFields:[],
            items:{
                allowItems:true,
                min:0,
                max:0
            },
            berthSelectType:null,
            berthSelectRule:null,
            allowOldEta:true,
            allowOldEtd:true,
            minimumPeriodInHours:true,
            maximumPeriodInHours: true
        },
        setConfiguration:setConfiguration,
        isRequired:isRequired,
        loadReservation:loadReservation,
        greenAwardShipTypes:null,
        greenAwardBerthValidation:greenAwardBerthValidation,
        loadedBerthGreenAwardLevel:false,
        selectedBerthId:null,
        selectedBerthGreenAwardLevel:0,
        selectedVesselId:null,
        selectedVesselGreenAwardLevel:0
    };
    load();
    return service;

    function load() {
        service.greenAwardShipTypes = _.find(constantsService.shipPropertyTypes, function (shipPropertyType) { return shipPropertyType.systemCode.toLowerCase() == 'greenaward'});
    }

    function setConfiguration(flow){
        service.configuration.hasVessel = flow.reservationConfiguration.allow_vessel_selection;
        service.configuration.hasTimes = flow.reservationConfiguration.allow_time_selection;
        service.configuration.hasBerth = flow.reservationConfiguration.allow_berth_selection;
        service.configuration.hasCustomer = flow.reservationConfiguration.allow_customer_selection;
        service.configuration.hasInvoiceAddress = flow.reservationConfiguration.allow_invoice_address_selection;
        service.configuration.allowInvoiceRef = flow.reservationConfiguration.allow_invoice_reference;
        service.configuration.showPrice = flow.reservationConfiguration.ui_show_price === null ? true : flow.reservationConfiguration.ui_show_price;
        service.configuration.requiredFields = flow.reservationConfiguration.req_fields === null ? [] : flow.reservationConfiguration.req_fields;
        if(flow.reservationConfiguration.items){
            service.configuration.items.allowItems = flow.reservationConfiguration.items.allow_items;
            service.configuration.items.min = flow.reservationConfiguration.items.min;
            service.configuration.items.max = flow.reservationConfiguration.items.max;
        } 
        service.configuration.berthSelectType = flow.reservationConfiguration.berths;
        service.configuration.berthSelectRule = flow.reservationConfiguration.berth_select;
        service.configuration.allowOldEta = flow.reservationConfiguration.eta_old;
        service.configuration.allowOldEtd = flow.reservationConfiguration.etd_old;
        service.configuration.minimumPeriodInHours = flow.reservationConfiguration.min_period;
        service.configuration.maximumPeriodInHours = flow.reservationConfiguration.max_period;
    }


    function getFacilitiesDescription(facilities) {
        _.reduce(facilities, function (curItem, nextItem) { return curItem + ', ' + nextItem; }, '');
    }

    function clear() {
        service.currentStep = 1;
        service.reservation = {
            reservationItems: [],
            eta: null,
            etd: null,
            selectedVessel: null
        };
        service.validation.step1.errors = [];
        service.validation.step2.errors = [];
    }

    function loadReservation(id){
        return reservationData.getReservation(id).then(function (result){

            var eta = new Date(result.eta);
            var etd = new Date(result.etd);

            service.reservation = {
                activities: result.reservationItems,
                berthGroup: result.reservationItems ? result.reservationItems[0].berthGroup : null,
                berth: result.reservationItems ? result.reservationItems[0].berth : null,
                eta: eta,
                etd: etd,
                newVessel: null,
                selectedVessel: result.ship,
                id: result.id,
                processFlowId: result.processFlowId
            };

            service.currentStep = 1;
        });
    }

    function getVesselType() {
        if (!service.reservation || !service.reservation.newVessel && !service.reservation.selectedVessel)
            return 1;

        if (service.reservation.selectedVessel)
            return service.reservation.selectedVessel.shipTypeId ? service.reservation.selectedVessel.shipTypeId : 1;
        else if (service.reservation.newVessel)
            return service.reservation.newVessel.shipTypeId ? service.reservation.newVessel.shipTypeId : 1;

        return 1;
    }

    function prepareToSave(reservation) {
        var result = reservation;
        if (result.activities) {
            result.reservationItems = _.map(result.activities, function (activity) {
                var item = _.clone(activity); // make a clone, ensure the property of the activity is not changed
                if (!item.endsOn)
                    item.endsOn = moment(item.startsOn).add(1, 'hours').toDate();
                var reservationItemId = item.id ? item.id : null;

                if (item.facilities && item.facilities.length > 0)
                    item.facilities = _.map(item.facilities, function (facility) {
                        return { facilityId: facility.id, reservationItemId: reservationItemId };
                    });
                if (!item.berth && result.berth)
                    item.berth = result.berth;
                else if (!item.berthGroup)
                    item.berthGroup = result.berthGroup;
                return item;
            });
        }
        else {
            // Manually add one to accomodate the berth selection
            result.reservationItems = [{
                berthGroup: result.berthGroup,
                berth: result.berth,
                endsOn: result.etd,
                startsOn: result.eta
            }];
        }

        if (reservation.selectedVessel !== null) {
            result.length = reservation.selectedVessel.length;
            result.shipId = reservation.selectedVessel.id;
            result.ship = reservation.selectedVessel;
        } else if (reservation.newVessel !== null) {
            result.length = reservation.newVessel.length;
            result.shipId = -1;
            result.ship = reservation.newVessel;
        }

        return result;
    }

    function save() {
        var deferred = common.$q.defer();

        if (!validateStep2())
            deferred.reject(error);

        var reservation = prepareToSave(service.reservation);

        if (reservation.selectedVessel !== null) {
            reservation.length = reservation.selectedVessel.length;
            reservation.shipId = reservation.selectedVessel.id;
            var req = reservationData.addOrUpdateReservation(reservation)
                .then(function (data) {
                    clear();
                    deferred.resolve(data);
                }, function (error) {
                    deferred.reject(error);
                });
        }
        else if (reservation.newVessel !== null) {
            fleetData.addShip(reservation.newVessel)
                .then(function (vessel) {
                    reservation.length = vessel.length;
                    reservation.shipId = vessel.id;

                    var req = reservationData.addOrUpdateReservation(reservation)
                        .then(function (data) {
                            clear();
                            deferred.resolve(data);
                        }, function (error) {
                            deferred.reject(error);
                        });
                }, function (error) {
                    deferred.reject(error);
                });
        }

        return deferred.promise;
    }

    function validateStep1(addNewVessel) {
        service.validation.step1.errors = [];
        var validEta, validEtd = false;
        let lastYear = moment().subtract(1, 'year');
        let endOfTheYear = moment().endOf('year');
        let commingYear = moment(moment(endOfTheYear).add(1, 'year'));


        if(service.configuration.hasVessel){
            if (!addNewVessel && isRequiredAndIsNull('selectedVessel'))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.vesselselect');
            else if (addNewVessel && fleetModels.validateVessel(service.reservation.newVessel) !== true) {
                var errors = fleetModels.validateVessel(service.reservation.newVessel);
                for (var i = 0; i < errors.length; i++) {
                    service.validation.step1.errors.push(errors[i]);
                }
            }
        }
        
        if(service.configuration.hasCustomer && isRequiredAndIsNull('customer')){
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.nocustomer');
        }

        if(service.configuration.hasInvoiceAddress && isRequiredAndIsNull('invoiceAddress')){
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.noInvoiceAddress');
        }

        if (service.configuration.allowInvoiceRef && isRequiredAndIsNull('invoiceReference')) {
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.noInvoiceReference');
        }
        
        if(service.configuration.hasTimes){
            if (isRequiredAndIsNull('eta'))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivaldate');
            else
                validEta = true;

            if (moment(service.reservation.eta).isBefore(lastYear))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooOld');
            else validEta = true;

            if (moment(service.reservation.eta).isAfter(commingYear))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooFar');
            else validEta = true;

            if(!service.configuration.allowOldEta && moment(service.reservation.eta).isBefore(moment().subtract(1,'days')))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.etaInPast');

            if(!service.configuration.allowOldEtd && moment(service.reservation.etd).isBefore(moment().subtract(1,'days')))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.etdInPast');

            var period = moment.duration(moment(service.reservation.etd).diff(moment(service.reservation.eta))).asHours();
            if(period < service.configuration.minimumPeriodInHours)
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.periodMin');

            if(period > service.configuration.maximumPeriodInHours && service.configuration.maximumPeriodInHours !== 0)
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.periodMax');

            if (isRequiredAndIsNull('etd'))
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.departuredate');
            else
                validEtd = true;

            if (validEta && validEtd && service.reservation.etd < service.reservation.eta)
                service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivalbeforedeparturedate');
            
            if(service.reservation.eta && !moment(service.reservation.eta).isBefore(moment('2023-01-01')) && greenAwardBerthValidation().requiresWarning)
                service.validation.step1.errors.push('views.reservations.reservationwizzard.greenAwardDenied');
    
        }

        if(service.configuration.hasBerth && isRequiredAndIsNull('berthGroup') && isRequiredAndIsNull('berth')){
            /* Added to accomodate the new reservation screen */
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.noberth');
        }

        return service.validation.step1.errors.length === 0;
    }

    function validateStep2() {
        var deferred = common.$q.defer();

        service.validation.step2.errors = [];

        // when the id > 0 it means it is a update, because reservation has always 1 reservationitem. so check the max
        if(service.configuration.items.allowItems || service.reservation.id > 0){
            if((!service.reservation.activities && service.configuration.items.min>0) || (service.reservation.activities && service.reservation.activities.length < service.configuration.items.min))
                service.validation.step2.errors.push('views.reservations.reservationwizzard.errors.minReservationItems');

            if (service.reservation.activities && service.configuration.items.max !== 0 && service.reservation.activities.length > service.configuration.items.max)
                    service.validation.step2.errors.push('views.reservations.reservationwizzard.errors.maxReservationItems');

        }else if(service.reservation.activities && service.reservation.activities.length > 0){
            service.validation.step2.errors.push('views.reservations.reservationwizzard.errors.notAllowedReservationItems');
        }

        if (service.validation.step2.errors.length > 0) {
            deferred.reject(service.validation.step2.errors);
        } else {
            var reservation = prepareToSave(service.reservation);
            if (!reservation.id && reservation.ship.shipTypeId === 1) {
                reservationData.getOverlappingReservations(reservation)
                    .then(function (result) {
                        deferred.resolve({ reservations: result });
                    }, function (error) {
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve({ reservations: [] });
            }
        }

        return deferred.promise;
    }

    function isRequired(value){
        return _.contains(service.configuration.requiredFields,value);
    }

    function isRequiredAndIsNull(value){
        var isRequiredValue = value;
        if(value === 'berth')
            isRequiredValue = 'berthGroup';
        var isRequired = service.isRequired(isRequiredValue);
        var property =  service.reservation[value];
        var isNull = (property === null || property === undefined || property === '');

        return isRequired&&isNull;
    }

    function greenAwardBerthValidation() {
        if(service.reservation && service.reservation.selectedVessel && service.reservation.selectedVessel.shipType && service.reservation.selectedVessel.shipType.id && service.greenAwardShipTypes && service.greenAwardShipTypes.shipTypes && service.greenAwardShipTypes.shipTypes.length && _.contains(_.pluck(service.greenAwardShipTypes.shipTypes, 'id'), service.reservation.selectedVessel.shipType.id) && service.reservation.berth && service.reservation.berth.id) {
            if(!service.selectedBerthId || (service.selectedBerthId && service.selectedBerthId != service.reservation.berth.id)) {
                service.selectedBerthId = service.reservation.berth.id;
                service.loadedBerthGreenAwardLevel = false;
              
                businessruleData.getByBerth(service.reservation.berth.id).then(
                    function(result) {
                        if(result && result.length) {
                            let iBerth = 0;
                            let berthUsingNowDate = false;
                            let relevantBerthGreenAward = { startDate: null, value: null };
              
                            _.each(result, rule => {
                                iBerth++;
                              
                                if(rule.businessRuleId == 8 && (!rule.endsOn || (!service.reservation.eta && moment(rule.endsOn).isAfter(moment()) || moment(rule.endsOn).isAfter(moment(service.reservation.eta))))) {
                                    if(!rule.startsOn) {
                                        if(!berthUsingNowDate && !relevantBerthGreenAward.startDate) {
                                            relevantBerthGreenAward.startDate = moment();
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                                            berthUsingNowDate = true;
                                        }
                              
                                    } else if(!service.reservation.eta) {
                                        if(!relevantBerthGreenAward.startDate) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                              
                                        } else if(moment(relevantBerthGreenAward.startDate).isBefore(moment(rule.startsOn))) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                              
                                        } else if(berthUsingNowDate) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                                            berthUsingNowDate = false;
                                        }
                              
                                    } else if(moment(service.reservation.eta).isAfter(moment(rule.startsOn))) {
                                        if(!relevantBerthGreenAward.startDate) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                              
                                        } else if(moment(relevantBerthGreenAward.startDate).isBefore(moment(rule.startsOn))) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                              
                                        } else if(berthUsingNowDate) {
                                            relevantBerthGreenAward.startDate = moment(rule.startsOn);
                                            relevantBerthGreenAward.value = rule.businessRuleParameters;
                                            berthUsingNowDate = false;
                                        }
                                    }
                                }
                                
                                if(iBerth == result.length) {
                                    if(relevantBerthGreenAward.value) {
                                        switch(relevantBerthGreenAward.value.toLowerCase()) {
                                            case 'none':
                                                service.selectedBerthGreenAwardLevel = 0;
                                            break;
                              
                                            case 'bronze':
                                                service.selectedBerthGreenAwardLevel = 1;
                                            break;
                              
                                            case 'silver':
                                                service.selectedBerthGreenAwardLevel = 2;
                                            break;
                              
                                            case 'gold':
                                                service.selectedBerthGreenAwardLevel = 3;
                                            break;
                                            
                                            default:
                                                service.selectedBerthGreenAwardLevel = 4;
                                            break;
                                        }
                                        
                                    } else {
                                        service.selectedBerthGreenAwardLevel = 0;
                                    }

                                    service.loadedBerthGreenAwardLevel = true;
                                }
                            });
              
                        } else {
                            service.selectedBerthGreenAwardLevel = 0;
                        }
                    }
                );
            }
      
            if(!service.selectedVesselId || (service.selectedVesselId && service.selectedVesselId != service.reservation.selectedVessel.id)) {
                service.selectedVesselId = service.reservation.selectedVessel.id;
      
                if(service.reservation.selectedVessel.shipProperties && service.reservation.selectedVessel.shipProperties.length) {
                    let iVessel = 0;
                    let vesselUsingNowDate = false;
                    let relevantVesselGreenAward = { startDate: null, value: null };
      
                    _.each(service.reservation.selectedVessel.shipProperties, property => {
                        iVessel++;
      
                        if(property.property == 3 && (!property.endsOn || (!service.reservation.eta && moment(property.endsOn).isAfter(moment()) || moment(property.endsOn).isAfter(moment(service.reservation.eta))))) {
                            if(!property.startsOn) {
                                if(!vesselUsingNowDate && !relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment();
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = true;
                                }
      
                            } else if(!service.reservation.eta) {
                                if(!relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(moment(relevantVesselGreenAward.startDate).isBefore(moment(property.startsOn))) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(vesselUsingNowDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = false;
                                }
      
                            } else if(moment(service.reservation.eta).isAfter(moment(property.startsOn))) {
                                if(!relevantVesselGreenAward.startDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(moment(relevantVesselGreenAward.startDate).isBefore(moment(property.startsOn))) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
      
                                } else if(vesselUsingNowDate) {
                                    relevantVesselGreenAward.startDate = moment(property.startsOn);
                                    relevantVesselGreenAward.value = property.value;
                                    vesselUsingNowDate = false;
                                }
                            }
                        }
                        
                        if(iVessel == service.reservation.selectedVessel.shipProperties.length) {
                            if(relevantVesselGreenAward.value) {
                                switch(relevantVesselGreenAward.value.toLowerCase()) {
                                    case 'none':
                                        service.selectedVesselGreenAwardLevel = 0;
                                    break;
      
                                    case 'bronze':
                                        service.selectedVesselGreenAwardLevel = 1;
                                    break;
      
                                    case 'silver':
                                        service.selectedVesselGreenAwardLevel = 2;
                                    break;
      
                                    case 'gold':
                                        service.selectedVesselGreenAwardLevel = 3;
                                    break;
                                    
                                    default:
                                        service.selectedVesselGreenAwardLevel = 4;
                                    break;
                                }
                                
                            } else {
                                service.selectedVesselGreenAwardLevel = 0;
                            }
                        }
                    });
      
                } else {
                    service.selectedVesselGreenAwardLevel = 0;
                }
            }
            
            return { requiresWarning: service.loadedBerthGreenAwardLevel && (service.selectedBerthGreenAwardLevel > service.selectedVesselGreenAwardLevel), vesselLevel: service.selectedVesselGreenAwardLevel, berthLevel: service.selectedBerthGreenAwardLevel };
        
        } else {
            return { requiresWarning: false, vesselLevel: 0, berthLevel: 0 };
        }
    }

}
