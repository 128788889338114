﻿module.exports = {
    'overview': 'Reservation overview',
    'findReservation': 'Find reservation...',
    'upcoming': 'Upcoming',
    'newReservation': 'New reservation',
    'newReservationDolphin': 'New reservation Dolphin',
    'activeReservation': 'You have no active reservations',
    'makeReservation': 'Make a reservation:',
    'sendReservation': 'Send reservation',
    'eta': 'ETA',
    'berth': 'Berth',
    'facilities': 'Facilities:',
    'etd': 'ETD',
    'cancelReservation': 'Cancel Reservation',
    'berthPlanning': 'Berth Planning',
    'loadingReservations': 'The reservations are being loaded...'
};