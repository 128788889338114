﻿module.exports = ['$http', 'common', 'toastr', dataContextService];

function dataContextService($http, common, toastr) {
    var vm = {
        del: function (url) { return requestWithoutData('DELETE', url); },
        get: function (url) { return requestWithoutData('GET', url); },
        post: function (url, parameters) { return requestWithData('POST', url, parameters); },
        put: function (url, parameters) { return requestWithData('PUT', url, parameters); },
        xhr: function (url, parameters) { return requestXhrWithData('POST', url, parameters); },
        formEncode: formEncode,
        getHeaders: getHeaders
    };

    function formEncode(obj) {
        var encodedString = '';
        for (var key in obj) {
            if (encodedString.length !== 0) {
                encodedString += '&';
            }

            encodedString += key + '=' + encodeURIComponent(obj[key]);
        }
        return encodedString.replace(/%20/g, '+');
    };

    function requestWithoutData(method, url) {
        var deferred = common.$q.defer();
        url = processUrl(url);

        $http({ method: method, url: url, headers: getHeaders() })
            .then(successCallback, errorCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); };
        function errorCallback(error) {
            deferred.reject(error);
        };

        return deferred.promise;
    };

    function requestWithData(method, url, parameters) {
        var deferred = common.$q.defer();
        var inputData = JSON.stringify(parameters);
        url = processUrl(url);

        var config = { method: method, url: url, headers: getHeaders(), data: inputData };

        $http(config)
            .then(successCallback, errorCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); };
        function errorCallback(error) {
            deferred.reject(error);
        };

        return deferred.promise;
    };

    function requestXhrWithData(method, url, parameters) {
        var deferred = common.$q.defer();
        url = processUrl(url);

        var headers = getHeaders();
        headers["Content-Type"] = undefined;

        var config = { method: method, url: url, headers: headers, data: parameters };
        config.transformRequest = angular.identity;

        $http(config)
            .then(successCallback, errorCallback, progressCallback);

        function successCallback(data, status, headers, cfg) { deferred.resolve(data.data); };
        function errorCallback(error) { deferred.reject(error); };
        function progressCallback(progress) { deferred.notify(progress); };

        return deferred.promise;
    };

    // we have to include the Bearer token with each call to the Web API controllers. 
    function getHeaders() {
        var header = {};
        if (common.identity.token && common.identity.token.tokenValue)
            header["Authorization"] = "Bearer " + common.identity.token.tokenValue;
        return header;
    };

    function processUrl(url) {
        if (url === null || url === '')
            return url;
        if (url.toLowerCase().indexOf('http://') !== 0 && url.toLowerCase().indexOf('https://') !== 0)
            url = common.config.apiHost + url;
        return url;
    };

    return vm;

};
