﻿module.exports = {
    'vesselName': 'Vessel Name:',
    'vesselType': 'Vessel Type:',
    'paxCapacity': 'PAX Capacity',
    'imoNumber': 'IMO Number:',
    'eniNumber': 'ENI Number:',
    'height': 'Height:',
    'legnth': 'Vessel Length:',
    'width': 'Vessel Width:',
    'maxDraught': 'Maximum Draught:',
    'phoneNumber': 'Phone Number:',
    'remarks': 'Remarks:',
    'loa': 'LOA:',
    'selectType': 'Select Type',
    'selectActivity': 'Select Activity',
    'selectCargo': 'Select Cargo',
    'tanker': 'Tanker',
    'inert': 'Inert',
    'dangerousGoods': 'Dangerous goods on board',
    'yes': 'Yes',
    'no': 'No',
    'unnumber': 'UN Number/ Product name'
};