﻿module.exports = ['$location', '$rootScope', 'common', 'constantsService', '$window', navbarDirective];

function navbarDirective($location, $rootScope, common, constants, $window ) {
    var directive = {
        bindToController: true,
        controller: navbarController,
        controllerAs: 'ctrlNavbar',
        restrict: 'E',
        templateUrl: 'templates/widgets/directives/navbar.html'
    };
    
    return directive;

    function navbarController() {
        var vm = {
            constants: constants,
            identity: common.identity,
            isActive: isActive,
            mobileNavOpen: false,
            openMobileNav: openMobileNav,
            showRcCalendar: showRcCalendar,
            supportedLanguages: common.language.supportedLanguages,
            title: 'Reservations'
        };
        function openMobileNav() {
            vm.mobileNavOpen = !vm.mobileNavOpen;
            if (vm.mobileNavOpen && $window.innerWidth < 1200) {
                document.getElementById("main").style.marginTop = "340px";
            } else {
                document.getElementById("main").style.marginTop = "0";
            }
        }
        angular.element($window).on('resize', function () {
            if ($window.innerWidth > 1200){
                document.getElementById("main").style.marginTop = "0";
                vm.mobileNavOpen = false;
            }
        });

        function isActive(viewLocation) {
            var active = (viewLocation === $location.path());
            return active;
        }

        function showRcCalendar() {
            // service provider customer doesn't have any shiptypes, so default allow these customers to see the RC calendar
            return !constants.hasBusinessUnit(1) || constants.hasBusinessUnit(2) || constants.hasBusinessUnit(3);
        }

        return vm;
    }
}

