﻿angular.module('poa.rprp', [
    // Angular modules
    'ngCookies',
    'ngRoute',
    'ngSanitize',
    // 3rd party angular modules
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'ui.bootstrap',
    'ui.select',
    'toastr',
    // PortOfAmsterdam modules
    'poa.rprp.core',
    'poa.rprp.account',
    'poa.rprp.businessUnits',
    'poa.rprp.businessrule',
    'poa.rprp.calendar',
    'poa.rprp.dashboard',
    'poa.rprp.fleet',
    'poa.rprp.messages',
    'poa.rprp.planning',
    'poa.rprp.search',
    'poa.rprp.users',
    'poa.rprp.widgets',
    'poa.rprp.reservationsOverview',
    'poa.rprp.reservationsDolphin',
    'poa.rprp.reservationsSeacruise',
    'poa.rprp.reservationsGeneric',
    'poa.rprp.reservations',
    'poa.rprp.financial'

]);

// require all internal modules
require('./config');
require('./core/_module');
require('./account/_module');
require('./business-unit/_module');
require('./businessrule/_module');
require('./calendar/_module');
require('./dashboard/_module');
require('./fleet/_module');
require('./messages/_module');
require('./planning/_module');
require('./reservationsOverview/_module');
require('./reservations/_module');
require('./search/_module');
require('./users/_module');
require('./widgets/_module');
require('./reservations-dolphin/_module');
require('./reservations-seacruise/_module');
require('./reservations-generic/_module');
require('./financial/_module');
