﻿module.exports = ['$uibModal', reservationModalsService];

function reservationModalsService($uibModal) {

    var service = {
        overlappingReservation: overlappingReservation
    };

    return service;

    function overlappingReservation(reservations) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/reservations/modals/overlapping-reservation.html',
            controllerAs: 'ctrlOverlappingReservation',
            controller: 'overlappingReservationModalController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return reservations;
                }
            }
        });
    }
}
