﻿module.exports = ['$uibModalInstance', 'modalArguments', overlappingReservationModalController];

function overlappingReservationModalController($uibModalInstance, modalArguments) {
    var vm = {
        ok: ok,
        cancel: cancel,
        args: modalArguments,
        hasError: false,
        selectedItem: null
    };

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        vm.hasError = false;
        if (vm.selectedItem === null) {
            vm.hasError = true;
        } else {
            $uibModalInstance.close(vm.selectedItem);
        }
    }

    return vm;

}