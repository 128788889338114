﻿'use strict';

module.exports = ['common', 'calendarDataService', nauticalCalendarController];

function nauticalCalendarController(common, calendarDataService) {
    var vm = {
        canLoadMore: false,
        items: [],
        getCalendar: getCalendar,
        loading: true,
        loadMore: loadMore,
        filter: {
            sortAscending: true,
            sortField: 'eta',
            searchQuery: null
        },
        pageIndex: 0,
        pageSize: 50
    };

    init();

    function init() {
    }

    function getCalendar(loadMore) {
        vm.loading = true;

        if (vm.pageIndex > 0 && !loadMore)
            vm.pageIndex = 0;

        calendarDataService.getNautical(vm.pageIndex, vm.pageSize, vm.filter.searchQuery)
            .then(function (result) {
                if (!loadMore)
                    vm.items = result;
                else
                    vm.items = vm.items.concat(result);
                vm.loading = false;
                vm.canLoadMore = result.length >= vm.pageSize;
            });
    }

    function loadMore() {
        if (vm.canLoadMore) {
            vm.pageIndex++;
            getCalendar(true);
        }
    }

    return vm;
}