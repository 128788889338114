﻿module.exports = ['$location', 'searchDataService', searchControlDirective];

function searchControlDirective($location, searchData) {
    var directive = {
        bindToController: true,
        controller: searchControlController,
        controllerAs: 'ctrlSearchControl',
        restrict: 'E',
        templateUrl: 'templates/search/directives/search-control.html'
    };

    return directive;

    function searchControlController() {
        var vm = this;

        vm.autoComplete = null;
        vm.searchText = null;

        vm.find = function (searchText) {
            if (searchText === null || searchText === '' || searchText.lenght < 3)
                return null;

            return searchData
                    .find(searchText)
                    .then(function (result) {
                        vm.autoComplete = result;
                    });
        }

        vm.onSearchKeyWordSelected = function (searchResult) {
            vm.autoComplete = null;
            $location.path("/" + searchResult.type + "/" + searchResult.id);
        }

        vm.onSearchButtonClicked = function () {
            vm.autoComplete = null;
            if (vm.searchText !== null && vm.searchText !== undefined && vm.searchText != '') {
                $location.path("/search/" + vm.searchText);
                vm.searchText = null;
            }
        }
    }

};