﻿module.exports = ['common', hasPermissionDirective];

function hasPermissionDirective(common) {
    return {
        link: function (scope, element, attrs) {
            var show = false;
            if (attrs && attrs.hasPermission) {
                show = common.identity.hasPermission(attrs.hasPermission);
            }
            
            element.removeClass('ng-hide');
            if (!show)
                element.addClass('ng-hide');
        }
    };
};
