﻿module.exports = {
    'ok': 'OK',
    'login': 'Log In',
    'logout': 'Log out',
    'myprofile': 'My Profile',
    'password': 'Password',
    'email': 'Email Address',
    'phone':'Phone number',
    'companyName': 'Company Name',
    'displayName':'Display Name',
    'confirmPassword': 'Confirm Password',
    'planning': 'Planning:',
    'registerAccount': 'Register an Account',
    'register': 'Register',
    'change': 'Change',
    'save': 'Save',
    'saveChanges': 'Save Changes',
    'edit': 'Edit',
    'all': 'All',
    'sent': 'Sent',
    'notSent': 'Not Sent',
    'arrivalDate': 'Arrival Date',
    'depDate': 'Departure Date',
    'details': 'Details',
    'searchfail': 'No Results found!',
    'search': 'My search results',
    'searchbox': 'Search ...',
    'role': 'Role',
    'reservations': 'Reservations',
    'fleet': 'Fleet',
    'users': 'Users',
    'sure': 'Are you sure?',
    'cancel': 'Cancel',
    'messages':'Messages',
    'language': 'EN',
    'cancelled': 'cancelled',
    'cancelledByAuthority': 'Cancelled by Port Authority',
    'confirm': 'Confirm',
    'endsOn': 'Ends on',
    'startsOn': 'Starts on',
    'rejected': 'rejected',
    'value': 'Value'
};