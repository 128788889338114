﻿module.exports = ['$translate', 'constantsService', fleetModels];

function fleetModels($translate, constantsService) {

    var service = {
        validateVessel: validateVessel,
        vesselModel: vesselModel,
        shipPropertyModel: shipPropertyModel
    };
    load();
    return service;

    function load() {
    }

    function validateVessel(vessel) {

        var errors = [];
        if (vessel === null || vessel === undefined) {
            errors.push('views.fleet.fleetmodels.errors.vesselselect');
            return errors;
        }

        if (vessel.name === null || vessel.name === undefined || vessel.name === '')
            errors.push('views.fleet.fleetmodels.errors.vesselname');
        else if (vessel.name.length >= 50)
            errors.push('views.fleet.fleetmodels.errors.veselnamelength');

        // draught
        if (vessel.maxDraught !== undefined && vessel.maxDraught !== null && vessel.maxDraught !== '') {
            if (isNaN(vessel.maxDraught))
                errors.push('views.fleet.fleetmodels.errors.maxdraughtformat');
            else if (vessel.maxDraught <= 0)
                errors.push('views.fleet.fleetmodels.errors.vesseldraught');
        }

        var config = null;
        var shipPropertyTypes = [];
        if (vessel.shipTypeId === null || vessel.shipTypeId === undefined || vessel.shipTypeId === '')
            errors.push('views.fleet.fleetmodels.errors.vesseltype');
        else {
            config = constantsService.shipTypeConfigs[vessel.shipTypeId];
            shipPropertyTypes= _.filter(constantsService.shipPropertyTypes, function (propType) {
                return _.some(propType.shipTypes, function (shipType) { return shipType.id === vessel.shipTypeId; });
            });
        }

        // pax
        if (vessel.paxCapacity !== undefined && vessel.paxCapacity !== null && vessel.paxCapacity !== '') {
            if (isNaN(vessel.paxCapacity) || vessel.paxCapacity < 0)
                errors.push('views.fleet.fleetmodels.errors.paxCapacityFormat'); // must a number and greater than zero
        }
        
        // height
        if (vessel.height !== undefined && vessel.height !== null && vessel.height !== '') {
            if (isNaN(vessel.height))
                errors.push('views.fleet.fleetmodels.errors.heightformat');
            else if (vessel.height <= 0)
                errors.push('views.fleet.fleetmodels.errors.vesselheight');
        }

        // length
        if (vessel.length !== undefined
            && vessel.length !== null
            && vessel.length !== ''
            && !_.some(vessel.shipProperties, function (property) { return property.property === 1; })) {
            if (vessel.shipProperties === undefined || vessel.shipProperties === null) {
                vessel.shipProperties = [];
            }
            var length = shipPropertyModel();
            length.value = vessel.length;
            length.property = 1;
            vessel.shipProperties.push(length);
        }

        // width
        if (vessel.width !== undefined
            && vessel.width !== null
            && vessel.width !== ''
            && !_.some(vessel.shipProperties, function (property) { return property.property === 2; })) {
            if (vessel.shipProperties === undefined || vessel.shipProperties === null) {
                vessel.shipProperties = [];
            }
            var width = shipPropertyModel();
            width.value = vessel.width;
            width.property = 2;
            vessel.shipProperties.push(width);
        }

        // ship properties: length, width, greenaward etc
        _.each(shipPropertyTypes, function (propertyType) {

            var properties = null;
            if (vessel.shipProperties !== undefined && vessel.shipProperties !== null && vessel.shipProperties.length > 0) {
                properties = _.filter(vessel.shipProperties, function (property) {
                    return property.property === propertyType.id;
                });
            }

            if (properties === undefined || properties === null || properties.length === 0) {
                errors.push('views.fleet.fleetmodels.errors.vessel' + propertyType.systemCode);
            } else {
                _.each(properties, function (property) {
                    if (property.startsOn === undefined || property.startsOn === null) {
                        errors.push('Please specify a (valid) ' + propertyType.name + ' starts on.');
                    }

                    if (propertyType.systemCode === 'length' || propertyType.systemCode === 'width') {
                        if (isNaN(property.value))
                            errors.push('views.fleet.fleetmodels.errors.' + propertyType.systemCode + 'format');
                        else if (property.value <= 0)
                            errors.push('views.fleet.fleetmodels.errors.vessel' + propertyType.systemCode);
                    } else {
                        if (property.value === undefined || property.value === null || property.value.trim().length === 0) {
                            errors.push('views.fleet.fleetmodels.errors.vesselvalue' + propertyType.systemCode);
                        }

                        if (property.endsOn === undefined || property.endsOn === null) {
                            errors.push('Please specify a (valid) ' + propertyType.name + ' ends on.');
                        } else {
                            if (moment(property.startsOn) > moment(property.endsOn)) {
                                errors.push('Vessel ' + propertyType.name + ' starts on cannot later than ends on.');
                            }
                        }
                    }
                });
            }
        });


        if (config) {
            if (Array.isArray(config.req)) {

                // PAX
                if (config.req.indexOf("pax") > -1) {
                    if (vessel.paxCapacity === undefined || vessel.paxCapacity === null || vessel.paxCapacity === '')
                        errors.push('views.fleet.fleetmodels.errors.paxCapacity'); // e.g. required for rivercruise and seacruise
                }

                // IMO
                if (config.req.indexOf("imo") > -1) {
                    if (vessel.imo === null || vessel.imo === undefined || vessel.imo === '' || isNaN(vessel.imo))
                        errors.push('views.fleet.fleetmodels.errors.imonumberformat');
                    else if (vessel.imo.length !== 7)
                        errors.push('views.fleet.fleetmodels.errors.imonumberlength');
                }

                // ENI
                if (config.req.indexOf("eni") > -1) {
                    if (vessel.eni === null || vessel.eni === undefined || vessel.eni === '' || isNaN(vessel.eni))
                        errors.push('views.fleet.fleetmodels.errors.eninumberformat');
                    else if (vessel.eni.length !== 8)
                        errors.push('views.fleet.fleetmodels.errors.eninumberlength');
                }
            }
        }
        
        return errors.length > 0 ? errors : true;
    }

    function vesselModel() {
        return {
            id: null,
            contstructionType: null,
            customerId: null,
            name: null,
            shipTypeId: 1,
            height: null,
            length: null,
            width: null,
            paxCapacity: null,
            shipProperties: null
        };
    }

    function shipPropertyModel(source) {
        if (!source) {
            var today = new Date().toISOString().split('T')[0];

            return {
                id: null,
                shipId: null,
                property: null,
                value: null,
                startsOn: moment.utc(today, 'YYYY-MM-DD'),
                endsOn: null,
                createdByUserId: null,
                createdOn: null,
                mode: 'inline', // only for UI; readonly, inline, popup
                allowToShow: true, // only for UI
                isSingleValue: null, // only for UI
                name: null // only for UI
            };
        }

        return {
            id: source.id,
            shipId: source.shipId,
            property: source.property,
            value: source.value,
            startsOn: source.startsOn,
            endsOn: source.endsOn,
            createdByUserId: source.createdByUserId,
            createdOn: source.createdOn,
            mode: source.mode, // only for UI
            allowToShow: source.allowToShow, // only for UI
            isSingleValue: source.isSingleValue, // only for UI
            name: source.name // only for UI
        };
    }
}