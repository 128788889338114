﻿module.exports = [ 'userAccountService','reservationWizardGenericService', genericReservationSelectCustomer];

function genericReservationSelectCustomer(userData,reservationWizard) {
    var directive = {
        bindToController: true,
        controller: genericReservationSelectCustomerController,
        controllerAs: 'ctrlGenericReservationSelectCustomer',
        restrict: 'E',
        scope: {
            shipTypeIds: '='
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-select-customer.html'
    };

    return directive;

    function genericReservationSelectCustomerController() {

        var vm = {
            customers:null,
            init:init,
            wizard:reservationWizard,
            isRequired:isRequired
        };
    
        function init() {
            loadCustomers();
        }
        
        function loadCustomers() {
            userData.getCustomerDebtor([3])
                .then(
                    function (result) {
                        vm.customers = [];
                        if (result !== undefined && result !== null && result.length > 0) {
                            vm.customers = result;
                        }

                        if (vm.wizard.reservation.selectedVessel !== undefined &&
                            vm.wizard.reservation.selectedVessel !== null &&
                            vm.wizard.reservation.selectedVessel.id !== undefined &&
                            vm.wizard.reservation.selectedVessel.id !== null) {

                            fleetData.getDebtorNumberByShipId(vm.wizard.reservation.selectedVessel.id)
                                .then(
                                    function(result) {
                                        if (result && result.length > 0) {
                                            vm.wizard.reservation.customer = _.find(vm.customers, function(customer) { return customer.debtorNumber === result });
                                        }
                                    },
                                    function() {
                                    });
                        }
                    }, function () {
                    });
        }

        function isRequired(){
            return reservationWizard.isRequired('customer');
        }

        return vm;
    }
}