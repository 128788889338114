﻿module.exports = ['addressPreferenceDataServices', 'constantsService','identity','addressPreferenceModelsService', addressPreferencesOverviewDirective];

function addressPreferencesOverviewDirective(addressPreferenceDataServices, constantsService, identity, addressPreferenceModelsService) {
    var directive = {
        bindToController: true,
        controller: addressPreferencesOverviewController,
        controllerAs: 'ctrlAddressPreferencesOverview',
        restrict: 'E',
        scope: {
            addressPreferencesData: '=',
            shipsList: '=',
            activeAddressPreference: '='
        },
        templateUrl: 'templates/financial/directives/address-preferences-overview.html'
    };

    return directive;

    function addressPreferencesOverviewController() {
        var vm = {
            setActiveAddressPreference: setActiveAddressPreference,
            selectedLink: selectedLink,
            currentlySelected: false,
            getObjectLabel:getObjectLabel,
            getTargetLabel:getTargetLabel,
            getAdressTypeLabel:getAdressTypeLabel,
            getRequestedLabel:getRequestedLabel,
            getAddressValueLabel:getAddressValueLabel
        };
        
        return vm;

        function setActiveAddressPreference(addressPreference) {
            //if new clicked!!!
            if (!addressPreference) {
                addressPreference = new addressPreferenceModelsService.addressPreferenceModel();
            }
            vm.activeAddressPreference = addressPreference;
        }

        function selectedLink(id) {
            if (vm.activeAddressPreference) {
                if (parseInt(id) === parseInt(vm.activeAddressPreference.id)) {
                    vm.currentlySelected = true;
                    return vm.currentlySelected;
                }
            }
        }

        function getObjectLabel(type,id){
            if(type == 0)
                return "Customer";
            if(type == 1){
                var shiptext = '';
                if(vm.shipsList!==null &&vm.shipsList.length!==0){
                    shiptext = _.filter(vm.shipsList, function(x){ return x.id == id})[0].name;
                }
                return "Ship: " + shiptext;
            }
            return "";
        }

        function getRequestedLabel(value){
            if(value.indexOf('TEMPF')!==-1){
                return "Requested";
            }
            else
            {
                return "Completed";
            }
        }

        function getAddressValueLabel(addressPreference) {

            var value = addressPreference.addressValue;
            if (value.indexOf('TEMPF') !== -1) {
                return value.replace('TEMPF:',"Temp ");
            }
            else
            {
                return addressPreference.displayName + " - " + addressPreference.addressValue;
            }
        }
        
        function getTargetLabel(type, id) {
            if (type == 0)
                return "Default";
            if (type == 1) {
                var flowtext = '';
                if (identity.userInfo.invoiceTypes !== null && identity.userInfo.invoiceTypes.length !== 0) {
                    flowtext = _.filter(identity.userInfo.invoiceTypes, function (x) { return x.id === id; })[0].name;
                }
                return "Invoice: " + flowtext;
            }
            if (type == 1) {
                var flowtext = '';
                if (constantsService.shipTypes !== null && constantsService.shipTypes.length !== 0) {
                    flowtext = _.filter(constantsService.shipTypes, function (x) { return x.id === id; })[0].name;
                }
                return "Ship type: " + flowtext;
            }
            return "";
        }

        function getAdressTypeLabel(type){
            if(type == 0){
                return "Email";
            }
            if(type == 1){
                return "Debitor Number";
            }
            return "";
        }
    }
}