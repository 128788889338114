﻿module.exports = ['$translate', 'addressPreferenceDataServices', 'addressPreferenceModelsService', 'common', 'identity', 'fleetDataService', 'fleetModelService', 'reservationDataService', reservationWizardSeacruiseService];

function reservationWizardSeacruiseService($translate, addressPreferenceDataServices, addressPreferenceModelsService, common, identity, fleetData, fleetModels, reservationData) {

    var service = {
        clear: clear,
        currentStep: 1,
        getFacilitiesDescription: getFacilitiesDescription,
        getVesselType: getVesselType,
        reservation: {
            reservationItems: [],
            eta: null,// new Date(2017, 8, 1, 12, 0),
            etd: null,// new Date(2017, 8, 4, 8, 0),
            newVessel: null,
            selectedVessel: null,
            templateVessel: null,
            customer: null,
            invoiceAddress: null
        },
        save: save,
        validation: {
            step1: {
                errors: [],
                validate: validateStep1
            },
            step2: {
                errors: [],
                validate: validateStep2
            }
        }
    };
    load();
    return service;

    function load() {
    }

    function getFacilitiesDescription(facilities) {
        _.reduce(facilities, function (curItem, nextItem) { return curItem + ', ' + nextItem; }, '');
    }

    function clear() {
        service.currentStep = 1;
        service.reservation = {
            reservationItems: [],
            eta: null,
            etd: null,
            selectedVessel: null
        };
        service.validation.step1.errors = [];
        service.validation.step2.errors = [];
    }

    function getVesselType() {
        return 3;
    }

    function saveReservationAddressPreference(reservationId, addressType, addressValue, displayName) {
        var newModel = addressPreferenceModelsService.addressPreferenceModel();
        newModel.objectType = addressPreferenceDataServices.objectType.reservation.id;
        newModel.objectId = reservationId;
        newModel.targetType = addressPreferenceDataServices.targetType.invoiceTypes.id;
        newModel.targetId = _.find(identity.userInfo.invoiceTypes, function (invoiceType) { return invoiceType.systemCode === 'TOURIST_TAX_SC'}).id; // tourist tax
        newModel.addressType = addressType;
        newModel.addressValue = addressValue;
        newModel.displayName = displayName;

        return addressPreferenceDataServices.addAddressPreference(newModel);
    }

    function save() {
        var deferred = common.$q.defer();
        if (!validateStep2())
            deferred.reject(error);

        var reservation = service.reservation;
        if (reservation.activities && reservation.activities.length > 0) {
            reservation.reservationItems = _.map(reservation.activities, function (item) {

                if (!item.endsOn)
                    item.endsOn = moment(item.startsOn).add(1, 'hours').toDate();
                var reservationItemId = item.id ? item.id : null;

                if (item.facilities && item.facilities.length > 0)
                    item.facilities = _.map(item.facilities, function (facility) {
                        return { facilityId: facility.id, reservationItemId: reservationItemId };
                    });

                item.berthGroup = reservation.berthGroup;

                return item;
            });
        }
        else {
            // Manually add one to accomodate the berth selection
            reservation.reservationItems = [{
                berthGroup: reservation.berthGroup,
                endsOn: reservation.etd,
                startsOn: reservation.eta
            }];
        }

        // when reservation.id is set, it is edit
        if (reservation.id !== undefined && reservation.id !== null) {

            fleetData.saveShip(reservation.selectedVessel)
                .then(function (vessel) {
                    reservation.length = vessel.length;
                    reservation.shipId = vessel.id;

                    var req = reservationData.addOrUpdateReservation(reservation)
                        .then(function (data) {
                            // save email address of agent/customer, for sailing report notification
                            saveReservationAddressPreference(data.id, addressPreferenceDataServices.addressType.email.id, reservation.customer.emailAddress, reservation.customer.name)
                                .then(function () {
                                    // invoice address is not required, when no invoice address is chosen from the UI, use the debtornumber of the agent
                                    var debtorNumber = reservation.invoiceAddress === undefined || reservation.invoiceAddress === null ? reservation.customer.debtorNumber : reservation.invoiceAddress.addressValue;
                                    var displayName = reservation.invoiceAddress === undefined || reservation.invoiceAddress === null ? reservation.customer.name : reservation.invoiceAddress.displayName;
                                    saveReservationAddressPreference(data.id, addressPreferenceDataServices.addressType.debtorNumber.id, debtorNumber, displayName)
                                        .then(function () {
                                            clear();
                                            deferred.resolve(data);
                                        },
                                        requestError);
                                },
                                requestError);
                        },
                        requestError);
                }, requestError);

        } else {
            var newShip = null;
            if (reservation.newVessel !== undefined && reservation.newVessel !== null) {
                newShip = reservation.newVessel;
            } else if (reservation.selectedVessel !== undefined && reservation.selectedVessel !== null) {
                newShip = reservation.selectedVessel;
            }

            if (newShip !== undefined && newShip !== null && reservation.customer) {
                newShip.id = null; // ensure the id is empty; object possible copy from template ship
                fleetData.addShip(newShip)
                    .then(function (vessel) {
                        reservation.length = vessel.length;
                        reservation.shipId = vessel.id;

                        var req = reservationData.addOrUpdateReservation(reservation)
                            .then(function (data) {
                                // save email address of agent/customer, for sailing report notification
                                saveReservationAddressPreference(data.id, addressPreferenceDataServices.addressType.email.id, reservation.customer.emailAddress, reservation.customer.name)
                                    .then(function () {
                                        // invoice address is not required, when no invoice address is chosen from the UI, use the debtornumber of the agent
                                        var debtorNumber = reservation.invoiceAddress === undefined || reservation.invoiceAddress === null ? reservation.customer.debtorNumber : reservation.invoiceAddress.addressValue;
                                        var displayName = reservation.invoiceAddress === undefined || reservation.invoiceAddress === null ? reservation.customer.name : reservation.invoiceAddress.displayName;
                                        saveReservationAddressPreference(data.id, addressPreferenceDataServices.addressType.debtorNumber.id, debtorNumber, displayName)
                                            .then(function () {
                                                clear();
                                                deferred.resolve(data);
                                            },
                                            requestError);
                                    },
                                    requestError);
                            },
                            requestError);
                    }, requestError);
            }
        }

        function requestError(error) {
            deferred.reject(error);
        }

        return deferred.promise;
    }

    function validateStep1(addNewVessel) {
        service.validation.step1.errors = [];
        var validEta, validEtd = false;
        let lastYear = moment().subtract(1, 'year');
        let endOfTheYear = moment().endOf('year');
        let commingYear = moment(moment(endOfTheYear).add(1, 'year'));


        if (!addNewVessel && (service.reservation.selectedVessel === null || service.reservation.selectedVessel === undefined))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.vesselselect');
        else if (addNewVessel) {
            var resultValidateVessel = fleetModels.validateVessel(service.reservation.newVessel);
            if (resultValidateVessel !== true) {
                for (var i = 0; i < resultValidateVessel.length; i++) {
                    service.validation.step1.errors.push(resultValidateVessel[i]);
                }
            }
        }

        if (service.reservation.customer === undefined || service.reservation.customer === null) {
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.nocustomer');
        }

        if (service.reservation.eta === null || service.reservation.eta === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivaldate');
        else
            validEta = true;

        if (moment(service.reservation.eta).isBefore(lastYear))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooOld');
        else validEta = true;

        if (moment(service.reservation.eta).isAfter(commingYear))
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.tooFar');
        else validEta = true;

        if (service.reservation.etd === null || service.reservation.etd === undefined)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.departuredate');
        else
            validEtd = true;

        if (validEta && validEtd && service.reservation.etd < service.reservation.eta)
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.arrivalbeforedeparturedate');

        /* Added to accomodate the new reservation screen */
        if (!service.reservation.berthGroup || service.reservation.berthGroup === '')
            service.validation.step1.errors.push('views.reservations.reservationwizzard.errors.noberth');

        return service.validation.step1.errors.length === 0;
    }

    function validateStep2() {
        service.validation.step2.errors = [];
        return service.validation.step2.errors.length === 0;
    }

}
