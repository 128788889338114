﻿'use strict';

module.exports = ['common', 'dataContext', 'userModelsService', userDataService];

function userDataService(common, context, userModels) {

    var service = {
        addUser: addUser,
        deleteUser: deleteUser,
        getRoles: getRoles,
        getUsers: getUsers,
        getUser : getUser,
        saveUser: saveUser
    };

    return service;

    function addUser(user) {
        var deferred = common.$q.defer();
        user.email = user.emailAddress;
        /*
        if (user.roleId !== undefined && user.roleId !== null)
            user.roles = [{ businessUnit: 0, roles: [{ id: user.roleId }] }];
        */
        if (user.roles[0] && user.roles[0].roles && !user.roles[0].businessUnit)
            user.roles[0].businessUnit = 0;

        var req = context.post('/user/', user)
            .then(function (data) {
                if (user.roles && user.roles[0]) {
                    data.roles = user.roles;
                    saveUser(data)
                        .then(function (update) {
                            deferred.resolve(update);
                        }, function (updateError) {
                            deferred.reject(updateError);
                        });
                }
                else
                    deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteUser(user) {
        var deferred = common.$q.defer();
        var req = context.del('/user/' + user.id, user)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getRoles() {
        var deferred = common.$q.defer();
        var req = context.get('/role')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUser(id) {
        var deferred = common.$q.defer();
        var req = context.get('/user/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getUsers() {
        var deferred = common.$q.defer();
        var req = context.get('/user')
            .then(function (data) {
                deferred.resolve(_.map(data, userModels.userModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveUser(user) {
        /*
        if (user.roleId !== undefined && user.roleId !== null)
            user.roles = [{ businessUnit: 0, roles: user.userRoles }];
            */

        if (user.roles[0] && user.roles[0].roles && !user.roles[0].businessUnit)
            user.roles[0].businessUnit = 0;

        var deferred = common.$q.defer();
        var req = context.put('/user/' + user.id, user)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

};