﻿module.exports = ['$translate', 'common', 'reservationDataService', 'reservationWizardGenericService', 'constantsService', genericReservationAddActivities];

function genericReservationAddActivities($translate, common, reservationData, reservationWizard, constantsService) {
    var directive = {
        bindToController: true,
        controller: genericReservationAddActivitiesController,
        controllerAs: 'ctrlGenericReservationAddActivities',
        restrict: 'E',
        scope: {
        },
        templateUrl: 'templates/reservations-generic/directives/generic-reservation-add-activities.html'
    };

    return directive;

    function genericReservationAddActivitiesController() {

        var vm = {
            init:init,
            wizard:reservationWizard,
            addActivity: addActivity,
            berthGroups: [],
            common: common,
            commercialGoals: [],
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yyyy',
                    'd-M-yyyy H:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yyyy',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            },
            errors: [],
            facilities: [],
            planning: {
                days: [],
                items: []
            },
            nauticalGoals: [],
            newActivity: null,
            deleteActivity: deleteActivity,
            reservation: reservationWizard.reservation,
            sending: false,
            etaChanged: etaChanged
        };
    
        function init() {
            var vesselType = reservationWizard.getVesselType();

            reservationData.getBerthGroupsByShipType(vesselType)
                .then(function (result) {
                    vm.berthGroups = result;
                });
            reservationData.getCommercialGoals()
                .then(function (result) {
                    vm.commercialGoals = result;
                });
            reservationData.getFacilities()
                .then(function (result) {
                    vm.facilities = result;
                });
            reservationData.getNauticalGoals()
                .then(function (result) {
                    vm.nauticalGoals = result;
                });

            clearNewActivity();
            load();
        };

        function load() {
            $translate([
                'views.reservations.reservationwizzard.errors.activitytype',
                'views.reservations.reservationwizzard.errors.validdate',
                'views.reservations.reservationwizzard.errors.validenddate',
                'views.reservations.reservationwizzard.errors.noberth',
            ]).then(function (translations) { vm.translations = translations; });
        };
        
        function addActivity() {
            vm.errors = [];
            if (!vm.newActivity.nauticalGoal)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.activitytype']);
            if (!vm.newActivity.startsOn)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.eta).diff(vm.newActivity.startsOn, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.etd).diff(vm.newActivity.endsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);
            if (moment(vm.newActivity.startsOn).diff(vm.reservation.etd, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.newActivity.endsOn).diff(vm.newActivity.startsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);

            if (vm.errors.length > 0)
                return;

            if (!vm.wizard.reservation.activities)
                vm.wizard.reservation.activities = [];

            if (vm.newActivity.endsOn === undefined || vm.newActivity.endsOn === null || vm.newActivity.endsOn === '') {
                vm.newActivity.endsOn = vm.reservation.etd;
            }
            vm.wizard.reservation.activities.push(vm.newActivity);
            vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            clearNewActivity();
        };

        function clearNewActivity() {
            vm.newActivity = {
                berthGroup: null,
                commercialGoal: null,
                endsOn: null,
                nauticalGoal: null,
                startsOn: null
            };

            if (!reservationWizard.reservation.activities || reservationWizard.reservation.activities.length === 0)
                vm.newActivity.startsOn = reservationWizard.reservation.eta;
            else if (reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn)
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn;
            else
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].startsOn;

            if (reservationWizard.reservation.berthGroup)
                vm.newActivity.berthGroup = reservationWizard.reservation.berthGroup;
        };
        
        function deleteActivity(activity) {
            var index = _.findIndex(vm.wizard.reservation.activities, activity);
            if (index >= 0) {
                vm.wizard.reservation.activities.splice(index, 1);
                vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            }
        };
        
        function etaChanged(newEta) {
            if (newEta === undefined)
                return;

            var shipType = reservationWizard.getVesselType();
            var config = constantsService.shipTypeConfigs[shipType];

            if (vm.newActivity.startsOn >= vm.newActivity.endsOn) {
                var atd = config && config.resItemAtdMin && config.resItemAtdMin > 0 ? config.resItemAtdMin : 0;
                vm.newActivity.endsOn = moment(vm.newActivity.startsOn).add(atd, 'minutes').toDate();
            }
        };

        return vm;
    }
}