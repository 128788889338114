﻿module.exports = ['common', 'dataContext', fleetDataService];

function fleetDataService(common, context) {

    var service = {
        addShip: addShip,
        deleteShip: deleteShip,
        getDebtorNumberByShipId: getDebtorNumberByShipId,
        getFleet: getFleet,
        getShipIssueList: getShipIssueList,
        getFleetById: getFleetById,
        getExternalFleetsBySearch: getExternalFleetsBySearch,
        getShipTypes: getShipTypes,
        saveShip: saveShip
    };

    return service;

    function addShip(ship) {
        var deferred = common.$q.defer();
        var req = context.post('/fleet', ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function deleteShip(ship) {
        var deferred = common.$q.defer();
        var req = context.del('/fleet/' + ship.id, ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getDebtorNumberByShipId(shipId) {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/debtornumber/' + shipId)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getFleet(shipTypes) {
        var deferred = common.$q.defer();
        var url = '/fleet';
        if (shipTypes && shipTypes.length > 0)
            url += '?shiptypes=' + shipTypes.join(',');

        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getShipIssueList() {
        var deferred = common.$q.defer();
        var url = '/fleet/ship-issue-list';
        var req = context.get(url)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }

    function getFleetById(id) {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/' + id)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getShipTypes() {
        var deferred = common.$q.defer();
        var req = context.get('/fleet/shiptype')
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveShip(ship) {
        var deferred = common.$q.defer();
        var req = context.put('/fleet/' + ship.id, ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function saveShipProperties(shipproperties) {
        var deferred = common.$q.defer();
        var req = context.put('/fleet/' + ship.id, ship)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getExternalFleetsBySearch(fieldName, searchQuery, shipType) {
        var deferred = common.$q.defer();

        var url = '/fleet/search/external/' + fieldName;

        var args = [];
        args.push('searchQuery=' + searchQuery);

        if (shipType && shipType > 0)
            args.push('shipType=' + shipType);

        var req = context.get(url + '?' + args.join('&'))
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
};