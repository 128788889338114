﻿module.exports = ['common', 'constantsService', 'fleetModelService', 'fleetModals', shipFormDirective];

function shipFormDirective(common, constants, fleetModels, fleetModals) {
    var directive = {
        bindToController: true,
        controller: shipFormController,
        controllerAs: 'ctrlShipForm',
        restrict: 'E',
        scope: {
            shipData: '=',
            simpleMode: '=',
            allowShiptypes: '@'
        },
        link: function (scope) {
            scope.$watchCollection(function (scope) {
                return scope.ctrlShipForm.shipData;
            },
            function (newValue, oldValue) {
                if (newValue && oldValue && oldValue.id !== newValue.id) {
                    scope.ctrlShipForm.setShipTypeConfig();
                    //scope.ctrlShipForm.initConnectorType();
                }
            });    
        },
        templateUrl: 'templates/fleet/directives/ship-form.html'
    };

    return directive;

    function shipFormController() {
        var ctrlShipForm = this;
        ctrlShipForm.constants = constants;
        var vm = {
            constants: constants,
            datePickerOptions: {
                altInputFormats: [
                    'd-M-yyyy',
                    'd-M-yyyy H:mm',
                    'd-M-yyyy HH:mm',
                    'dd-MM-yyyy',
                    'dd-MM-yyyy H:mm',
                    'dd-MM-yyyy HH:mm'
                ]
            },
            editShipProperties: editShipProperties,
            shipData: this.shipData,
            simpleMode: this.simpleMode,
            shipTypes: [],
            shipPropertyTypes: [],
            init: init,
            onSelectConnectorType:onSelectConnectorType,
            connectorType:-1,
            initConnectorType: initConnectorType,
            setShipTypeConfig: setShipTypeConfig,
            shipTypeConfig: null
        };
        return vm;
        function init() {
            //only select for party boat or river cruise 
            vm.constants.preloaded.then(function () {
                if (vm.allowShiptypes === undefined || vm.allowShiptypes === null || vm.allowShiptypes === "") {
                    vm.shipTypes = vm.constants.shipTypes;
                } else {
                    var allow = _.map(vm.allowShiptypes.split(","), function (num) { return parseInt(num); });
                    vm.shipTypes = _.filter(vm.constants.shipTypes, function(type) { return allow.indexOf(type.id) >= 0; });
                }

                vm.shipPropertyTypes = _.indexBy(vm.constants.shipPropertyTypes, 'id');
                vm.setShipTypeConfig();
            });
            vm.initConnectorType();
        }

        function initConnectorType(){
            if (!vm.shipData || vm.shipData.connectorQuantity === null && vm.shipData.connectorAmpere === null)
                vm.connectorType = -1;
            else if(vm.shipData.connectorQuantity === 1 && vm.shipData.connectorAmpere === 250)
                vm.connectorType = 1;
            else if(vm.shipData.connectorQuantity === 2 && vm.shipData.connectorAmpere === 125)
                vm.connectorType = 2;
            else if(vm.shipData.connectorQuantity === 5 && vm.shipData.connectorAmpere === 80)
                vm.connectorType = 3;
            else
                vm.connectorType = 0;
        }

        
        function onSelectConnectorType() {
            switch(vm.connectorType){
                case 0:
                    vm.shipData.connectorQuantity = 0;
                    vm.shipData.connectorAmpere = 0;
                    break;
                case 1:
                    vm.shipData.connectorQuantity = 1;
                    vm.shipData.connectorAmpere = 250;
                    break;
                case 2:
                    vm.shipData.connectorQuantity = 2;
                    vm.shipData.connectorAmpere = 125;
                    break;
                case 3:
                    vm.shipData.connectorQuantity = 5;
                    vm.shipData.connectorAmpere = 80;
                    break;
                default:
                    vm.shipData.connectorQuantity = null;
                    vm.shipData.connectorAmpere = null;
                    vm.shipData.connectorLocation = null;
                    break;
            }
        }

        function setShipTypeConfig() {
            if (vm.shipData && vm.shipData.shipTypeId) {
                vm.shipTypeConfig = vm.constants.shipTypeConfigs[vm.shipData.shipTypeId];

                // handle ship property types, add new ship properties when it needs
                var properties = [];
                _.each(vm.shipPropertyTypes, function (propType) { // loop through the property types

                    // check if this property type contains this shiptype, otherwise hide this property
                    var hasProperty = _.some(propType.shipTypes, function (shipType) { return shipType.id === vm.shipData.shipTypeId; });
                    if (hasProperty === false) return;

                    var fixValue = null;
                    var hasProp = false;
                    if (!vm.shipData.id && vm.shipTypeConfig && vm.shipTypeConfig.prop && vm.shipTypeConfig.prop[propType.systemCode]) {
                        fixValue = vm.shipTypeConfig.prop[propType.systemCode];
                        vm.shipData.shipProperties = _.filter(vm.shipData.shipProperties, function (p) { return p.property !== propType.id }); // this property has fix value, so remove all existing properties
                    }

                    if (vm.shipData.shipProperties) {
                        existingProp = _.each(vm.shipData.shipProperties, function (shipProp) { // loop through the existing properties
                            if (shipProp.property === propType.id) {
                                hasProp = true;

                                if (!shipProp.id)
                                    shipProp.mode = 'inline'; // reset the mode

                                properties.push(shipProp);
                            }
                        });
                    }

                    if (hasProp === false) {
                        var prop = fleetModels.shipPropertyModel();
                        prop.property = propType.id;
                        prop.isSingleValue = !propType.isDateDependent;
                        prop.name = propType.name;

                        if (propType.defaultValues) {
                            prop.value = propType.defaultValues[0];
                        }

                        // e.g. canal boat fix length and width. not allow to change by client
                        if (fixValue !== null) {
                            prop.value = fixValue;
                            prop.mode = 'readonly';
                        }

                        properties.push(prop);
                    }
                });
                vm.shipData.shipProperties = _.sortBy(_.sortBy(properties, 'startsOn').reverse(), 'property');

                if (vm.shipData.id && vm.shipData.id > 0) {
                    var previousPropTypeId = null;
                    _.each(vm.shipData.shipProperties, function (existing) {

                        if ((existing.endsOn !== undefined && existing.endsOn !== null)
                            || (previousPropTypeId !== existing.property && moment() > moment(existing.startsOn))) { // startson is later than now
                            existing.allowToShow = true;
                        } else {
                            existing.allowToShow = false;
                        }

                        var foundPropType = vm.shipPropertyTypes[existing.property];
                        if (common.identity.hasPermission('ship_admin') && existing.mode !== 'readonly') {
                            var allowInline = existing.id === null && _.filter(vm.shipData.shipProperties, function (p) { return p.property === existing.property; }).length === 1;
                            if (allowInline || existing.value === null || existing.value === undefined || existing.value.trim().length === 0) {
                                existing.mode = 'inline';
                            } else if (foundPropType.allowChangeByClient) {
                                existing.mode = 'popup';
                            } else {
                                existing.mode = 'readonly';
                            }
                        } else {
                            existing.mode = 'readonly';
                        }
                        existing.isSingleValue = existing.value === null || existing.value === undefined || existing.value.trim().length === 0 || !foundPropType.isDateDependent;


                        if (previousPropTypeId === existing.property)
                            existing.name = null;
                        else {
                            previousPropTypeId = existing.property;
                            existing.name = foundPropType.name;
                        }
                    });

                }
            }
        }

        function editShipProperties(shipPropertyTypeId) {
            fleetModals.shipProperties({ shipProperties: vm.shipData.shipProperties, shipPropertyType: vm.shipPropertyTypes[shipPropertyTypeId] })
                .result
                .then(function (response) {
                    if (response !== null) {
                        vm.shipData.shipProperties = response;
                        vm.setShipTypeConfig();
                    }
                });
        }
    }
}
