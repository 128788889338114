﻿angular.module('poa.rprp.users', [])
    .config(['$routeProvider', routeConfig])
    .controller('userOverviewController', require('./controllers/user-overview'))
    .directive('userForm', require('./directives/user-form'))
    .factory('userDataService', require('./services/user-data'))
    .factory('userModelsService', require('./services/user-models'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/users', {
            templateUrl: 'templates/users/controllers/user-overview.html'
        })
        .when('/users/:userId', {
            templateUrl: 'templates/users/controllers/user-overview.html'
        });
};
