﻿module.exports = ['common', reservationReceiptDirective];

function reservationReceiptDirective(common) {

    var directive = {
        bindToController: true,
        controller: reservationReceiptController,
        controllerAs: 'ctrlReservationReceipt',
        restrict: 'E',
        scope: {
            reservationData: '='
        },
        templateUrl: 'templates/reservations/directives/reservation-receipt.html'
    };

    return directive;

    function reservationReceiptController() {
        var ctrlReservationReceipt = this;
    }

};