﻿module.exports = ['common', 'dataContext', 'businessruleModelsService', businessruleDataService];

function businessruleDataService(common, context, businessruleModels) {

    var service = {
        get: getBusinessRules,
        getByBerth: getBerthBusinessRules,
        getByType: getByType
    };

    return service;

    function addBerthBusinessRule(businessrule) {
        var deferred = common.$q.defer();

        var req = context.post('/businessrule/', businessrule)
            .then(function (data) {
                deferred.resolve(data);
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBusinessRules() {
        var deferred = common.$q.defer();
        var req = context.get('/businessrule')
            .then(function (data) {
                deferred.resolve(_.map(data, businessruleModels.businessruleModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getBerthBusinessRules(berthId) {
        var deferred = common.$q.defer();
        var req = context.get('/berth/' + berthId + '/businessrule')
            .then(function (data) {
                deferred.resolve(_.map(data, businessruleModels.businessruleModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }

    function getByType(businessRuleId, dateFrom, dateUntil){
        var deferred = common.$q.defer();
        var req = context.get('/berth/businessrule?type=' + businessRuleId + '&dateFrom=' + dateFrom + '&dateUntil=' + dateUntil)
            .then(function (data) {
                deferred.resolve(_.map(data, businessruleModels.businessruleModel));
            }, function (error) {
                deferred.reject(error);
            });

        return deferred.promise;
    }
}