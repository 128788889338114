﻿module.exports = {
    'userOverview': 'Gebruiker overzicht',
    'addUser': 'Toevoegen',
    'editUser': 'Gebruiker bewerken',
    'changeSucessful': 'Uw account is afgemeld. U wordt doorgestuurd in een moment.',
    'errorSaving': 'Er is een fout opgetreden wijzigingen op te slaan:',
    'saveUser': 'Gebruiker opslaan',
    'deleteUser': 'Gebruiker verwijder',
    'addNewUser': 'Nieuw gebruiker toevoegen',
    'errorAddingUser': 'Er is een fout opgetreden het toevoegen van de gebruiker:',
    'deleteuserquestion': 'U wilt deze gebruiker verwijderen uit uw account?',
    'loadingUsers': 'De gebruikers worden geladen...',
    'noUsers': 'Er zijn nog geen gebruikers toegevoegd...'
};