﻿module.exports = ['$location', 'identity', dashboardController];

function dashboardController($location, identity) {
    var vm = {
    };

    function init() {
        if (identity.userInfo === null)
            $location.path('/account/login');
        else if (identity.hasPermission('nautical_provider'))
            $location.path('/calendar/nautical-provider');
        else
            $location.path('/reservations');
    }

    init();

    return vm;

}