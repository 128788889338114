﻿angular.module('poa.rprp.fleet', [])
    .config(['$routeProvider', routeConfig])
    .controller('modalShipPropertiesController', require('./modals/ship-properties-modal'))
    .controller('fleetController', require('./controllers/fleet'))
    .directive('shipForm', require('./directives/ship-form'))
    .factory('fleetDataService', require('./services/fleet-data'))
    .factory('fleetModelService', require('./services/fleet-models'))
    .factory('vesselDisplayFilter', require('./filters/vessel-display'))
    .factory('fleetModals', require('./modals/modals'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/fleet', {
            templateUrl: 'templates/fleet/controllers/fleet.html'
        })
        .when('/fleet/:shipId', {
            templateUrl: 'templates/fleet/controllers/fleet.html'
        });
};