﻿angular.module('poa.rprp.calendar', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    .controller('calendarController', require('./controllers/calendar'))
    .controller('nauticalCalendarController', require('./controllers/nautical-provider-calendar'))
    .controller('publicBerthCalendarController', require('./controllers/public-berth-calendar'))
    // Directive
    // Services
    .factory('calendarDataService', require('./services/calendar-data'))
;

function routeConfig($routeProvider) {
    $routeProvider
        .when('/calendar', {
            templateUrl: 'templates/calendar/controllers/calendar.html'
        })
        .when('/calendar/nautical-provider', {
            templateUrl: 'templates/calendar/controllers/nautical-provider-calendar.html'
        })
        .when('/calendar/public-berths', {
            templateUrl: 'templates/calendar/controllers/public-berth-calendar.html'
        })
        ;

}