﻿module.exports = ['$location', 'reservationWizardService', 'businessruleDataService', 'constantsService', newReservationController];

function newReservationController($location, reservationWizard, businessruleData, constantsService) {

    var vm = {
        wizard: reservationWizard,
        greenAwardShipTypes:null,
        greenAwardWarning:greenAwardWarning,
        greenAwardVesselLevel:0,
        greenAwardBerthGroupLevel:0
    };

    init();

    function init() {
        vm.wizard.clear();
        vm.greenAwardShipTypes = _.find(constantsService.shipPropertyTypes, function (shipPropertyType) { return shipPropertyType.systemCode.toLowerCase() == 'greenaward'});
    }

    function greenAwardWarning() {
        let warningLevel = (!vm.wizard.reservation.eta && moment().isBefore(moment('2023-01-01'))) || moment(vm.wizard.reservation.eta).isBefore(moment('2023-01-01')) ? 'warning' : 'danger';
        let greenAwardBerthGroupValidation = vm.wizard.greenAwardBerthGroupValidation();

        vm.greenAwardVesselLevel = greenAwardBerthGroupValidation.vesselLevel;
        vm.greenAwardBerthGroupLevel = greenAwardBerthGroupValidation.berthGroupLevel;

        return greenAwardBerthGroupValidation.requiresWarning ? warningLevel : null;
    }

    return vm;
};