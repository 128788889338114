﻿module.exports = [businessruleModels];

function businessruleModels() {

    var service = {
        businessruleModel: businessruleModel
    };

    return service;

    function businessruleModel(model) {
        var result = {
            berthId: null,
            berthName: null,
            businessRuleId: null,
            businessRuleName: null,
            businessRuleParameters: null,
            id:null,
            startsOn: null,
            endsOn: null,
            remarks: null
        };

        if (model === undefined || model === null)
            return result;

        result.berthId = model.berthId;
        result.berthName = model.berthName;
        result.businessRuleId = model.businessRuleId;
        result.businessRuleName = model.businessRuleName;
        result.businessRuleParameters = model.businessRuleParameters;
        result.startsOn = model.startsOn;
        result.endsOn = model.endsOn;
        result.remarks = model.remarks;
        result.id = model.id;
        return result;
    }

}