﻿module.exports = {
    'overview': 'Reservering overzicht',
    'findReservation': 'Vind reservering...',
    'upcoming': 'Aankomende',
    'newReservation': 'Nieuwe reservering',
    'activeReservation': 'U heeft geen actieve reserveringen',
    'makeReservation': 'Maak een reservering:',
    'sendReservation': 'Verzend reservering',
    'eta': 'ETA',
    'berth': 'Ligplaats',
    'facilities': 'Faciliteiten:',
    'etd': 'ETD',
    'cancelReservation': 'Annuleren reservering',
    'berthPlanning': 'Ligplaats',
    'loadingReservations': 'The reservations are being loaded...'
};