﻿'use strict';
module.exports = [activityFacilitiesDisplayFilter];

function activityFacilitiesDisplayFilter() {
    return function (facilities) {

        if (!facilities || facilities.length ===0)
            return null;

        return _.reduce(facilities, function (result, item) {
            var curfacilityName = '';
            if (item !== null && item !== undefined){
                var facility = item;
                if (item.facility !== null && item.facility !== undefined)
                    facility = item.facility;

                if (facility.name !== null && facility.name !== undefined)
                    curfacilityName = facility.name;
            }

            if (result === '' || result === null || result === undefined)
                result = curfacilityName;
            else
                result = result + ',' + curfacilityName;

            return result;

        }, '');
    };
};
