﻿module.exports = {
    'ad': 'Andorra',
    'ae': 'Verenigde Arabische Emiraten',
    'af': 'Afghanistan',
    'ag': 'Antigua en Barbuda',
    'ai': 'Anguilla',
    'al': 'Albanië',
    'am': 'Armenië',
    'an': 'Nederlandse Antillen',
    'ao': 'Angola',
    'aq': 'Antarctica',
    'ar': 'Argentinië',
    'as': 'Amerikaans-Samoa',
    'at': 'Oostenrijk',
    'au': 'Australië',
    'aw': 'Aruba',
    'az': 'Azerbeidzjan',
    'ba': 'Bosnië en Herzegovina',
    'bb': 'Barbados',
    'bd': 'Bangladesh',
    'be': 'België',
    'bf': 'Burkina Faso',
    'bg': 'Bulgarije',
    'bh': 'Bahrein',
    'bi': 'Burundi',
    'bj': 'Benin',
    'bm': 'Bermuda',
    'bn': 'Brunei',
    'bo': 'Bolivia',
    'br': 'Brazilië',
    'bs': 'Bahama\'s',
    'bt': 'Bhutan',
    'bv': 'Bouveteiland',
    'bw': 'Botswana',
    'by': 'Belarus; Wit-Rusland',
    'bz': 'Belize',
    'ca': 'Canada',
    'cc': 'Cocoseilanden',
    'cd': 'Democratische Republiek Congo',
    'cf': 'Centraal-Afrikaanse Republiek',
    'cg': 'Congo',
    'ch': 'Zwitserland',
    'ci': 'Ivoorkust',
    'ck': 'Cookeilanden',
    'cl': 'Chili',
    'cm': 'Kameroen',
    'cn': 'China',
    'co': 'Colombia',
    'cr': 'Costa Rica',
    'cu': 'Cuba',
    'cv': 'Kaapverdië',
    'cx': 'Christmaseiland',
    'cy': 'Cyprus',
    'cz': 'Tsjechië',
    'de': 'Duitsland',
    'dj': 'Djibouti',
    'dk': 'Denemarken',
    'dm': 'Dominica',
    'do': 'Dominicaanse Republiek',
    'dz': 'Algerije',
    'ec': 'Ecuador',
    'ee': 'Estland',
    'eg': 'Egypte',
    'eh': 'Westelijke Sahara',
    'er': 'Eritrea',
    'es': 'Spanje',
    'et': 'Ethiopië',
    'fi': 'Finland',
    'fj': 'Fiji',
    'fk': 'Falklandeilanden',
    'fm': 'Micronesia',
    'fo': 'Faeröer',
    'fr': 'Frankrijk',
    'ga': 'Gabon',
    'gb': 'Verenigd Koninkrijk',
    'gd': 'Grenada',
    'ge': 'Georgië',
    'gf': 'Frans-Guyana',
    'gh': 'Ghana',
    'gi': 'Gibraltar',
    'gl': 'Groenland',
    'gm': 'Gambia',
    'gn': 'Guinee',
    'gp': 'Guadeloupe',
    'gq': 'Equatoriaal-Guinea',
    'gr': 'Griekenland',
    'gs': 'Zuid-Georgië en Zuidelijke Sandwicheilanden',
    'gt': 'Guatemala',
    'gu': 'Guam',
    'gw': 'Guinee-Bissau',
    'gy': 'Guyana',
    'hk': 'Hongkong',
    'hm': 'Heard- en McDonaldeilanden',
    'hn': 'Honduras',
    'hr': 'Kroatië',
    'ht': 'Haïti',
    'hu': 'Hongarije',
    'id': 'Indonesië',
    'ie': 'Ierland',
    'il': 'Israël',
    'in': 'India',
    'io': 'Brits Territorium in de Indische Oceaan',
    'iq': 'Irak',
    'ir': 'Iran',
    'is': 'IJsland',
    'it': 'Italië',
    'jm': 'Jamaica',
    'jo': 'Jordanië',
    'jp': 'Japan',
    'ke': 'Kenia; Kenya',
    'kg': 'Kirgizië; Kirgizstan',
    'kh': 'Cambodja',
    'ki': 'Kiribati',
    'km': 'Comoren',
    'kn': 'Saint Kitts en Nevis',
    'kp': 'Noord-Korea',
    'kr': 'Zuid-Korea',
    'kw': 'Koeweit',
    'ky': 'Caymaneilanden',
    'kz': 'Kazachstan',
    'la': 'Laos',
    'lb': 'Libanon',
    'lc': 'Saint Lucia',
    'li': 'Liechtenstein',
    'lk': 'Sri Lanka',
    'lr': 'Liberia',
    'ls': 'Lesotho',
    'lt': 'Litouwen',
    'lu': 'Luxemburg',
    'lv': 'Letland',
    'ly': 'Libië',
    'ma': 'Marokko',
    'mc': 'Monaco',
    'md': 'Moldavië',
    'mg': 'Madagaskar',
    'mh': 'Marshalleilanden',
    'mk': 'Voormalige Joegoslavische Republiek Macedonië',
    'ml': 'Mali',
    'mm': 'Myanmar',
    'mn': 'Mongolië',
    'mo': 'Macau',
    'mp': 'Noordelijke Marianen',
    'mq': 'Martinique',
    'mr': 'Mauritanië',
    'ms': 'Montserrat',
    'mt': 'Malta',
    'mu': 'Mauritius',
    'mv': 'Maldiven',
    'mw': 'Malawi',
    'mx': 'Mexico',
    'my': 'Maleisië',
    'mz': 'Mozambique',
    'na': 'Namibië',
    'nc': 'Nieuw-Caledonië',
    'ne': 'Niger',
    'nf': 'Norfolkeiland',
    'ng': 'Nigeria',
    'ni': 'Nicaragua',
    'nl': 'Nederland',
    'no': 'Noorwegen',
    'np': 'Nepal',
    'nr': 'Nauru',
    'nu': 'Niue',
    'nz': 'Nieuw-Zeeland',
    'om': 'Oman',
    'pa': 'Panama',
    'pe': 'Peru',
    'pf': 'Frans-Polynesië',
    'pg': 'Papoea-Nieuw-Guinea',
    'ph': 'Filipijnen',
    'pk': 'Pakistan',
    'pl': 'Polen',
    'pm': 'Saint-Pierre en Miquelon',
    'pn': 'Pitcairneilanden',
    'pr': 'Puerto Rico; Porto Rico',
    'pt': 'Portugal',
    'pw': 'Palau',
    'py': 'Paraguay',
    'qa': 'Qatar',
    're': 'Réunion',
    'ro': 'Roemenië',
    'ru': 'Rusland',
    'rw': 'Rwanda',
    'sa': 'Saudi-Arabië; Saoedi-Arabië',
    'sb': 'Salomonseilanden',
    'sc': 'Seychellen',
    'sd': 'Sudan; Soedan',
    'se': 'Zweden',
    'sg': 'Singapore',
    'sh': 'Sint-Helena',
    'si': 'Slovenië',
    'sj': 'Svalbard en Jan Mayen',
    'sk': 'Slowakije; Slovakije',
    'sl': 'Sierra Leone',
    'sm': 'San Marino',
    'sn': 'Senegal',
    'so': 'Somalië',
    'sr': 'Suriname',
    'st': 'Sao Tomé en Principe',
    'sv': 'El Salvador',
    'sy': 'Syrië',
    'sz': 'Swaziland',
    'tc': 'Turks- en Caicoseilanden',
    'td': 'Tsjaad',
    'tf': 'Franse Gebieden in de zuidelijke Indische Oceaan',
    'tg': 'Togo',
    'th': 'Thailand',
    'tj': 'Tadzjikistan',
    'tk': 'Tokelau-eilanden',
    'tl': 'Oost-Timor',
    'tm': 'Turkmenistan',
    'tn': 'Tunesië',
    'to': 'Tonga',
    'tr': 'Turkije',
    'tt': 'Trinidad en Tobago',
    'tv': 'Tuvalu',
    'tw': 'Taiwan',
    'tz': 'Tanzania',
    'ua': 'Oekraïne',
    'ug': 'Uganda; Oeganda',
    'um': 'Amerikaanse ondergeschikte afgelegen eilanden',
    'us': 'Verenigde Staten',
    'uy': 'Uruguay',
    'uz': 'Oezbekistan',
    'va': 'Vaticaanstad',
    'vc': 'Saint Vincent en de Grenadines',
    've': 'Venezuela',
    'vg': 'Britse Maagdeneilanden',
    'vi': 'Amerikaanse Maagdeneilanden',
    'vn': 'Vietnam',
    'vu': 'Vanuatu',
    'wf': 'Wallis en Futuna',
    'ws': 'Samoa',
    'ye': 'Jemen',
    'yt': 'Mayotte',
    'yu': 'Joegoslavië',
    'za': 'Zuid-Afrika',
    'zm': 'Zambia',
    'zw': 'Zimbabwe'
};
