﻿module.exports = ['common', 'fleetDataService', 'reservationDataService', 'reservationDolphinModelsService', 'toastr', '$uibModalInstance', 'modalArguments', extendReservationController];

function extendReservationController(common, fleetData, reservationData, reservationDolphinModels, toastr, $uibModalInstance, args) {

    if (!args)
        args = {};

    var vm = {
        errors: [],
        etdChanged: etdChanged,
        cancel: cancel,
        ok: ok,
        nauticalGoalPriorityLevel: args.nauticalGoalPriorityLevel,
        reservation: args.reservation
    };

    function etdChanged() {
        vm.reservation.reservationItems[0].endsOn = vm.reservation.etd;
        vm.errors = reservationDolphinModels.validateReservation(vm.reservation, vm.nauticalGoalPriorityLevel);
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function ok() {
        vm.isSaving = true;

        var deferredMaster = common.$q.defer();
        deferredMaster.promise.then(function (masterReservation) {

            if (vm.reservation.reservationSlaves && vm.reservation.reservationSlaves.length > 0) {
                var slave = reservationDolphinModels.convertDisplayToApiModel(vm.reservation.reservationSlaves[0]);
                slave.masterReservationId = masterReservation.id;
                slave.eta = masterReservation.eta;
                slave.etd = masterReservation.etd;
                slave.reservationItems[0].startsOn = masterReservation.eta;
                slave.reservationItems[0].endsOn = masterReservation.etd;

                var deferredSlave = common.$q.defer();
                deferredSlave.promise.then(function () {
                    reservationData.lastSaved = masterReservation;
                    onSaveSucceeded(true);
                }, onSaveFailed);

                saveReservationWithShip(deferredSlave,
                    vm.reservation.reservationSlaves[0].ship,
                    slave);
            } else {
                onSaveSucceeded(true);
            }
        },
            onSaveFailed);


        saveReservationWithShip(deferredMaster, vm.reservation.ship, reservationDolphinModels.convertDisplayToApiModel(vm.reservation));
    }

    function saveReservationWithShip(deferred, ship, reservation) {

        // ensure the id is 0
        ship.id = 0;
        reservation.id = 0;

        fleetData.addShip(ship)
            .then(function (vessel) {

                reservation.shipId = vessel.id;
                reservationData.addOrUpdateReservation(reservation)
                    .then(function (data) {
                        deferred.resolve(data);
                    }, function (error) {
                        deferred.reject(error);
                    });
            }, function (error) {
                deferred.reject(error);
            });
    }

    function onSaveFailed(error) {
        toastr.error('Error adding new reservation.');
        vm.isSaving = false;
    }

    function onSaveSucceeded(needToClose) {
        vm.isSaving = false;
        toastr.success('Your reservation has been saved successfully.');
        location.href = '/#!/reservations'; // return to the overview page with reload

        if (needToClose) {
            $uibModalInstance.close();
        }
    }

    return vm;

}