﻿'use strict';

module.exports = ['$location', '$route', 'messageDataService', 'reservationDataService', 'userAccountService', '$routeParams', messageController];

function messageController($location, $route, messageDataService, reservationData, userAccountService, $routeParams) {
    var vm = {
        attached: attached,
        getInbox: getInbox,
        getItem: getItem,
        inbox: [],
        customerInbox: null,
        isLoading: false,
        reservationId: null,
        reservations: [],
        isCustomerMessage: false,
        messages: [],
        customerMessages: [],
        getMessage: getMessage,
        selectedLink: selectedLink,
        currentlySelected: false,
        goBack: goBack,
        shipName: null,
        reservation: null,
        countMessages: countMessages,
        getCustomerMessages: getCustomerMessages,
        customerInfo: null
    };

    /*
    var original = $location.path;
    $location.path = function (path, reload) {
        if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function () {
                $route.current = lastRoute;
                un();
            });
        }
        return original.apply($location, [path]);
    }
    */

    function attached() {
        if (vm.reservations.length === 0) {
            getAccountInfo();
            getInbox();
            getCustomerInbox();
        }
    }

    function getAccountInfo() {
        userAccountService.getUserInfo()
            .then(function (result) {
                vm.customerInfo = result.customer;
            });
    }

    function countMessages(arr) {
        //arr.forEach(function (item) {
        //    reservationData.getMessages(item.reservation.id)
        //        .then(function (messages) {
        //            let messagesCount = messages.length;
        //            let id = item.reservation.id;
        //            vm.inbox.forEach(function (obj) {
        //                if (obj.reservation.id === id) {
        //                    obj.messagesCount = messagesCount;
        //                }
        //            });
        //        });
        //});

        //return arr;
    }
    function countCustomerMessages() {
        //messageDataService.getCustomerMessages()
        //    .then(function (messages) {
        //        vm.customerInbox.messagesCount = messages.length;
        //    });
    }
    function getCustomerMessages() {
        //vm.isCustomerMessage = true;

        //messageDataService.getCustomerMessages()
        //    .then(function (messages) {
        //        vm.customerMessages = messages;
        //    });
    }
    function getInbox() {
        vm.isLoading = true;

        messageDataService.getMessageInbox(50, 0)
            .then(function (results) {
                results = _.map(results, function (result) {
                    result.reservation = null;

                    if (result.objects && result.objects.length > 0) {
                        const reservation = _.find(result.objects, function (object) {
                            return object.objectType === 'reservation';
                        });
                        if (reservation) {
                            result.reservation = reservation.objectValue;
                        }
                    }

                    return result;
                });

                vm.inbox = results;
                vm.isLoading = false;
                countMessages(vm.inbox);
            }, function () {
                vm.isLoading = false;
            });
    }
    function getCustomerInbox() {
        //messageDataService.getCustomerMessageInbox(10, 0)
        //    .then(function (result) {
        //        vm.customerInbox = result;
        //        countCustomerMessages(vm.customerInbox);
        //    }, function () {
        //    });
    }
    function getItem() {
        if (vm.reservationId === null) {
            var id = $routeParams.reservationId || 0;
            if (id === 0) return;

            vm.reservationId = id;
        }

        reservationData.getMessages(vm.reservationId)
            .then(function (results) {
                vm.messages = results;
            });
        $location.path('/messages/' + vm.reservationId, false);
    }
    function getMessage(id, shipName, reservation) {
        vm.isCustomerMessage = false;

        vm.reservationId = id;
        reservationData.getMessages(vm.reservationId)
            .then(function (results) {
                if (results && results.length > 0) {
                    vm.messages = results[0].messages;
                }
            });
        vm.shipName = shipName;
        vm.reservation = reservation;
    }
    function selectedLink(id) {
        if (parseInt(id) === parseInt(vm.reservationId)) {
            vm.currentlySelected = true;
            return vm.currentlySelected;
        }
    }
    function goBack() {
        $location.path('/messages/', false);
    }

    return vm;
};