﻿module.exports = ['common', 'constantsService', hasPermissionForBusinessUnitDirective];

function hasPermissionForBusinessUnitDirective(common, constants) {
    return {
        link: function (scope, element, attrs) {
            var show = false;
            if (attrs && attrs.hasPermissionForBusinessUnit) {
                var jsonString = attrs.hasPermissionForBusinessUnit;
                // Remove the starting and ending '"'
                jsonString = jsonString.replace(/'/g, '"');

                var attrValue = JSON.parse(jsonString.toString());
                for (var property in attrValue) {
                    if (common.identity.hasPermission(property) && _.some(attrValue[property], function (unit) { return constants.hasBusinessUnit(unit); })) {
                        show = true;
                        break;
                    }
                }
            }

            element.removeClass('ng-hide');
            if (!show)
                element.addClass('ng-hide');
        }
    };
};
