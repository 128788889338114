﻿// In the index file we'll reference all scripts in this folder and subfolders and create their respective controllers, factories and directives

// First we'll define a module for this section of the app
angular.module('poa.rprp.core', [])
    .controller('preloader', require('./controllers/preloader'))
// filters
    .filter('languageName', require('./filters/languageName'))
// services.common
    .factory('common', require('./services.common/common'))
    .factory('formatting', require('./services.common/formatting'))
    .factory('identity', require('./services.common/identity'))
    .factory('language', require('./services.common/language'))
// services.data
    .factory('constantsService', require('./services.data/constants'))
    .factory('dataContext', require('./services.data/data-context'))
    .factory('isoCodesService', require('./services.data/iso-codes'))
// directives
    .directive('checklistModel', require('./directives/checklist-model'))
    .directive('convertToDecimal', require('./directives/convert-to-decimal'))
    .directive('convertToNumber', require('./directives/convert-to-number'))
    .directive('hasBusinessUnit', require('./directives/has-business-unit'))
    .directive('hasPermission', require('./directives/has-permission'))
    .directive('hasPermissionForBusinessUnit', require('./directives/has-permission-for-business-unit'))
    .directive('loadingIndicator', require('./directives/loading-indicator'))
    .directive('submitAfterValidation', require('./directives/form-validation'))
;
