'use strict';

module.exports = ['addressPreferenceDataServices','fleetDataService',financialController];

function financialController(addressPreferenceDataServices,fleetDataService) {
    var vm = {
        attached: attached,
        addressPreferences:[],
        shipsList:[],
        activeAddressPreference: null,
        onAddressPreferencesUpdate: onAddressPreferencesUpdate
    };
    return vm;

    function attached() {
        retrieveAddressPreferencesList();
        retrieveShipList();
    }
    
    function retrieveAddressPreferencesList(){
        return addressPreferenceDataServices.getCustomersOnly().then(function(result){
            vm.addressPreferences = result;
        }).catch(function(error){
            console.dir(error);
        });
    }
    function retrieveShipList(){
        fleetDataService.getFleet().then(function(result){
            vm.shipsList = result;
        });
    }

    function onAddressPreferencesUpdate(){
        retrieveAddressPreferencesList().then(function(){
            
        });
        vm.activeAddressPreference = null;
    }
};