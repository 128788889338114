﻿module.exports = ['$uibModal', widgetModalsService];

function widgetModalsService($uibModal) {

    var service = {
        areYouSure: areYouSure,
        duplicateReservationWithNewDates: duplicateReservationWithNewDates,
        extendReservation: extendReservation,
        reviewWarnings: reviewWarnings,
        privacy: privacy,
        touristTaxConfirmation: touristTaxConfirmation,
        touristTaxLateWarning: touristTaxLateWarning,
        okConfirmation: okConfirmation,
        cancelReservationOfShip:cancelReservationOfShip
    };

    return service;
    function privacy(template) {
        privacyStatementController.$inject = ['$uibModalInstance'];

        function privacyStatementController($uibModalInstance) {
            var ctrlPrivacy = {
                cancel: cancel,
                ok: ok
            };

            return ctrlPrivacy;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function ok() {
                $uibModalInstance.close(true);
            }
        }
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            template: template,
            controllerAs: 'ctrlPrivacy',
            controller: privacyStatementController,
            backdrop: 'static',
            windowClass: 'privacy-modal'
        });
    }
    function reviewWarnings(warnings) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/review-warnings.html',
            controllerAs: 'ctrlReviewWarnings',
            controller: 'reviewWarningsController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return warnings;
                }
            }
        });
    }
    function touristTaxConfirmation() {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/tourist-tax-confirmation.html',
            controllerAs: 'ctrlModalOk',
            controller: 'modalOkController',
            backdrop: 'static'
        });
    }
    function touristTaxLateWarning() {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/tourist-tax-latewarning.html',
            controllerAs: 'ctrlModalOk',
            controller: 'modalOkController',
            backdrop: 'static'
        });
    }
    function duplicateReservationWithNewDates($uibModalInstance) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/reservation-add-new-dates.html',
            controllerAs: 'ctrlReservationDuplicate',
            controller: 'duplicateReservationController',
            backdrop: 'static'
        });
    }

    function areYouSure(thisMessage) {
        modalAreYouSureController.$inject = ['$uibModalInstance'];

        function modalAreYouSureController($uibModalInstance) {
            var vm = {
                cancel: cancel,
                message: thisMessage,
                ok: ok
            };

            return vm;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function ok() {
                $uibModalInstance.close(true);
            }
            //loadVessels();
        }
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/are-you-sure.html',
            controllerAs: 'vm',
            controller: modalAreYouSureController,
            backdrop: 'static'
        });
    }

    function extendReservation(args) {
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/extend-reservation.html',
            controllerAs: 'ctrlExtendReservation',
            controller: 'extendReservationController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            }
        });
    }

    function okConfirmation(thisMessage) {

        modalOkConfirmationController.$inject = ['$uibModalInstance'];

        function modalOkConfirmationController($uibModalInstance) {
            var vm = {
                message: thisMessage,
                ok: ok
            };

            return vm;

            function ok() {
                $uibModalInstance.close(true);
            }
        }
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/ok-confirmation.html',
            controllerAs: 'vm',
            controller: modalOkConfirmationController,
            backdrop: 'static'
        });
    }

    
    function cancelReservationOfShip(args) {
        
        return $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            bindToController: true,
            templateUrl: 'templates/widgets/modals/cancel-reservation-of-ship.html',
            controllerAs: 'vm',
            controller: 'cancelReservationOfShipController',
            backdrop: 'static',
            resolve: {
                modalArguments: function () {
                    return args;
                }
            }
        });
    }
}