﻿module.exports = ['$window', '$filter', 'common', 'businessruleDataService','reservationDataService', 'reservationPlanningService', 'constantsService', publicBerthCalendarController];

function publicBerthCalendarController($window, $filter, common, businessRuleData, reservationData, reservationPlanning, constantsService) {

    var vm = {
        attached: attached,
        displayBerthsFilter: displayBerthsFilter,
        filter: {
            startDate: null,
            endDate: null,
            sortAscending: true,
            sortField: 'eta',
            searchquery: null,
            search: search
        },
        loading: false,
        openBerthDetailsPage: openBerthDetailsPage,
        planning: {
            berthDetails: [],
            berths: [],
            calendar: reservationPlanning
        },
        redirectToNewReservation: redirectToNewReservation,
        reload: reload,
        getRowsForBerth:getRowsForBerth
    };

    function attached() {
        constantsService.preloaded.then(function () {
            vm.filter.startDate = moment();///.add(-6, 'days');
            vm.filter.endDate = moment().add(6, 'days');
            reservationPlanning.calculateDays({
                eta: vm.filter.startDate,
                etd: vm.filter.endDate
            });

            getBerths();
            loadReservations();
        });
    }

    function displayBerthsFilter(berth) {

        if (berth !== undefined && berth !== null) {
            if (berth.availableUntil !== undefined && berth.availableUntil !== null) {
                if (vm.filter !== undefined && vm.filter !== null && vm.filter.startDate.toISOString() > berth.availableUntil)
                    return;
            }

            if (berth.availableFrom !== undefined && berth.availableFrom !== null) {
                if (vm.filter !== undefined && vm.filter !== null && vm.filter.endDate.toISOString() < berth.availableFrom)
                    return;
            }

            return berth;
        }

        return;
    }

    function calculateDays() {
        reservationPlanning.calculateDays({
            eta: vm.filter.startDate,
            etd: vm.filter.endDate
        });
    }

    function getBerths() {
        reservationData.getBerthGroupsByBusinessUnit(1).then(function (result) {
            result = _.map(result, function (berthGroup) {
                berthGroup.processFlows = _.map(berthGroup.processFlowIds, function (processFlowId) {
                    return _.find(constantsService.processFlows, function (processFlow) {
                        return processFlow.id === processFlowId && processFlow.isActive === true && (processFlow.endsOn === null || moment(processFlow.endsOn) > moment());
                    });
                }).filter(Boolean);
                berthGroup.berths = _.map(_.filter(berthGroup.berths, function (activeBerth) { return activeBerth.isActive; }), function (berth) {
                    // If the berth name starts with the group name, remove it for display purposes
                    if (berth.name.indexOf(berthGroup.name) === 0 && berth.name.length > berthGroup.name.length) {
                        berth.name = berth.name.substring(berthGroup.name.length).trim();
                    }
                    return berth;
                });
                return berthGroup;
            });
            vm.planning.berths = _.filter(result, function (berthGroup) {
                return berthGroup.berths && berthGroup.berths.length > 0;
            });
            calculateBerths();
            loadBerthObstructions();
        });
    }

    function calculateBerths() {
        var berths = [];
        for (var a = 0; a < vm.planning.berths.length; a++) {
            for (var b = 0; b < vm.planning.berths[a].berths.length; b++) {
                berths.push(vm.planning.berths[a].berths[b]);
            }
        }
        vm.planning.berthDetails = berths;
    }

    function calculateObstructions(obstructions) {
        reservationPlanning.calculateBerths(vm.planning.berthDetails, obstructions);
    }

    function loadBerthObstructions() {
        businessRuleData.getByType(3, vm.filter.startDate.format('YYYY-MM-DD'), vm.filter.endDate.format('YYYY-MM-DD'))
            .then(function (result) {
                calculateObstructions(result);
            });
    }

    function loadReservations() {
        reservationData.getAvailability(vm.filter.startDate, vm.filter.endDate, 1)
            .then(function (result) {
                reservationPlanning.calculateDays({
                    eta: vm.filter.startDate,
                    etd: vm.filter.endDate,
                    planningItems: result
                });

                var mappedItems = _.map(result, function (item) {
                    item.customer = { name: item.customerName };
                    item.ship = { id: item.shipId, name: item.shipName };
                    return item;
                });

                reservationPlanning.calculateItems(_.filter(result, function (r) { return r.isConfirmed; }), 'planned');
                reservationPlanning.calculateItems(_.filter(result, function (r) { return !r.isConfirmed; }), 'unplanned');
            });
    }

    function openBerthDetailsPage() {
        $window.open(common.config.publicBerthInfoUrl, '_blank');
    }

    function redirectToNewReservation(dayIndex, berthGroupId, flow) {
        var param = {};
        var startDate = moment(vm.filter.startDate).add(dayIndex, 'days');
        param.eta = startDate.toJSON();
        param.etd = startDate.add(7, 'days').toJSON();
        
        if (berthGroupId) {
            param.berthGroupId = berthGroupId;
        }

        var url;
        switch (flow.systemCode) {
            case "SEA_GOING":
            case "INLAND":
                url = '/reservations/new-public-berth/' + flow.id;
                break;

            case "SEA_CRUISE":
                url = '/reservations/new-seacruise/' + flow.id;
                break;

            case "RIVER_CRUISE":
            case "PARTY":            
                url = '/reservations/new/' + flow.id;
                break;

            default:                
                url = '/reservations/generic/new/' + flow.id;
        }

        common.$location.path(url).search(param);
    }

    function reload(forceReload) {

        // use timeout to wait the date-period-picker is closed; otherwise the startDate and endDate are not updated
        common.$timeout(function () {
            calculateDays(); // ensure the date is updated, needs this to calculate the width of the calendar items
            loadBerthObstructions();
            loadReservations();
        }, 200);
    }

    function search() {

    }

    function getRowsForBerth(berth) {
        var items = $filter('calendarItems')(vm.planning.calendar.data.planned, berth);
        items = items.concat($filter('calendarItems')(vm.planning.calendar.data.unplanned, berth));

        if (!items || items.length === 0)
            return 1;

        var result = _.max(items, function (item) { return item.display.row; });
        var row = result ? result.display.row + 1 : 1;

        return berth.capacity && berth.capacity > row ? row + 1 : row;
    }

    return vm;
}
