﻿module.exports = ['$translate', '$window', 'common', 'constantsService', 'reservationDataService', 'reservationPlanningService', 'reservationWizardSeacruiseService', 'constantsService', reservationWizardStep2Directive];

function reservationWizardStep2Directive($translate, $window, common, constantsService, reservationData, reservationPlanning, reservationWizard, constantsService) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep2Controller,
        controllerAs: 'ctrlReservationWizard2',
        restrict: 'E',
        templateUrl: 'templates/reservations-seacruise/directives/reservation-wizard-step2.html'
    };

    return directive;

    function reservationWizardStep2Controller() {
        var vm = this;
        vm.berthGroups = [];
        vm.commercialGoals = [];
        vm.common = common;
        vm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        };
        vm.errors = [];
        vm.facilities = [];
        vm.getVesselType = reservationWizard.getVesselType;
        vm.goBack = goBack;
        vm.planning = {
            days: [],
            items: []
        };
        vm.nauticalGoals = [];
        vm.next = next;
        vm.newActivity = null;
        vm.wizard = reservationWizard;
        vm.deleteActivity = deleteActivity;
        vm.reservation = reservationWizard.reservation;
        vm.sending = false;
        init();

        function load() {
            $translate([
                'views.reservations.reservationwizzard.errors.activitytype',
                'views.reservations.reservationwizzard.errors.validdate',
                'views.reservations.reservationwizzard.errors.validenddate',
                'views.reservations.reservationwizzard.errors.noberth',
            ]).then(function (translations) { vm.translations = translations; });
        }

        function deleteActivity(activity) {
            var index = _.findIndex(vm.wizard.reservation.activities, activity);
            if (index >= 0)
                vm.wizard.reservation.activities.splice(index, 1);
        }

        function goBack() {
             $window.history.back();
        }

        function init() {
            var vesselType = vm.getVesselType();

            reservationData.getBerthGroupsByShipType(vesselType)
                .then(function (result) {
                    vm.berthGroups = result;
                });
            reservationData.getCommercialGoals()
                .then(function (result) {
                    vm.commercialGoals = result;
                });
            reservationData.getFacilities()
                .then(function (result) {
                    vm.facilities = result;
                });
            reservationData.getNauticalGoals()
                .then(function (result) {
                    vm.nauticalGoals = result;
                });
            load();
        };

        function next() {
            vm.sending = true;

            vm.errors = [];
            if (reservationWizard.validation.step2.validate()) {
                reservationWizard.save()
                    .then(function () {
                        vm.sending = false;
                        common.$location.path('/reservations/');
                    });
            } else {
                vm.sending = false;
                vm.errors = reservationWizard.validation.step2.errors;
            }
        };
    }

};