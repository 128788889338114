﻿module.exports = ['$location', '$routeParams', 'searchDataService', searchResultController];

function searchResultController($location, $routeParams, searchData) {
    var vm = {
        results: [],
        loadResults: loadResults,
        showDetails: showDetails
    };

    return vm;

    function loadResults() {
        vm.results = [];
        searchText = $routeParams.searchText;
        searchData.find(searchText)
            .then(function (result) {
                vm.results = result;
            });
    }

    function showDetails(searchResult) {
        $location.path("/" + searchResult.type + "/" + searchResult.id);
    }
}