﻿module.exports = ['common', 'reservationDataService', '$uibModalInstance', 'modalArguments', cancelReservationOfShipController];

function cancelReservationOfShipController(common, reservationData, $uibModalInstance,args) {
    
    if (!args)
        args = {};

    var loadingFrames = 0;

    var vm = {
        ok:ok,
        cancel:cancel,
        message: args.message,
        isLoading:false,
        totalReservations:0,
        reservations: [],
        isCompleted:false ,
        loadingText:'...' 
    }

    init();

    return vm;

    function init(){
        vm.isCompleted = false;
        reservationData.getReservationsByShipId(args.shipId).then(function(result){
            var currentDate = new Date();
            vm.reservations = _.filter(result,function(r){
                return !r.isCancelled &&
                !r.isCancelledByAuthority &&
                (currentDate <= new Date(r.eta))
            });
            vm.totalReservations = vm.reservations.length;
        });
    }

    function ok() {
        if(vm.isCompleted || vm.totalReservations==0){
            $uibModalInstance.close();
        }else{
            vm.isLoading = true;
            cancelReservations().then(function(){
                vm.isLoading = false;
                vm.isCompleted = true;
            }).catch(function (error) {
                vm.isLoading = false;
                init();
            });
        }
    }

    function cancel() {
        $uibModalInstance.dismiss('cancel');
    }

    function cancelReservations(){
        var deferred = common.$q.defer();
        if(vm.reservations.length <= 0){
            deferred.resolve();
        }else{
            return reservationData.cancelReservation(vm.reservations[0].id,'Ship deactivated').then(function (cancelResult) {
                vm.reservations.shift();
                if (vm.reservations.length > 0) {
                    return cancelReservations();
                }
            });
        }
        return deferred.promise;
    }
}