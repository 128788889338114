﻿module.exports = {
    'myDetails': 'My details',
    'personalInformation': 'Personal information',
    'companyInfo': 'Company information',
    'changePassword': 'Change password',
    'currentPassword':'Current password',
    'newPassword':'New Password',
    'verifyPassword': 'Verify new password',
    'update': {
        'failed': 'Error updating profile.',
        'sucess': 'Profile successfully updated.',
        'emptydisplayname': 'Display name can\'t be empty.',
        'emptycompanyname': 'Company name can\'t be empty.',
        }
};