﻿module.exports = ['$location', 'common', logoutController];

function logoutController($location, common) {
    var vm = this;
    vm.loggedOut = false;

    function logout() {
        common.identity.set(null);
        vm.loggedOut = true;

        common.$timeout(function () {
            $location.path('/');
        }, 1000);
    };

    logout();
};
