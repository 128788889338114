﻿module.exports = ['common', 'constantsService', updateReservationDolphin];

function updateReservationDolphin(common, constants) {
    var directive = {
        bindToController: true,
        controller: updateReservationController,
        controllerAs: 'ctrlUpdate',
        restrict: 'E',
        scope: {
            reservation: '=',
            ship: '='
        },
        templateUrl: 'templates/reservations-dolphin/directives/update-reservation.html'
    };

    return directive;

    function updateReservationController() {

        var vm = {
            init: init,
            hasPriorityFlag: false,
            originalEtd: null,
            redirectToNewReservation: redirectToNewReservation
        };

        function init() {
            if (vm.reservation.nauticalGoalId) {
                constants.preloaded
                    .then(function () {
                            var nauticalGoal = _.find(constants.nauticalGoals,
                                function(goal) {
                                    return goal.id === vm.reservation.nauticalGoalId;
                            });

                            if (nauticalGoal) {
                                vm.hasPriorityFlag = nauticalGoal.priorityLevel === 1;
                            }
                        },
                        function () { });
            }

            vm.originalEtd = moment(vm.reservation.etd);
        }

        function redirectToNewReservation() {

            var param = {};
            param.eta = vm.originalEtd.toJSON();
            param.etd = moment(vm.originalEtd).add(vm.hasPriorityFlag ? 7 : 3, 'days').toJSON();

            if (vm.reservation.nauticalGoalId) {
                param.nauticalGoalId = vm.reservation.nauticalGoalId;
            }

            if (vm.reservation.commercialGoalId) {
                param.commercialGoalId = vm.reservation.commercialGoalId;
            }

            if (vm.reservation.berthId) {
                param.berthId = vm.reservation.berthId;
            }

            if (vm.reservation.ship) {
                param.loa = vm.ship.length;
            }
            
            common.$location.path("/reservations/new-public-berth").search(param);
        }

        return vm;
    }
}