﻿angular.module('poa.rprp.reservations', [])
    .config(['$routeProvider', routeConfig])
    // Controllers
    //.controller('reservationsController', require('./controllers/reservations'))
    .controller('overlappingReservationModalController', require('./modals/overlapping-reservation'))
    .controller('reservationDetailController', require('./controllers/reservation-detail'))
    .controller('reservationNewController', require('./controllers/reservation-new'))
    .controller('reservationNewControllerAlternative', require('./controllers/reservation-new-alternative'))
    .controller('updateReservationController', require('./controllers/reservation-update'))
    .controller('duplicateReservationController', require('./controllers/reservation-duplicate'))
    // Directives
    .directive('calendarBlock', require('./directives/calendar-block'))
    .directive('reservationCommunicatedStatus', require('./directives/reservation-communicated-status'))
    .directive('reservationReceipt', require('./directives/reservation-receipt'))
    .directive('reservationReceiptPrice', require('./directives/reservation-receipt-price'))
    .directive('reservationWizardStep1Alternative', require('./directives/reservation-wizard-step1-alternative'))
    .directive('reservationWizardStep2Alternative', require('./directives/reservation-wizard-step2-alternative'))
    .directive('reservationWizardStep1', require('./directives/reservation-wizard-step1'))
    .directive('reservationWizardStep2', require('./directives/reservation-wizard-step2'))
    .directive('reservationDuplicateCurrent', require('./directives/reservation-duplicate-current'))
    // Filters
    .factory('activityDateDisplayFilter', require('./filters/activity-date-display'))
    .factory('activityFacilitiesDisplayFilter', require('./filters/activity-facilities-display'))
    .factory('calendarItemsFilter', require('./filters/calendar-items-filter'))
    .factory('reservationDateDisplayFilter', require('./filters/reservation-date-display'))
    // Services
    .factory('reservationDataService', require('./services/reservation-data'))
    .factory('reservationOverviewStateService', require('./services/reservation-overview-state'))
    .factory('reservationPlanningService', require('./services/reservation-planning'))
    .factory('reservationWizardService', require('./services/reservation-wizard'))
    .factory('reservationCurrentService', require('./services/reservation-current'))
    .factory('reservationModals', require('./modals/modals'))
;

function routeConfig($routeProvider) {
    $routeProvider
        //.when('/reservations', {
        //    templateUrl: 'templates/reservations/controllers/reservations.html'
        //})
        .when('/reservations/new-old', {
            templateUrl: 'templates/reservations/controllers/reservation-new.html'
        })
        .when('/reservations/new/:flowId', {
            templateUrl: 'templates/reservations/controllers/reservation-new-alternative.html'
        })
       .when('/reservations/update', {
            templateUrl: 'templates/reservations/controllers/reservation-update.html'
       })
        .when('/reservations/update/:reservationId', {
            templateUrl: 'templates/reservations/controllers/reservation-update.html'
        })
        .when('/reservations/newArrival', {
            templateUrl: 'templates/reservations/controllers/reservation-duplicate.html'
        })
        .when('/reservations/:reservationId', {
            templateUrl: 'templates/reservations/controllers/reservation-detail.html'
        });

}