﻿module.exports = ['$translate', 'common', 'reservationDataService', 'reservationPlanningService', 'reservationWizardService', 'constantsService', reservationWizardStep1Directive];

function reservationWizardStep1Directive($translate, common, reservationData, reservationPlanning, reservationWizard, constantsService) {

    var directive = {
        bindToController: true,
        controller: reservationWizardStep2Controller,
        controllerAs: 'ctrlReservationWizard2',
        restrict: 'E',
        templateUrl: 'templates/reservations/directives/reservation-wizard-step2.html'
    };

    return directive;

    function reservationWizardStep2Controller() {
        var vm = this;
        vm.addActivity = addActivity;
        vm.berthGroups = [];
        vm.commercialGoals = [];
        vm.common = common;
        vm.datePickerOptions = {
            altInputFormats: [
                'd-M-yyyy',
                'd-M-yyyy H:mm',
                'd-M-yyyy HH:mm',
                'dd-MM-yyyy',
                'dd-MM-yyyy H:mm',
                'dd-MM-yyyy HH:mm'
            ]
        };
        vm.errors = [];
        vm.facilities = [];
        vm.getVesselType = reservationWizard.getVesselType;
        vm.planning = {
            days: [],
            items: []
        };
        vm.nauticalGoals = [];
        vm.next = next;
        vm.newActivity = null;
        vm.wizard = reservationWizard;
        vm.deleteActivity = deleteActivity;
        vm.reservation = reservationWizard.reservation;
        vm.sending = false;

        init();

        function load() {
            $translate([
                'views.reservations.reservationwizzard.errors.activitytype',
                'views.reservations.reservationwizzard.errors.validdate',
                'views.reservations.reservationwizzard.errors.validenddate',
                'views.reservations.reservationwizzard.errors.noberth',
            ]).then(function (translations) { vm.translations = translations; });
        }


        function deleteActivity(activity) {
            var index = _.findIndex(vm.wizard.reservation.activities, activity);
            if (index >= 0) {
                vm.wizard.reservation.activities.splice(index, 1);
                vm.wizard.reservation = Object.assign({}, vm.wizard.reservation);
            }

        }

        function addActivity() {
            vm.errors = [];
            if (!vm.newActivity.nauticalGoal)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.activitytype']);
            if (!vm.newActivity.startsOn)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.eta).diff(vm.newActivity.startsOn, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.reservation.etd).diff(vm.newActivity.endsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);
            if (moment(vm.newActivity.startsOn).diff(vm.reservation.etd, 'minutes') > 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validdate']);
            if (moment(vm.newActivity.endsOn).diff(vm.newActivity.startsOn, 'minutes') < 0)
                vm.errors.push(vm.translations['views.reservations.reservationwizzard.errors.validenddate']);

            if (vm.errors.length > 0)
                return;

            if (!vm.wizard.reservation.activities)
                vm.wizard.reservation.activities = [];

            if (vm.newActivity.endsOn === undefined || vm.newActivity.endsOn === null || vm.newActivity.endsOn === '') {
                vm.newActivity.endsOn = vm.reservation.etd;
            }

            vm.wizard.reservation.activities.push(vm.newActivity);
            clearNewActivity();
        };

        function clearNewActivity() {
            vm.newActivity = {
                berthGroup: null,
                commercialGoal: null,
                endsOn: null,
                nauticalGoal: null,
                startsOn: null
            };

            if (!reservationWizard.reservation.activities || reservationWizard.reservation.activities.length === 0)
                vm.newActivity.startsOn = reservationWizard.reservation.eta;
            else if (reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn)
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].endsOn;
            else
                vm.newActivity.startsOn = reservationWizard.reservation.activities[reservationWizard.reservation.activities.length - 1].startsOn;
        };

        function generatePlanning() {
            //vm.planning = reservationPlanning.calculateCalendar([{
            //    eta: reservationWizard.reservation.eta,
            //    etd: reservationWizard.reservation.etd
            //}]);
        };

        function init() {
            var vesselType = vm.getVesselType();

            reservationData.getBerthGroupsByShipType(vesselType)
                .then(function (result) {
                    vm.berthGroups = result;
                });
            reservationData.getCommercialGoals()
                .then(function (result) {
                    vm.commercialGoals = result;
                });
            reservationData.getFacilities()
                .then(function (result) {
                    vm.facilities = result;
                });
            reservationData.getNauticalGoals()
                .then(function (result) {
                    vm.nauticalGoals = result;
                });
            clearNewActivity();
            generatePlanning();
            load();
        };

        function next() {
            vm.sending = true;

            vm.errors = [];
            reservationWizard.validation.step2.validate()
                .then(function (validationResult) {
                    /*
                    reservationWizard.save()
                        .then(function () {
                            vm.sending = false;
                            common.$location.path('/reservations');
                        });
                    */

                }, function (validationError) {
                    vm.sending = false;
                    vm.errors = reservationWizard.validation.step2.errors;
                });
        };
    }

};