﻿angular.module('poa.rprp.reservationsGeneric', [])
        .config(['$routeProvider', routeConfig])
        // Controllers
        .controller('reservationGenericController', require('./controllers/reservation-generic'))
        // Directives
        .directive('genericReservationSelectVessel', require('./directives/generic-reservation-select-vessel'))
        .directive('genericReservationSelectTime', require('./directives/generic-reservation-select-time'))
        .directive('genericReservationSelectBerth', require('./directives/generic-reservation-select-berth'))
        .directive('genericReservationSelectCustomer', require('./directives/generic-reservation-select-customer'))
        .directive('genericReservationSelectInvoiceaddress', require('./directives/generic-reservation-select-invoiceaddress'))
        .directive('genericReservationYourReservation', require('./directives/generic-reservation-your-reservation'))
        .directive('genericReservationAddActivities', require('./directives/generic-reservation-add-activities'))
        // Services
        .factory('reservationWizardGenericService', require('./services/reservation-wizard-generic'))
    ;

function routeConfig($routeProvider) {
    $routeProvider
            .when('/reservations/generic/new/:flowId', {
                templateUrl: 'templates/reservations-generic/controllers/reservation-generic.html'
            })
            .when('/reservations/generic/:reservationId', {
                templateUrl: 'templates/reservations-generic/controllers/reservation-generic.html'
            })
        ;
};