﻿module.exports = {
    'errors': {
        'vesselgreenaward': 'Please specify a (valid) vessel Green award',
        'vesselselect': 'Please specify a vessel',
        'vesselname': 'Please specify a vessel name',
        'vessellength': 'Please specify a (valid) vessel length',
        'vesselwidth': 'Please specify a (valid) vessel width',
        'vesseldraught': 'Please specify a (valid) vessel draught',
        'vesselheight': 'Please specify a (valid) vessel height',
        'veselnamelength': 'Vessel name cannot contain more than 50 characters',
        'vesseltype': 'Please specify a vessel type',
        'lengthformat': 'Vessel length must be a number',
        'widthformat': 'Vessel width must be a number',
        'maxdraughtformat': 'Vessel Maximum Draught must be a number',
        'heightformat': 'Vessel height must be a number',
        'eninumberformat': 'ENI number must be a number',
        'eninumberlength': 'ENI number must contain 8 digits',
        'imonumberformat': 'IMO number must be a number',
        'imonumberlength': 'IMO number must contain 7 digits',
        'paxCapacity': 'Please specify amount of people',
        'paxCapacityFormat': 'Please specify a valid pax capacity',
        'vesselunnumber': 'Please specify an UN number.'
    }
};